import { offerStates } from "../Utils";
export default {
  activityTypes: {
    isFetching: false,
    options: [],
  },
  auth: {
    token: "",
    isFetching: false,
  },
  cities: {
    isFetching: false,
    options: [],
    suggestedOptions: [],
  },
  clientForm: {
    isSaving: false,
    isSeller: "",
    activityType: "",
    cpfcnpj: "",
    telephone: "",
    federalRegistration: "",
    legalName: "",
    tradeName: "",
    officeType: "",
    commercialUnitsQuantity: "",
    storageCapacity: "",
    receivingCapacity: "",
    cep: "",
    cityId: "",
    cityName: "",
    ufCode: "",
    address: "",
    isHeadOffice: false,
    email: "",
    emailCommercial: "",
    emailFinancial: "",
    bankData1: "",
    bankData2: "",
    bankData3: "",
    bankData4: "",
    tradeRestriction: [],
    tradeRestriction1: "",
    tradeRestriction2: "",
    tradeRestriction3: "",
    tradeRestriction4: "",
    tradeRestriction5: "",
    tradeRestriction6: "",
    observation: "",
  },
  editClientForm: {
    isSaving: false,
    isFetching: false,
    id: null,
    isSeller: "",
    activityType: "",
    cpfcnpj: "",
    telephone: "",
    federalRegistration: "",
    legalName: "",
    tradeName: "",
    officeType: "",
    commercialUnitsQuantity: "",
    storageCapacity: "",
    receivingCapacity: "",
    cep: "",
    cityId: "",
    cityName: "",
    ufCode: "",
    address: "",
    isHeadOffice: false,
    email: "",
    emailCommercial: "",
    emailFinancial: "",
    bankData1: "",
    bankData2: "",
    bankData3: "",
    bankData4: "",
    tradeRestriction: [],
    tradeRestriction1: "",
    tradeRestriction2: "",
    tradeRestriction3: "",
    tradeRestriction4: "",
    tradeRestriction5: "",
    tradeRestriction6: "",
    observation: "",
  },
  clientDetails: {
    isFetching: false,
    data: {},
  },
  clients: {
    nameOptions: [],
    cpfCnpjOptions: [],
    entities: [],
    top: 5,
    currentPage: 0,
    searchFilter: "",
    count: 0,
    isFetching: false,
  },
  userForm: {
    name: "",
    phoneNumber: "",
    cpf: "",
    cpfcnpj: "",
    clientId: "",
    bornOn: "",
    email: "",
    isSaving: false,
  },
  editUserForm: {
    id: null,
    name: "",
    phoneNumber: "",
    cpf: "",
    cpfcnpj: "",
    clientId: "",
    bornOn: "",
    email: "",
    isSaving: false,
    isFetching: false,
  },
  users: {
    entities: [],
    top: 5,
    currentPage: 0,
    searchFilter: "",
    count: 0,
    isFetching: false,
  },
  userDetails: {
    data: {},
    isFetching: false,
    isSaving: false,
  },
  prices: {
    entities: [],
    currentHarvestId: null,
    currentProductId: null,
    allChecked: false,
    cotationControl: "pricesCotationSubscribed",
    exchangeControl: "pricesExchangeSubscribed",
    isFetching: false,
    isSaving: false,
  },
  pricesIndications: {
    entities: [],
    currentHarvestId: null,
    currentProductId: null,
    cotationControl: "indicationsCotationSubscribed",
    exchangeControl: "indicationsExchangeSubscribed",
    isFetching: false,
    isSaving: false,
  },
  priceForm: {
    tradeOn: new Date(),
    paymentOn: "",
    buyerId: "",
    buyerName: "",
    productId: null,
    harvestId: null,
    shippingAgreementTypeId: null,
    shippingAgreementType: {},
    value: 0,
    destinationUFCode: 43, //RS
    destinationId: 4315602, //prod //1597 - stage, //Rio Grande
    suggestedLocationId: null,
    deliveryStartsOn: "",
    deliveryEndsOn: "",
    immediateDelivery: false,
    dirtRoadLength: null,
    portTerminalId: null,
    rewardData: {
      isFetching: false,
    },
    isSaving: false,
    duration: 10,
  },
  offerForm: {
    UserId: "",
    ClientId: "",
    paymentOn: "",
    productId: null,
    harvestId: null,
    value: 0,
    shippingAgreementType: {},
    shippingAgreementTypeId: null,
    destinationUFCode: 43,
    destinationId: 4315602,
    deliveryStartsOn: "",
    deliveryEndsOn: "",
    immediateDelivery: false,
    dirtRoadLength: null,
    portTerminalId: null,
    duration: 10,
    isSaving: false,
    quantity: "",
    useMarketTime: "false",
    ufCode: "",
    product: {},
    users: {},
  },
  pricesHistory: {
    currentTab: "soy",
    selectedOffer: {},
    entities: [],
    top: 5,
    count: 0,
    currentPage: 0,
    buyerFilter: "",
    productFilter: 1,
    harvestId: null,
    dateFilter: null,
    isFetching: false,
    isSaving: false,
  },
  products: {
    isFetching: false,
    options: [],
    entities: [],
  },
  dayOffers: {
    orderBy: 1, // Expiration
    currentTab: offerStates.New,
    offerCount: {
      new: null,
      negotiation: null,
      approved: null,
      rejected: null,
    },
    selectedOffer: {},
    entities: [],
    cotationControl: "offersCotationSubscribed",
    exchangeControl: "offersExchangeSubscribed",
    isFetching: false,
    isSaving: false,
  },
  businessConfirmation: {
    isFetching: false,
    isSaving: false,
    form: {
      bankDataId: null,
      dataBank: "",
      productSpecification: "",
      specialClauses: "",
    },
    data: {
      numberSerial: "",
      sellerClient: {
        bankOptions: [],
      },
      sellerUser: {},
      buyerClient: {},
    },
  },
  negotiationsHistory: {
    currentTab: "soy",
    selectedOffer: {},
    entities: [],
    top: 5,
    count: 0,
    currentPage: 0,
    clientFilter: "",
    productFilter: 1,
    harvestId: null,
    dateFilter: null,
    isFetching: false,
    isSaving: false,
  },
  harvests: {
    isFetching: false,
    isSaving: false,
    options: [],
    entities: [],
  },
  harvestForm: {
    isSaving: false,
    productId: null,
    year: null,
  },
  portTerminals: {
    isFetching: false,
    options: [],
    entities: [],
    top: 5,
    currentPage: 0,
    count: 0,
    searchFilter: "",
  },
  portTerminalDetails: {
    isFetching: false,
    data: {},
  },
  portTerminalForm: {
    isSaving: false,
    cpfcnpj: "",
    telephone: "",
    federalRegistration: "",
    legalName: "",
    tradeName: "",
    officeType: "",
    commercialUnitsQuantity: "",
    storageCapacity: "",
    receivingCapacity: "",
    cep: "",
    cityId: "",
    cityName: "",
    ufCode: "",
    address: "",
    isHeadOffice: false,
    email: "",
    emailCommercial: "",
    emailFinancial: "",
    observation: "",
  },
  editPortTerminalForm: {
    isSaving: false,
    isFetching: false,
    id: null,
    cpfcnpj: "",
    telephone: "",
    federalRegistration: "",
    legalName: "",
    tradeName: "",
    officeType: "",
    commercialUnitsQuantity: "",
    storageCapacity: "",
    receivingCapacity: "",
    cep: "",
    cityId: "",
    cityName: "",
    ufCode: "",
    address: "",
    isHeadOffice: false,
    email: "",
    emailCommercial: "",
    emailFinancial: "",
    observation: "",
  },
  shippingAgreementTypes: {
    isFetching: false,
    options: [],
  },
  freights: {
    isFetching: false,
    options: [],
  },
  cbots: {
    isFetching: false,
    entities: [],
  },
  cbotForm: {
    isSaving: false,
    productId: null,
    start: null,
    end: null,
    month: null,
    year: null,
  },
  marketHours: {
    isFetching: false,
    isSaving: false,
    entities: [],
    hasEdited: false,
  },
  signalR: {
    isPriceConnecting: false,
    isPriceConnected: false,
    instrumentation: { isConnected: false, lastReceivedTickers: [] },
    pricesCotationSubscribed: false,
    pricesExchangeSubscribed: false,
    indicationsCotationSubscribed: false,
    indicationsExchangeSubscribed: false,
    offersCotationSubscribed: false,
    offersExchangeSubscribed: false,
    fetchNewOffers: false,
    exchanges: { cbot: { isOpen: true }, dollar: { isOpen: true } },

    isNotificationConnecting: false,
    isNotificationConnected: false,
    currentDollarSubscribed: false,
    currentDollar: { value: null, hour: null },
  },
};
