import { useEffect, useCallback, useRef } from 'react'

const useInterval = (callback, delay) => {
    const intervalRef = useRef();
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
 
    const start = useCallback(() => {
        if (intervalRef.current !== null)
            return;

        const tick = () => savedCallback.current();
        intervalRef.current = setInterval(tick, delay);
    }, [delay]);

    const stop = useCallback(() => {
        if (intervalRef.current === null)
            return;

        clearInterval(intervalRef.current);
        intervalRef.current = null;
    }, []);

    const reset = useCallback(() => {
        stop();
        start();
    }, [start, stop]);

    return [reset, start, stop];
}

export default useInterval;
