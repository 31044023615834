const CPF_LENGTH = 11
const CPF_DOT_INDEXES = [2, 5]
const CPF_HYPHEN_INDEXES = [8]
const CPF_MASK = '___.___.___-__'
const CNPJ_LENGTH = 14
const CNPJ_DOT_INDEXES = [1, 4]
const CNPJ_SLASH_INDEXES = [7]
const CNPJ_HYPHEN_INDEXES = [11]
const CNPJ_MASK = '__.___.___/____-__'

class CpfCnpjUtils {

    format(cpfCnpj = '') {
        cpfCnpj = cpfCnpj.replace(/\D/g, '');

        if (cpfCnpj.length === 11)
            return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
        else
            return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }

    isValid(cpfCnpj) {
        cpfCnpj = cpfCnpj.replace(/\D/g, '');

        if (cpfCnpj.length < 14)
            return this.validateCpf(cpfCnpj);
        else
            return this.validateCnpj(cpfCnpj)
    }

    validateCpf(cpf) {
        // Default cases
        if (!cpf || cpf.length !== 11 || (/^(\d)\1+$/.test(cpf)))
            return false

        var sum = 0
        var remainder
        for (var i = 1; i <= 9; i++)
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        remainder = (sum * 10) % 11

        if ((remainder === 10) || (remainder === 11))
            remainder = 0

        if (remainder !== parseInt(cpf.substring(9, 10)))
            return false

        sum = 0
        for (i = 1; i <= 10; i++)
            sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)

        remainder = (sum * 10) % 11

        if ((remainder === 10) || (remainder === 11))
            remainder = 0

        if (remainder !== parseInt(cpf.substring(10, 11)))
            return false

        return true
    }

    validateCnpj(cnpj) {
            // Default cases
            if (!cnpj || cnpj.length !== 14 || (/^(\d)\1+$/.test(cnpj)))
                return false

            // Validation
            const size = cnpj.length - 2
            const numbers = cnpj.substring(size)
            const d1 = parseInt(numbers.charAt(0))
            const d2 = parseInt(numbers.charAt(1))
            const  calc = size => {
                    let n = cnpj.substring(0, size),
                        position = size - 7,
                        sum = 0,
                        result = 0

                    for (let i = size; i >= 1; i--) {
                        sum += n.charAt(size - i) * position--;
                        if (position < 2)
                            position = 9
                    }

                    result = 11 - sum % 11
                    return result > 9 ? 0 : result
                }

            return calc(size) === d1 && calc(size + 1) === d2
    }

    inputFormat(cpfCnpj) {
        return cpfCnpj.length < 12 ? this.formatCpfInput(cpfCnpj) : this.formatCnpjInput(cpfCnpj);
    }

    formatCpfInput(cpf) {
        if (!cpf) return '';

        let result = cpf
            .slice(0, CPF_LENGTH)
            .split('')
            .reduce((acc, digit, index) => {
                const result = `${acc}${digit}`;
                if (!(index === cpf.length - 1)) {
                    if (CPF_DOT_INDEXES.includes(index)) return `${result}.`;
                    if (CPF_HYPHEN_INDEXES.includes(index)) return `${result}-`;
                }
                return result
            }, '');
        return result + CPF_MASK.slice(result.length);
    }

    formatCnpjInput(cnpj) {
        if (!cnpj) return '';

        let result = cnpj
            .slice(0, CNPJ_LENGTH)
            .split('')
            .reduce((acc, digit, index) => {
                const result = `${acc}${digit}`;
                if (!(index === cnpj.length - 1)) {
                    if (CNPJ_DOT_INDEXES.includes(index)) return `${result}.`;
                    if (CNPJ_SLASH_INDEXES.includes(index)) return `${result}/`;
                    if (CNPJ_HYPHEN_INDEXES.includes(index)) return `${result}-`;
                }
                return result
            }, '');
        return result + CNPJ_MASK.slice(result.length);
    }

}

const cpfCnpjUtils = new CpfCnpjUtils();
export default cpfCnpjUtils;
