export default function (price, cotation, exchange) {
    //Calculo do Valor Atualizado
    // fatores -> ver no back
    // cifValue = ((((cotation + reward) * fator_BU_TON ) - fobbings ) * fator_TON_SC) * exchange
    // if(!fob)
    //     return cifValue
    // return cifValue - (shippingValue * fator_TON_SC)
    const fobbings = price.shippingAgreementTypeFobbings || 0
    const reward = price.reward
    const shippingValue = price.freightValuePerTon
    const multiplierTONtoPriceUnit = price.multiplierTONtoPriceUnit
    const multiplierBUtoTON = price.multiplierBUtoTON
    const cifValue = ((reward + cotation) * multiplierBUtoTON - fobbings) * exchange / multiplierTONtoPriceUnit

    if (price.shippingAgreementTypeCode !== 'FOB')
        return cifValue
    return cifValue - (shippingValue / multiplierTONtoPriceUnit)
}
