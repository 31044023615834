import * as types from './actionTypes'
import { dayOfferActions, negotiationsHistoryActions } from '../Actions'
import { TradeService, alertService } from '../Services'

const tradeService = new TradeService();

export const requestBusinessConfirmation = () => ({
    type: types.REQUEST_BUSINESS_CONFIRMATION,
    payload: null
});

export const requestBusinessConfirmationSuccess = businessConfirmation => ({
    type: types.REQUEST_BUSINESS_CONFIRMATION_SUCCESS,
    payload: businessConfirmation
})

export const requestBusinessConfirmationError = error => ({
    type: types.REQUEST_BUSINESS_CONFIRMATION_ERROR,
    payload: error
});

export const fetchBusinessConfirmation = (offerId, hideModal) => dispatch => {
    dispatch(requestBusinessConfirmation());
    tradeService.getConfirmationByOffer(offerId)
        .then(response => {
            if (response.data) {
                const confirmation = response.data
                const bankOptions = []
                for (let i = 1; i <= 4; i++) {
                    if (confirmation.sellerClient[`bankData${i}`])
                        bankOptions.push({ value: i, label: confirmation.sellerClient[`bankData${i}`] })
                }
                dispatch(requestBusinessConfirmationSuccess({ ...confirmation, sellerClient: { ...confirmation.sellerClient, bankOptions } }));
            }
        })
        .catch(error => {
            dispatch(requestBusinessConfirmationError(error));
            hideModal();
            alertService.error(error, 'Erro ao buscar confirmação de negócio');
        });
};

export const requestConfirmBusinessConfirmation = () => ({
    type: types.REQUEST_CONFIRM_BUSINESS_CONFIRMATION,
    payload: null
});

export const requestConfirmBusinessConfirmationSuccess = () => ({
    type: types.REQUEST_CONFIRM_BUSINESS_CONFIRMATION_SUCCESS,
    payload: null
})

export const requestConfirmBusinessConfirmationError = error => ({
    type: types.REQUEST_CONFIRM_BUSINESS_CONFIRMATION_ERROR,
    payload: error
});

export const confirmBusinessConfirmation = (confirmationId, data, offerId, hideModal, isHistory = false) => dispatch => {
    dispatch(requestConfirmBusinessConfirmation());

    tradeService.updateAndConfirm(confirmationId, data)
        .then(response => {
            if (response.data) {
                dispatch(requestConfirmBusinessConfirmationSuccess());

                if (isHistory)
                    dispatch(negotiationsHistoryActions.updateNegotiation(offerId));
                else
                    dispatch(dayOfferActions.updateOffer(offerId));

                hideModal()
                alertService.success('Confirmação de negócio enviada com sucesso!');
            }
        })
        .catch(error => {
            dispatch(requestConfirmBusinessConfirmationError(error));
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao enviar confirmação de negócio');
        });
};

export const requestContractFile = () => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT,
    payload: null
});

export const requestContractFileSuccess = contract => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT_SUCCESS,
    payload: contract
})

export const requestContractFileError = error => ({
    type: types.REQUEST_NEGOTIATION_CONTRACT_ERROR,
    payload: error
});

export const fetchContract = (contractURL, fileName) => dispatch => {
    dispatch(requestContractFile());
    tradeService.getNegotiationContract(contractURL)
        .then(response => {
            if (response.data) {
                var blob = new Blob([response.data], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob);
                // var win = window.open(url, '_blank');
                // win.focus();
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Contrato_${fileName}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                
                dispatch(requestContractFileSuccess());

            }
        })
        .catch(error => {
            dispatch(requestContractFileError(error));
            alertService.error(error, 'Erro ao baixar contrato');
        });
};

export const requestResendEmail = () => ({
    type: types.REQUEST_RESEND_EMAIL,
    payload: null
});

export const requestResendEmailSuccess = () => ({
    type: types.REQUEST_RESEND_EMAIL_SUCCESS,
    payload: null
})

export const requestResendEmailError = error => ({
    type: types.REQUEST_RESEND_EMAIL_ERROR,
    payload: error
});

export const resendEmail = tradeId => dispatch => {
    dispatch(requestResendEmail());
    tradeService.resendContractEmail(tradeId)
        .then(() => {
                dispatch(requestResendEmailSuccess());
                alertService.success('Confirmação de negócio reenviada com sucesso!');
        })
        .catch(error => {
            dispatch(requestResendEmailError(error));
            alertService.error(error, 'Erro ao reenviar confirmação de negócio');
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_BUSINESS_CONFIRMATION_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_BUSINESS_CONFIRMATION_FORM,
    payload: null
});
