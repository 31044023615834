import initialState from '../initialState'
import {
    REQUEST_EDIT_PORT_TERMINAL,
    REQUEST_EDIT_PORT_TERMINAL_SUCCESS,
    REQUEST_EDIT_PORT_TERMINAL_ERROR,
    REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS,
    REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_SUCCESS,
    REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_ERROR,
    HANDLE_EDIT_PORT_TERMINAL_FORM_VALUE_CHANGE,
    CLEAR_EDIT_PORT_TERMINAL_FORM
} from '../../Actions/actionTypes'


const requestEditPortTerminal = (state, action) => {
    return { ...state, isSaving: true };
};

const requestEditPortTerminalSuccess = (state, action) => {
    return initialState.editPortTerminalForm;
};

const requestEditPortTerminalError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const requestPortTerminalDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPortTerminalDetailsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestPortTerminalDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const clear = (state, action) => {
    return initialState.editPortTerminalForm
}

const actions = {
    [REQUEST_EDIT_PORT_TERMINAL]: requestEditPortTerminal,
    [REQUEST_EDIT_PORT_TERMINAL_SUCCESS]: requestEditPortTerminalSuccess,
    [REQUEST_EDIT_PORT_TERMINAL_ERROR]: requestEditPortTerminalError,
    [REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS]: requestPortTerminalDetails,
    [REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_SUCCESS]: requestPortTerminalDetailsSuccess,
    [REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_ERROR]: requestPortTerminalDetailsError,
    [HANDLE_EDIT_PORT_TERMINAL_FORM_VALUE_CHANGE]: handleValueChange,
    [CLEAR_EDIT_PORT_TERMINAL_FORM]: clear
};

export const editPortTerminalForm = (state = initialState.editPortTerminalForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
