import BaseService from './base.service'

class PortTerminalService extends BaseService {
  constructor() {
    super('PortTerminal');
  }

  toggleStatus(id) {
    return this.post({}, `/${id}/ActiveToggle`)
  }
}

export default PortTerminalService;
