import * as types from '../actionTypes'
import { alertService, PriceService } from '../../Services'
import { redirectUtils, dateUtils } from '../../Utils'
const priceService = new PriceService()

export const requestSavePriceForm = () => ({
    type: types.REQUEST_SAVE_PRICE_FORM,
    payload: null
});

export const requestSavePriceFormSuccess = price => ({
    type: types.REQUEST_SAVE_PRICE_FORM_SUCCESS,
    payload: price
});

export const requestSavePriceFormError = error => ({
    type: types.REQUEST_SAVE_PRICE_FORM_ERROR,
    payload: error
});

export const handleSave = priceForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(priceForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    const newPrice = {
        ...priceForm,
        value: priceForm.value / 100,
        tradeOn: dateUtils.format(priceForm.tradeOn, 'YYYY-MM-DD'),
        paymentOn: dateUtils.format(priceForm.paymentOn, 'YYYY-MM-DD'),
        deliveryStartsOn: priceForm.immediateDelivery ? '' : dateUtils.format(priceForm.deliveryStartsOn, 'YYYY-MM-DD'),
        deliveryEndsOn: priceForm.immediateDelivery ? '' : dateUtils.format(priceForm.deliveryEndsOn, 'YYYY-MM-DD'),
        exchangeFee: priceForm.exchange,
        reward: priceForm.rewardValue,
        dirtRoadLength: priceForm.dirtRoadLength || 0
    }

    if (priceForm.shippingAgreementType.hasFreight) {
        delete newPrice.portTerminalId
        delete newPrice.suggestedLocationId
    } else if (!priceForm.suggestedLocationId) {
        delete newPrice.dirtRoadLength
    }

    dispatch(requestSavePriceForm());

    priceService.saveOrUpdate(newPrice)
        .then(response => {
            dispatch(requestSavePriceFormSuccess())
            redirectUtils.redirectTo('#/precos')
            alertService.success('Preço criado com sucesso!')
        })
        .catch(error => {
            dispatch(requestSavePriceFormError({ data: priceForm, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar preço')
        })
}

const checkRequiredFields = pf => {
    let alerts = []
    let isInvalid = false

    if (!pf.immediateDelivery && !dateUtils.isSameOrAfter(pf.deliveryStartsOn, pf.deliveryEndsOn)) {
        alerts.push('Final da entrega deve ser igual ou após início da entrega!')
        isInvalid = true
    }

    return [isInvalid, alerts]
}

export const clear = () => ({
    type: types.CLEAR_PRICE_FORM,
    payload: null
});

export const requestReward = () => ({
    type: types.REQUEST_PRICE_REWARD,
    payload: null
});

export const requestRewardSuccess = rewardData => ({
    type: types.REQUEST_PRICE_REWARD_SUCCESS,
    payload: rewardData
})

export const requestRewardError = error => ({
    type: types.REQUEST_PRICE_REWARD_ERROR,
    payload: error
});

export const calculateReward = (priceInfo, resetIntervals) => dispatch => {
    dispatch(requestReward());

    priceInfo.value = priceInfo.value / 100
    console.log(priceInfo)
    priceService.calculateReward(priceInfo)
        .then(response => {
            if (response.data) {
                dispatch(requestRewardSuccess(response.data))
            }
            resetIntervals()
        }).catch(error => {
            dispatch(requestRewardError(error))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao calcular prêmio')
        })
};

export const handleValueChange = edition => ({
    type: types.HANDLE_PRICE_FORM_VALUE_CHANGE,
    payload: edition
});
