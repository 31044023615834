import * as types from '../actionTypes'
import { PortTerminalService, alertService } from '../../Services'

const portTerminalService = new PortTerminalService();

export const requestPortTerminalDetails = () => ({
    type: types.REQUEST_PORT_TERMINAL_DETAILS,
    payload: null
});

export const requestPortTerminalDetailsSuccess = details => ({
    type: types.REQUEST_PORT_TERMINAL_DETAILS_SUCCESS,
    payload: details
})

export const requestPortTerminalDetailsError = error => ({
    type: types.REQUEST_PORT_TERMINAL_DETAILS_ERROR,
    payload: error
});

export const fetchPortTerminal = id => dispatch => {
    dispatch(requestPortTerminalDetails());
    portTerminalService.getById(id)
        .then(response => {
            if (response.data) {
                dispatch(requestPortTerminalDetailsSuccess(response.data));
            }
        })
        .catch(error => {
            dispatch(requestPortTerminalDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do terminal portuário');
        });
};

export const requestPortTerminalStatusToggle = () => ({
    type: types.REQUEST_PORT_TERMINAL_STATUS_TOGGLE,
    payload: null
});

export const requestPortTerminalStatusToggleSuccess = () => ({
    type: types.REQUEST_PORT_TERMINAL_STATUS_TOGGLE_SUCCESS,
    payload: null
})

export const requestPortTerminalStatusToggleError = error => ({
    type: types.REQUEST_PORT_TERMINAL_STATUS_TOGGLE_ERROR,
    payload: error
});

export const toggleStatus = (id, isActive) => dispatch => {
    dispatch(requestPortTerminalStatusToggle());

    portTerminalService.toggleStatus(id)
        .then(response => {
            if (response.data) {
                const sucessMessage = isActive ? 'ativado' : 'inativado'
                dispatch(requestPortTerminalStatusToggleSuccess())
                alertService.success(`Terminal portuário ${sucessMessage} com sucesso!`)
            }
        })
        .catch(error => {
            const errorMessage = isActive ? 'ativar' : 'inativar'
            dispatch(requestPortTerminalStatusToggleError(error));
            alertService.error(error, `Erro ao ${errorMessage} terminal portuário`)
        });
};

export const clear = () => ({
    type: types.CLEAR_PORT_TERMINAL_DETAILS,
    payload: null
});
