import React from 'react'

const FormInput = ({ value, onChange, glyphicon, placeholder, type, name, containerClass, inputClass, addon, append, rightIcon, required, autoFocus, maxLength, disabled }) => {

    const prependAddon = addon && !append ?
        <div className='input-group-prepend'>
            <div className='input-group-text'>
                <span className={`glyphicon glyphicon-${glyphicon}`}></span>
            </div>
        </div > : null;
    const appendAddon = addon && append ?
        <div className='input-group-append'>
            <div className='input-group-text'>
                <span className={`glyphicon glyphicon-${glyphicon}`}></span>
            </div>
        </div > : null;

    const feedbackIcon = rightIcon ? <span className={`glyphicon glyphicon-${glyphicon} form-control-feedback`}></span>
        : null;

    containerClass = rightIcon
        ? containerClass + ' has-feedback'
        : containerClass + ' input-group'

    inputClass = inputClass ? inputClass : '';

    return (
        <>
            <div className={`${containerClass}`}>
                {prependAddon}
                <input
                    type={type}
                    className={`form-control ${inputClass}`}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    required={required}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    disabled={disabled}
                    onChange={e => onChange(e)}
                />
                {feedbackIcon}
                {appendAddon}
            </div>
        </>
    )
}

export default FormInput
