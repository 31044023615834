import React from 'react'
import ReactDOM from 'react-dom'
import Loader from 'react-loader'

export default function Modal({ isShowing, hide, content, btnClass, btnTxt, onClick, containerClass, isLoading = false, hideClose = false, loadingMessage }) {
  return isShowing ? ReactDOM.createPortal(
    <>
      <div className='modal-overlay' />
      <div className='modal-wrapper' aria-modal aria-hidden tabIndex={-1} role='dialog'>
        <div className={`modal-container modal-size-regular ${containerClass}`}>
          <div className='pointer-events-auto p-5 text-center'>
            <Loader loaded={!isLoading} color='#fff'>
              <div className='modal-header mb-5'>
                {!hideClose &&
                  <button type='button' className='modal-close-button' data-dismiss='modal' aria-label='Close' onClick={hide}>
                    <span aria-hidden='true'>&times;</span>
                  </button>
                }
              </div>
              {typeof content === 'string' ?
                <>
                  <span className='modal-content-text '>{content}</span>
                  <div className='mt-4'>
                    <button className='btn inova-btn secondary mr-3' onClick={hide}>Cancelar</button>
                    <button className={`btn inova-btn ${btnClass}`} onClick={onClick}>{btnTxt}</button>
                  </div>
                </>
                :
                content}
            </Loader>
          </div>
          {loadingMessage &&
            <div className='modal-message-container'>
              <p className='message'>{loadingMessage}</p>
            </div>
          }
        </div>
      </div>
    </>, document.body
  ) : null;
}
