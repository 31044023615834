import initialState from './initialState';
import { REQUEST_PRODUCTS, REQUEST_PRODUCTS_SUCCESS, REQUEST_PRODUCTS_ERROR } from '../Actions/actionTypes';

const requestProducts = (state, action) => {
    return { ...state, isFetching: true };
};

const requestProductsSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload.options,
        entities: action.payload.entities,
        isFetching: false
    };
};

const requestProductsError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_PRODUCTS]: requestProducts,
    [REQUEST_PRODUCTS_SUCCESS]: requestProductsSuccess,
    [REQUEST_PRODUCTS_ERROR]: requestProductsError
};

export const products = (state = initialState.products, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
