import React from "react";
import Loadable from "react-loadable";
import Loader from "react-loader";
import { localStorageUtils, redirectUtils } from "../../Utils";
import { Root } from "../../Modules";

function Loading() {
  return <Loader loaded={false} color="#fff" />;
}

const Dashboard = Loadable({
  loader: () => import("../../Modules/Dashboard/dashboard.container"),
  loading: Loading,
});

const Clients = Loadable({
  loader: () => import("../../Modules/Clients/clients.container"),
  loading: Loading,
});

const ClientFormContainer = Loadable({
  loader: () => import("../../Modules/Clients/Form/clientForm.container"),
  loading: Loading,
});

const EditClientFormContainer = Loadable({
  loader: () => import("../../Modules/Clients/Form/editClientForm.container"),
  loading: Loading,
});

const ClientDetailsContainer = Loadable({
  loader: () => import("../../Modules/Clients/Details/clientDetails.container"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("../../Modules/Users/users.container"),
  loading: Loading,
});

const UserFormContainer = Loadable({
  loader: () => import("../../Modules/Users/Form/userForm.container"),
  loading: Loading,
});

const EditUserFormContainer = Loadable({
  loader: () => import("../../Modules/Users/Form/editUserForm.container"),
  loading: Loading,
});

const UserDetailsContainer = Loadable({
  loader: () => import("../../Modules/Users/Details/userDetails.container"),
  loading: Loading,
});

const PortTerminals = Loadable({
  loader: () => import("../../Modules/Port Terminals/portTerminals.container"),
  loading: Loading,
});

const PortTerminalFormContainer = Loadable({
  loader: () =>
    import("../../Modules/Port Terminals/Form/portTerminalForm.container"),
  loading: Loading,
});

const EditPortTerminalFormContainer = Loadable({
  loader: () =>
    import("../../Modules/Port Terminals/Form/editPortTerminalForm.container"),
  loading: Loading,
});

const PortTerminalDetailsContainer = Loadable({
  loader: () =>
    import(
      "../../Modules/Port Terminals/Details/portTerminalDetails.container"
    ),
  loading: Loading,
});

const Prices = Loadable({
  loader: () => import("../../Modules/Prices/prices.container.js"),
  loading: Loading,
});

const PriceFormContainer = Loadable({
  loader: () => import("../../Modules/Prices/Form/priceForm.container"),
  loading: Loading,
});

const PricesIndicationsContainer = Loadable({
  loader: () =>
    import("../../Modules/Prices/Indications/indications.container"),
  loading: Loading,
});

const PricesHistoryContainer = Loadable({
  loader: () => import("../../Modules/Prices/History/pricesHistory.container"),
  loading: Loading,
});

const DayOffersContainer = Loadable({
  loader: () => import("../../Modules/Offers/Day Offers/dayOffers.container"),
  loading: Loading,
});

const NegotiationsHistoryContainer = Loadable({
  loader: () =>
    import(
      "../../Modules/Offers/Negotiation History/negotiationsHistory.container"
    ),
  loading: Loading,
});

const CreateOfferClientContainer = Loadable({
  loader: () =>
    import("../../Modules/Offers/CreateOffer/createOfferClient.container"),
  loading: Loading,
});

const HarvestsContainer = Loadable({
  loader: () => import("../../Modules/Harvests/harvests.container"),
  loading: Loading,
});

const CBOTContainer = Loadable({
  loader: () => import("../../Modules/CBOT/cbot.container"),
  loading: Loading,
});

const MarketHoursContainer = Loadable({
  loader: () => import("../../Modules/Market Hours/marketHours.container"),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => {
    localStorageUtils.removeAuthToken();
    redirectUtils.redirectTo("/");
  },
  loading: Loading,
});

const routes = [
  {
    path: "/",
    exact: true,
    component: Root,
  },
  {
    path: "/dashboard",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/clientes",
    exact: true,
    name: "Clientes",
    component: Clients,
  },
  {
    path: "/clientes/novo",
    exact: true,
    name: "Novo cliente",
    component: ClientFormContainer,
  },
  {
    path: "/clientes/editar/:id",
    exact: true,
    name: "Editar cliente",
    component: EditClientFormContainer,
  },
  {
    path: "/clientes/detalhe/:id",
    name: "Cliente",
    component: ClientDetailsContainer,
  },
  {
    path: "/usuarios",
    exact: true,
    name: "Usuários",
    component: Users,
  },
  {
    path: "/usuarios/novo",
    exact: true,
    name: "Novo usuário",
    component: UserFormContainer,
  },
  {
    path: "/usuarios/editar/:id",
    exact: true,
    name: "Editar usuário",
    component: EditUserFormContainer,
  },
  {
    path: "/usuarios/detalhe/:registerType/:id",
    name: "Usuário",
    component: UserDetailsContainer,
  },
  {
    path: "/terminais",
    exact: true,
    name: "Terminais Portuários",
    component: PortTerminals,
  },
  {
    path: "/terminais/novo",
    exact: true,
    name: "Novo terminal portuário",
    component: PortTerminalFormContainer,
  },
  {
    path: "/terminais/editar/:id",
    exact: true,
    name: "Editar terminal portuário",
    component: EditPortTerminalFormContainer,
  },
  {
    path: "/terminais/detalhe/:id",
    name: "Terminal Portuário",
    component: PortTerminalDetailsContainer,
  },
  {
    path: "/precos",
    exact: true,
    name: "Gerar Preços",
    component: Prices,
  },
  {
    path: "/precos/novo",
    exact: true,
    name: "Novo preço",
    component: PriceFormContainer,
  },
  {
    path: "/indicacoes",
    exact: true,
    name: "Indicações de preços",
    component: PricesIndicationsContainer,
  },
  {
    path: "/historico-preco",
    exact: true,
    name: "Histórico de preços",
    component: PricesHistoryContainer,
  },
  {
    path: "/ofertas",
    exact: true,
    name: "Ofertas do dia",
    component: DayOffersContainer,
  },
  {
    path: "/historico-negociacoes",
    exact: true,
    name: "Histórico de negociações",
    component: NegotiationsHistoryContainer,
  },
  {
    path: "/criar-oferta-cliente",
    exact: true,
    name: "Criar Oferta para Cliente",
    component: CreateOfferClientContainer,
  },
  {
    path: "/safras",
    exact: true,
    name: "Safras",
    component: HarvestsContainer,
  },
  {
    path: "/cbot",
    exact: true,
    name: "Cotação da Bolsa",
    component: CBOTContainer,
  },
  {
    path: "/horarios",
    exact: true,
    name: "Horários do mercado",
    component: MarketHoursContainer,
  },
  { path: "/logout", name: "Logout", component: Logout },
];

export default routes;
