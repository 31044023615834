import initialState from '../initialState'
import {
    REQUEST_CLIENT_DETAILS,
    REQUEST_CLIENT_DETAILS_SUCCESS,
    REQUEST_CLIENT_DETAILS_ERROR,
    REQUEST_CLIENT_STATUS_TOGGLE,
    REQUEST_CLIENT_STATUS_TOGGLE_SUCCESS,
    REQUEST_CLIENT_STATUS_TOGGLE_ERROR,
    CLEAR_CLIENT_DETAILS
} from '../../Actions/actionTypes'

const requestClientDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestClientDetailsSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isFetching: false
    };
};

const requestClientDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestClientStatusToggle = (state, action) => {
    return { ...state, data: { ...state.data, active: !state.data.active } };
};

const requestClientStatusToggleSuccess = (state, action) => {
    return { ...state };
};

const requestClientStatusToggleError = (state, action) => {
    return { ...state, data: { ...state.data, active: !state.data.active } };
};

const clear = (state, action) => {
    return initialState.clientDetails;
};

const actions = {
    [REQUEST_CLIENT_DETAILS]: requestClientDetails,
    [REQUEST_CLIENT_DETAILS_SUCCESS]: requestClientDetailsSuccess,
    [REQUEST_CLIENT_DETAILS_ERROR]: requestClientDetailsError,
    [REQUEST_CLIENT_STATUS_TOGGLE]: requestClientStatusToggle,
    [REQUEST_CLIENT_STATUS_TOGGLE_SUCCESS]: requestClientStatusToggleSuccess,
    [REQUEST_CLIENT_STATUS_TOGGLE_ERROR]: requestClientStatusToggleError,
    [CLEAR_CLIENT_DETAILS]: clear
};

export const clientDetails = (state = initialState.clientDetails, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
