import initialState from '../initialState'
import {
    REQUEST_PORT_TERMINAL_DETAILS,
    REQUEST_PORT_TERMINAL_DETAILS_SUCCESS,
    REQUEST_PORT_TERMINAL_DETAILS_ERROR,
    REQUEST_PORT_TERMINAL_STATUS_TOGGLE,
    REQUEST_PORT_TERMINAL_STATUS_TOGGLE_SUCCESS,
    REQUEST_PORT_TERMINAL_STATUS_TOGGLE_ERROR,
    CLEAR_PORT_TERMINAL_DETAILS
} from '../../Actions/actionTypes'

const requestPortTerminalDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPortTerminalDetailsSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isFetching: false
    };
};

const requestPortTerminalDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestPortTerminalStatusToggle = (state, action) => {
    return { ...state, data: { ...state.data, active: !state.data.active } };
};

const requestPortTerminalStatusToggleSuccess = (state, action) => {
    return { ...state };
};

const requestPortTerminalStatusToggleError = (state, action) => {
    return { ...state, data: { ...state.data, active: !state.data.active } };
};

const clear = (state, action) => {
    return initialState.portTerminalDetails;
};

const actions = {
    [REQUEST_PORT_TERMINAL_DETAILS]: requestPortTerminalDetails,
    [REQUEST_PORT_TERMINAL_DETAILS_SUCCESS]: requestPortTerminalDetailsSuccess,
    [REQUEST_PORT_TERMINAL_DETAILS_ERROR]: requestPortTerminalDetailsError,
    [REQUEST_PORT_TERMINAL_STATUS_TOGGLE]: requestPortTerminalStatusToggle,
    [REQUEST_PORT_TERMINAL_STATUS_TOGGLE_SUCCESS]: requestPortTerminalStatusToggleSuccess,
    [REQUEST_PORT_TERMINAL_STATUS_TOGGLE_ERROR]: requestPortTerminalStatusToggleError,
    [CLEAR_PORT_TERMINAL_DETAILS]: clear
};

export const portTerminalDetails = (state = initialState.portTerminalDetails, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
