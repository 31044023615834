import React, { Component } from 'react'
import PropTypes from 'prop-types'

class RadioInput extends Component {
  render() {
    return (
      <div className='radio-input'>
        <label className='container' style={this.props.inputStyle}>
          {this.props.label}
          <input
            type='radio'
            disabled={this.props.disabled}
            checked={this.props.checked}
            required={this.props.required}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
          />
          <span className='checkmark' style={this.props.inputStyle} />
        </label>
        <label className='tooltip'>{this.props.tooltipText}</label>
      </div>
    );
  }
}

RadioInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.bool
};

export default RadioInput;
