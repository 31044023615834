import initialState from '../initialState'
import {
    REQUEST_SAVE_CLIENT_FORM,
    REQUEST_SAVE_CLIENT_FORM_SUCCESS,
    REQUEST_SAVE_CLIENT_FORM_ERROR,
    CLEAR_CLIENT_FORM
} from '../../Actions/actionTypes'


const requestSaveClientForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveClientFormSuccess = (state, action) => {
    return initialState.clientForm;
};

const requestSaveClientFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clearClientForm = (state, action) => {
    return initialState.clientForm
}

const actions = {
    [REQUEST_SAVE_CLIENT_FORM]: requestSaveClientForm,
    [REQUEST_SAVE_CLIENT_FORM_SUCCESS]: requestSaveClientFormSuccess,
    [REQUEST_SAVE_CLIENT_FORM_ERROR]: requestSaveClientFormError,
    [CLEAR_CLIENT_FORM]: clearClientForm
};

export const clientForm = (state = initialState.clientForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
