import React from 'react'
import { closedSign } from '../../Assets'

export default function ClosedMarketMessage(props) {

    return (
        <div className='closed-message'>
            <img src={closedSign} className='' width='200px' alt='closed' />
            <span className='title'>Mercado fechado!</span>
            <span className='text-center'>{`Retorne a partir da abertura do mercado`}<br/>{`para ${props.message}.`}</span>
        </div>
    )
}
