import React from 'react'
import ReactDOM from 'react-dom'
import { Root } from './Modules'
import { localStorageUtils, redirectUtils } from './Utils'
import { alertService } from './Services'
import axios from 'axios'
import './Styles/index.scss'

// React Toastify
import 'react-toastify/dist/ReactToastify.min.css'

// Redux,
import { Provider } from 'react-redux'
import configureStore from './Store/configureStore'

const store = configureStore();

axios.interceptors.request.use(function (config) {
    const authToken = localStorageUtils.getAuthToken();
    if (authToken) {
        config.headers.common['Authorization'] = `bearer ${authToken}`;
    }

    return config;
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorageUtils.removeAuthToken();
            alertService.info('Sua sessão expirou. Você será redirecionado para o login.', { toastId: 'expiredSession' });
            redirectUtils.redirectTo('/#/login');
        }
        return Promise.reject(error);
    }
);

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    document.getElementById('root'));