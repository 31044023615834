import * as types from './actionTypes'
import { AuthService, alertService } from '../Services'
import { localStorageUtils, redirectUtils } from '../Utils';

const authService = new AuthService()

export const requestAuth = () => ({
    type: types.REQUEST_AUTH,
    payload: null
});

export const requestAuthSuccess = () => ({
    type: types.REQUEST_AUTH_SUCCESS,
    payload: null
});

export const requestAuthError = error => ({
    type: types.REQUEST_AUTH_ERROR,
    payload: error
});

export const requestLogin = data => dispatch => {
    dispatch(requestAuth());
    authService.post(data, 'administrator')
        .then(res => {
            const user = res.data;
            localStorageUtils.setAuthToken(user.token);
            dispatch(requestAuthSuccess());
            redirectUtils.redirectTo('#/dashboard');
        })
        .catch(err => {
            dispatch(requestAuthError(err));
            alertService.error(err, 'Falha ao logar! Verifique seu email e/ou senha!');
        })
};
