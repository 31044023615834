import React from 'react'
import { toast } from 'react-toastify'

const defaultOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
}

class AlertService {
    info(message, options) {
        if (!toast.isActive('expiredSession'))
            toast.info(message, { ...defaultOptions, ...options })
    }

    success(message, options = defaultOptions) {
        const toastMessage = <span><i className='fa fa-check mr-2' />{message}</span>
        toast.success(toastMessage, { ...options, className: 'alert-sucess' })
    }

    warning(message, options = defaultOptions) {
        toast.warning(message, options)
    }

    error(error, message, options = defaultOptions) {
        if (error && error.response && (error.response.status !== 401 && error.response.status !== 403))
            toast.error(message, { ...options, className: 'alert-error' })
    }

    default(message, options = defaultOptions) {
        toast.default(message, options)
    }

    infoRedirect(message, onClick, options = defaultOptions) {
        toast.info(<div onClick={() => { onClick(); toast.dismiss(); }}>{message}</div>, options)
    }
}

const alertService = new AlertService();
export default alertService;
