import initialState from '../initialState';
import { REQUEST_SAVE_USER_FORM, REQUEST_SAVE_USER_FORM_SUCCESS, REQUEST_SAVE_USER_FORM_ERROR, CLEAR_USER_FORM } from '../../Actions/actionTypes';


const requestSaveUserForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveUserFormSuccess = (state, action) => {
    return initialState.userForm;
};

const requestSaveUserFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clearUserForm = (state, action) => {
    return initialState.userForm
}

const actions = {
    [REQUEST_SAVE_USER_FORM]: requestSaveUserForm,
    [REQUEST_SAVE_USER_FORM_SUCCESS]: requestSaveUserFormSuccess,
    [REQUEST_SAVE_USER_FORM_ERROR]: requestSaveUserFormError,
    [CLEAR_USER_FORM]: clearUserForm
};

export const userForm = (state = initialState.userForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
