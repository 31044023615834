import * as types from '../actionTypes'
import { cpfCnpjUtils } from '../../Utils'
import { ClientService, alertService } from '../../Services'

const clientService = new ClientService();

export const requestClients = () => ({
    type: types.REQUEST_CLIENTS,
    payload: null
});

export const requestClientsSuccess = clients => ({
    type: types.REQUEST_CLIENTS_SUCCESS,
    payload: clients
})

export const requestClientsError = error => ({
    type: types.REQUEST_CLIENTS_ERROR,
    payload: error
})

export const fetchClients = options => dispatch => {
    dispatch(requestClients());
    clientService.findAll(options)
        .then(response => {
            if (response.data) {
                dispatch(requestClientsSuccess({ count: response.data.totalResults, entities: response.data.results || [] }))
            }
        })
        .catch(error => {
            dispatch(requestClientsError(error));
            alertService.error(error, 'Erro ao buscar clientes');
        })
}

export const fetchClientsOptions = () => dispatch => {
    dispatch(requestClients());
    clientService.findAll()
        .then(response => {
            if (response.data) {
                const nameOptions = []
                const cpfCnpjOptions = []

                response.data.results.forEach(client => {
                    nameOptions.push({
                        label: client.tradeName,
                        value: client.id,
                        cpfcnpj: client.cpfcnpj,
                        activityType: client.activityType,
                        isSeller: client.isSeller,
                        isHeadOffice: client.isHeadOffice,
                        active: client.active
                    })

                    cpfCnpjOptions.push({
                        label: `${client.tradeName || client.legalName} - ${cpfCnpjUtils.format(client.cpfcnpj)}`,
                        value: client.id,
                        tradeName: client.tradeName,
                        activityType: client.activityType,
                        address: client.address,
                        cityName: client.cityName,
                        uf: client.uf,
                        isSeller: client.isSeller,
                        isHeadOffice: client.isHeadOffice,
                        active: client.active,
                        legalName: client.legalName
                    })
                });

                dispatch(requestClientsSuccess({ nameOptions, cpfCnpjOptions }))
            }
        })
        .catch(error => {
            dispatch(requestClientsError(error));
            alertService.error(error, 'Erro ao buscar opções de restrição comercial');
        })
}

export const fetchBuyerOptions = () => dispatch => {
    dispatch(requestClients());
    clientService.findAll({ isSeller: false })
        .then(response => {
            if (response.data) {
                const buyersOptions = response.data.results.map(client => { return { label: client.legalName, value: client.id } })
                dispatch(requestClientsSuccess({ buyersOptions }))
            }
        })
        .catch(error => {
            dispatch(requestClientsError(error));
            alertService.error(error, 'Erro ao buscar opções de compradores');
        })
}

export const handleValueChange = edition => ({
    type: types.HANDLE_CLIENTS_VALUE_CHANGE,
    payload: edition
});

export const updateClientsList = client => ({
    type: types.UPDATE_CLIENTS_LIST,
    payload: client
})
