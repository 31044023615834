import React from 'react'

export default function ActivityRow(props) {
    return (
        <div className='activity-row'>
            <span>{props.name}</span>
            <span className='hour'>{props.hour ? props.hour.slice(0, 8) : '-'}</span>
        </div>
    );
}
