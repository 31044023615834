import * as types from '../actionTypes'
import { PortTerminalService, alertService } from '../../Services'
import { redirectUtils, cpfCnpjUtils } from '../../Utils'
const portTerminalService = new PortTerminalService();

export const requestSavePortTerminalForm = () => ({
    type: types.REQUEST_SAVE_PORT_TERMINAL_FORM,
    payload: null
});

export const requestSavePortTerminalFormSuccess = portTerminal => ({
    type: types.REQUEST_SAVE_PORT_TERMINAL_FORM_SUCCESS,
    payload: portTerminal
});

export const requestSavePortTerminalFormError = error => ({
    type: types.REQUEST_SAVE_PORT_TERMINAL_FORM_ERROR,
    payload: error
});

export const handleSave = portTerminalForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(portTerminalForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestSavePortTerminalForm());

    portTerminalService
        .saveOrUpdate({ ...portTerminalForm })
        .then(response => {
            dispatch(requestSavePortTerminalFormSuccess())
            redirectUtils.redirectTo('#/terminais')
            alertService.success('Terminal portuário criado com sucesso!')
        })
        .catch(error => {
            const data = { ...portTerminalForm }
            dispatch(requestSavePortTerminalFormError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar terminal portuário')
        })
}

const checkRequiredFields = ptf => {
    let alerts = []
    let isInvalid = false

    if (ptf.legalName.length === 0) {
        isInvalid = true
    }
    if (!cpfCnpjUtils.isValid(ptf.cpfcnpj)) {
        alerts.push('Campo CPF/CNPJ inválido!')
        isInvalid = true
    }
    if (ptf.federalRegistration.length === 0) {
        isInvalid = true
    }
    if (ptf.cep.length !== 8) {
        alerts.push('CEP inválido!')
        isInvalid = true
    }
    if (ptf.address.length === 0) {
        isInvalid = true
    }
    if (ptf.cityId === '') {
        isInvalid = true
    }
    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const clear = () => ({
    type: types.CLEAR_PORT_TERMINAL_FORM,
    payload: null
});
