import initialState from './initialState';
import {
    REQUEST_SAVE_HARVEST,
    REQUEST_SAVE_HARVEST_SUCCESS,
    REQUEST_SAVE_HARVEST_ERROR,
    CLEAR_HARVEST_FORM
} from '../Actions/actionTypes';


const requestSaveHarvest = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveHarvestSuccess = (state, action) => {
    return initialState.harvestForm;
};

const requestSaveHarvestError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clearHarvestForm = (state, action) => {
    return initialState.harvestForm
}

const actions = {
    [REQUEST_SAVE_HARVEST]: requestSaveHarvest,
    [REQUEST_SAVE_HARVEST_SUCCESS]: requestSaveHarvestSuccess,
    [REQUEST_SAVE_HARVEST_ERROR]: requestSaveHarvestError,
    [CLEAR_HARVEST_FORM]: clearHarvestForm
};

export const harvestForm = (state = initialState.harvestForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
