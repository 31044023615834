import * as types from '../actionTypes'
import { UserService, UserPreRegisterService, alertService } from '../../Services'
import { redirectUtils } from '../../Utils'

const userService = new UserService();
const userPreRegisterService = new UserPreRegisterService();

export const requestUsers = () => ({
    type: types.REQUEST_USERS,
    payload: null
});

export const fetchUserByClientId = options => dispatch => {
    dispatch(requestUsers());
    userService.findAll(options)
        .then(response => {
            if (response.data) {
                dispatch(requestUserDetailsSuccess({ ...response.data }));
            }
        })
        .catch(error => {
            dispatch(requestUserDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do usuário');
        });
}

export const requestUserDetails = () => ({
    type: types.REQUEST_USER_DETAILS,
    payload: null
});

export const requestUserDetailsSuccess = details => ({
    type: types.REQUEST_USER_DETAILS_SUCCESS,
    payload: details
})

export const requestUserDetailsError = error => ({
    type: types.REQUEST_USER_DETAILS_ERROR,
    payload: error
});

export const fetchUser = (id, registerType) => dispatch => {
    dispatch(requestUserDetails());

    const service = registerType === 'pr' ? userPreRegisterService : userService

    service.getById(id)
        .then(response => {
            if (response.data) {
                dispatch(requestUserDetailsSuccess({ ...response.data, isPreRegister: registerType === 'pr' }));
            }
        })
        .catch(error => {
            dispatch(requestUserDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do usuário');
        });
};

export const requestUserApproval = () => ({
    type: types.REQUEST_USER_APPROVAL,
    payload: null
});

export const requestUserApprovalSuccess = () => ({
    type: types.REQUEST_USER_APPROVAL_SUCCESS,
    payload: null
})

export const requestUserApprovalError = error => ({
    type: types.REQUEST_USER_APPROVAL_ERROR,
    payload: error
});

export const approveUser = (id) => dispatch => {
    dispatch(requestUserApproval());

    userPreRegisterService.approve(id)
        .then(response => {
            if (response.data) {
                const id = response.data.id
                dispatch(requestUserApprovalSuccess({ ...response.data, isPreRegister: false }))
                redirectUtils.redirectTo(`#/usuarios/detalhe/r/${id}`)
                alertService.success('Usuário aprovado com sucesso!')
            }
        })
        .catch(error => {
            dispatch(requestUserApprovalError(error))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao aprovar usuário')
        });
};

export const requestUserRejection = () => ({
    type: types.REQUEST_USER_REJECTION,
    payload: null
});

export const requestUserRejectionSuccess = details => ({
    type: types.REQUEST_USER_REJECTION_SUCCESS,
    payload: details
})

export const requestUserRejectionError = error => ({
    type: types.REQUEST_USER_REJECTION_ERROR,
    payload: error
});

export const rejectUser = (id, description, toggleModal) => dispatch => {
    dispatch(requestUserRejection());

    userPreRegisterService.reject(id, description)
        .then(response => {
            if (response.data) {
                dispatch(requestUserRejectionSuccess({ ...response.data, isPreRegister: true }))
                alertService.success('Usuário rejeitado com sucesso!')
                toggleModal()
            }
        })
        .catch(error => {
            dispatch(requestUserRejectionError(error));
            alertService.error(error, 'Erro ao rejeitar usuário')
        });
};

export const requestUserStatusToggle = () => ({
    type: types.REQUEST_USER_STATUS_TOGGLE,
    payload: null
});

export const requestUserStatusToggleSuccess = () => ({
    type: types.REQUEST_USER_STATUS_TOGGLE_SUCCESS,
    payload: null
})

export const requestUserStatusToggleError = error => ({
    type: types.REQUEST_USER_STATUS_TOGGLE_ERROR,
    payload: error
});

export const toggleStatus = (id, status) => dispatch => {
    dispatch(requestUserStatusToggle());

    userService.toggleStatus(id)
        .then(response => {
            if (response.data) {
                const sucessMessage = status ? 'ativado' : 'inativado'
                dispatch(requestUserStatusToggleSuccess())
                alertService.success(`Usuário ${sucessMessage} com sucesso!`)
            }
        })
        .catch(error => {
            const errorMessage = status ? 'ativar' : 'inativar'
            dispatch(requestUserStatusToggleError(error));
            alertService.error(error, `Erro ao ${errorMessage} usuário`)
        });
};

export const clear = () => ({
    type: types.CLEAR_USER_DETAILS,
    payload: null
});
