import initialState from './initialState'
import { 
    REQUEST_FREIGHTS,
    REQUEST_FREIGHTS_SUCCESS,
    REQUEST_FREIGHTS_ERROR 
} from '../Actions/actionTypes'

const requestFreights = (state, action) => {
    return { ...state, isFetching: true };
};

const requestFreightsSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestFreightsError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_FREIGHTS]: requestFreights,
    [REQUEST_FREIGHTS_SUCCESS]: requestFreightsSuccess,
    [REQUEST_FREIGHTS_ERROR]: requestFreightsError
};

export const freights = (state = initialState.freights, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
