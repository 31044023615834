import { userDataStorageConstants } from "./constants";
const keepAlive = userDataStorageConstants.KeepAlive;
const authToken = userDataStorageConstants.AuthToken;

class UserDataStorageUtils {
  setAuthInfo(auth) {
    this.updateKeepConnected(auth.keepConnected);
    if (auth.keepConnected)
      localStorage.setItem(authToken, JSON.stringify(auth.token));
    else sessionStorage.setItem(authToken, JSON.stringify(auth.token));
  }

  setUserData(data) {
    localStorage.setItem("userData", JSON.stringify(data));
  }

  setIndicationTabInfo(harvestId, productId) {
    localStorage.setItem(
      "tabIndicationInfo",
      JSON.stringify({ harvestId, productId })
    );
  }

  setActiveRoute(path) {
    localStorage.setItem("activeRoute", JSON.stringify(path));
  }

  updateKeepConnected(keepConnected) {
    if (localStorage.getItem(keepAlive)) localStorage.removeItem(keepAlive);

    localStorage.setItem(keepAlive, JSON.stringify(keepConnected));
  }

  getKeepConnected() {
    return JSON.parse(localStorage.getItem(keepAlive));
  }

  getAuth() {
    return this.getKeepConnected()
      ? JSON.parse(localStorage.getItem(authToken))
      : JSON.parse(sessionStorage.getItem(authToken));
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("userData"));
  }

  getIndicationTabInfo() {
    return JSON.parse(localStorage.getItem("tabIndicationInfo"));
  }

  getActiveRoute() {
    return JSON.parse(localStorage.getItem("activeRoute"));
  }

  removeAuth() {
    localStorage.removeItem("userData");
    sessionStorage.removeItem(authToken);
    localStorage.removeItem(authToken);
  }
}

const userDataStorageUtils = new UserDataStorageUtils();
export default userDataStorageUtils;
