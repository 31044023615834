import BaseService from './base.service'

class CBOTService extends BaseService {
  constructor() {
    super('CBOT');
  }

  findAllGroupedByProduct() {
    return this.post({}, '/FindAllGroupedByProduct')
  }
}

export default CBOTService;
