import initialState from '../initialState'
import {
    REQUEST_EDIT_CLIENT,
    REQUEST_EDIT_CLIENT_SUCCESS,
    REQUEST_EDIT_CLIENT_ERROR,
    REQUEST_EDIT_FORM_CLIENT_DETAILS,
    REQUEST_EDIT_FORM_CLIENT_DETAILS_SUCCESS,
    REQUEST_EDIT_FORM_CLIENT_DETAILS_ERROR,
    HANDLE_EDIT_CLIENT_FORM_VALUE_CHANGE,
    CLEAR_EDIT_CLIENT_FORM
} from '../../Actions/actionTypes'


const requestEditClient = (state, action) => {
    return { ...state, isSaving: true };
};

const requestEditClientSuccess = (state, action) => {
    return initialState.editClientForm;
};

const requestEditClientError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const requestClientDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestClientDetailsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestClientDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const clear = (state, action) => {
    return initialState.editClientForm
}

const actions = {
    [REQUEST_EDIT_CLIENT]: requestEditClient,
    [REQUEST_EDIT_CLIENT_SUCCESS]: requestEditClientSuccess,
    [REQUEST_EDIT_CLIENT_ERROR]: requestEditClientError,
    [REQUEST_EDIT_FORM_CLIENT_DETAILS]: requestClientDetails,
    [REQUEST_EDIT_FORM_CLIENT_DETAILS_SUCCESS]: requestClientDetailsSuccess,
    [REQUEST_EDIT_FORM_CLIENT_DETAILS_ERROR]: requestClientDetailsError,
    [HANDLE_EDIT_CLIENT_FORM_VALUE_CHANGE]: handleValueChange,
    [CLEAR_EDIT_CLIENT_FORM]: clear
};

export const editClientForm = (state = initialState.editClientForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
