import initialState from '../initialState'
import {
    REQUEST_PRICES_HISTORY,
    REQUEST_PRICES_HISTORY_SUCCESS,
    REQUEST_PRICES_HISTORY_ERROR,
    HANDLE_PRICES_HISTORY_VALUE_CHANGE,
    CLEAR_PRICES_HISTORY
} from '../../Actions/actionTypes'

const requestPricesHistory = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPricesHistorySuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload.entities,
        count: action.payload.count,
        isFetching: false
    };
};

const requestPricesHistoryError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const clear = (state, action) => {
    return initialState.pricesHistory;
};

const actions = {
    [REQUEST_PRICES_HISTORY]: requestPricesHistory,
    [REQUEST_PRICES_HISTORY_SUCCESS]: requestPricesHistorySuccess,
    [REQUEST_PRICES_HISTORY_ERROR]: requestPricesHistoryError,
    [HANDLE_PRICES_HISTORY_VALUE_CHANGE]: handleValueChange,
    [CLEAR_PRICES_HISTORY]: clear
};

export const pricesHistory = (state = initialState.pricesHistory, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
