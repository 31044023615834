import React from 'react'
import TabItem from './tabItem'

export default function Tab({ harvestGroup, currentTab, onTabClick }) {
    return (
        <>
            <div className='d-flex tab-container'>
                {harvestGroup.map(product =>
                    product.harvests.map(harvest =>
                        <TabItem
                            key={harvest.id}
                            id={harvest.id}
                            productId={harvest.productId}
                            product={harvest.productName}
                            year={harvest.year}
                            isActive={currentTab === harvest.id}
                            size={'regular'}
                            onTabClick={onTabClick} />
                    )
                )}
            </div>
            <hr className='inova-tab-border mb-4' />
        </>
    )
}
