import BaseService from './base.service'
import { localStorageUtils } from '../../Utils'

class AuthService extends BaseService {
  constructor() {
    super('Login/');
  }

  isAuthenticated() {
    return !!localStorageUtils.getAuthToken();
  }
}

export default AuthService;
