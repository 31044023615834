import initialState from './initialState'
import {
    REQUEST_CBOTS,
    REQUEST_CBOTS_SUCCESS,
    REQUEST_CBOTS_ERROR,
    REQUEST_DELETE_CBOT,
    REQUEST_DELETE_CBOT_SUCCESS,
    REQUEST_DELETE_CBOT_ERROR
} from '../Actions/actionTypes'

const requestCBOTs = (state, action) => {
    return { ...state, isFetching: true };
};

const requestCBOTsSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        isFetching: false
    };
};

const requestCBOTsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestDeleteCBOT = (state, action) => {
    return { ...state, isSaving: true };
};

const requestDeleteCBOTSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestDeleteCBOTError = (state, action) => {
    return { ...state, isSaving: false };
};

const actions = {
    [REQUEST_CBOTS]: requestCBOTs,
    [REQUEST_CBOTS_SUCCESS]: requestCBOTsSuccess,
    [REQUEST_CBOTS_ERROR]: requestCBOTsError,
    [REQUEST_DELETE_CBOT]: requestDeleteCBOT,
    [REQUEST_DELETE_CBOT_SUCCESS]: requestDeleteCBOTSuccess,
    [REQUEST_DELETE_CBOT_ERROR]: requestDeleteCBOTError,
};

export const cbots = (state = initialState.cbots, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
