import * as types from '../actionTypes'
import { OfferService, CounterOfferService, alertService } from '../../Services'
import { signalRActions } from '../../Actions'
import { offerStates, dateUtils } from '../../Utils'

const offerService = new OfferService();
const counterOfferService = new CounterOfferService();

export const requestOffers = setLoading => ({
    type: types.REQUEST_OFFERS,
    payload: setLoading
});

export const requestOffersSuccess = (offers, offerCount) => ({
    type: types.REQUEST_OFFERS_SUCCESS,
    payload: { offers, offerCount }
});

export const requestOffersError = error => ({
    type: types.REQUEST_OFFERS_ERROR,
    payload: error
});

export const fetchOffers = (options, setLoading = true, offerCount = null) => dispatch => {
    dispatch(requestOffers(setLoading));
    offerService.findAllToday(options)
        .then(response => {
            if (response.data) {
                const offers = response.data.map(offer => {
                    const price = offer.price ? {
                        ...offer.price,
                        updatedValue: offer.price.value,
                        updatedExchangeFee: offer.price.exchangeFee,
                        updatedCotation: offer.price.cotation
                    } : null
                    return { ...offer, price }
                })
                dispatch(signalRActions.handleValueChange({ offersCotationSubscribed: false, offersExchangeSubscribed: false }));
                dispatch(requestOffersSuccess(offers, offerCount));
            }
        })
        .catch(error => {
            dispatch(requestOffersError(error));
            alertService.error(error, 'Erro ao buscar ofertas');
        });
};

export const requestApproveOffer = () => ({
    type: types.REQUEST_APPROVE_OFFER,
    payload: null
});

export const requestApproveOfferSuccess = () => ({
    type: types.REQUEST_APPROVE_OFFER_SUCCESS,
    payload: null
});

export const requestApproveOfferError = error => ({
    type: types.REQUEST_APPROVE_OFFER_ERROR,
    payload: error
});

export const approveOffer = (offerId, buyerId) => dispatch => {
    dispatch(requestApproveOffer());
    offerService.approve(offerId, buyerId)
        .then(result => {
            dispatch(requestApproveOfferSuccess());
            dispatch(fetchOffers({ states: [offerStates.Approved.id] }));
            dispatch(markAsRead(result.data.id))
            dispatch(handleValueChange({ selectedOffer: { ...result.data }, currentTab: offerStates.Approved }))
            alertService.success('Oferta aprovada com sucesso!');
        })
        .catch(error => {
            dispatch(requestApproveOfferError(error));
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao aprovar oferta');
        });
};

export const requestRejectOffer = () => ({
    type: types.REQUEST_REJECT_OFFER,
    payload: null
});

export const requestRejectOfferSuccess = () => ({
    type: types.REQUEST_REJECT_OFFER_SUCCESS,
    payload: null
});

export const requestRejectOfferError = error => ({
    type: types.REQUEST_REJECT_OFFER_ERROR,
    payload: error
});

export const rejectOffer = (offerId, options) => dispatch => {
    dispatch(requestRejectOffer());
    offerService.reject(offerId)
        .then(() => {
            dispatch(requestRejectOfferSuccess());
            dispatch(fetchOffers(options));
            alertService.success('Oferta rejeitada com sucesso!');
        })
        .catch(error => {
            dispatch(requestRejectOfferError(error));
            alertService.error(error, 'Erro ao rejeitar oferta');
        });
};

export const requestNegotiateOffer = () => ({
    type: types.REQUEST_NEGOTIATE_OFFER,
    payload: null
});

export const requestNegotiateOfferSuccess = () => ({
    type: types.REQUEST_NEGOTIATE_OFFER_SUCCESS,
    payload: null
});

export const requestNegotiateOfferError = error => ({
    type: types.REQUEST_NEGOTIATE_OFFER_ERROR,
    payload: error
});

export const negotiateOffer = offerId => dispatch => {
    dispatch(requestNegotiateOffer());
    offerService.negotiate(offerId)
        .then(result => {
            dispatch(requestNegotiateOfferSuccess());
            dispatch(fetchOffers({ states: [offerStates.Negotiation.id] }));
            dispatch(markAsRead(result.data.id))
            dispatch(handleValueChange({ selectedOffer: { ...result.data }, currentTab: offerStates.Negotiation }))
            alertService.success('Negociação iniciada com sucesso!');
        })
        .catch(error => {
            dispatch(requestNegotiateOfferError(error));
            alertService.error(error, 'Erro ao iniciar negociação');
        });
};

export const requestCreateCounterOffer = () => ({
    type: types.REQUEST_CREATE_COUNTER_OFFER,
    payload: null
});

export const requestCreateCounterOfferSuccess = counterOffer => ({
    type: types.REQUEST_CREATE_COUNTER_OFFER_SUCCESS,
    payload: counterOffer
});

export const requestCreateCounterOfferError = error => ({
    type: types.REQUEST_CREATE_COUNTER_OFFER_ERROR,
    payload: error
});

export const createCounterOffer = (counterOffer, minimumLot, hideModal) => dispatch => {
    dispatch(requestCreateCounterOffer());

    const newCounterOffer = {
        ...counterOffer,
        value: counterOffer.value / 100,
        paymentOn: dateUtils.format(counterOffer.paymentOn, 'YYYY-MM-DD'),
        deliveryStartsOn: counterOffer.immediateDelivery ? '' : dateUtils.format(counterOffer.deliveryStartsOn, 'YYYY-MM-DD'),
        deliveryEndsOn: counterOffer.immediateDelivery ? '' : dateUtils.format(counterOffer.deliveryEndsOn, 'YYYY-MM-DD')
    }

    counterOfferService.saveOrUpdate(newCounterOffer)
        .then(result => {
            hideModal();
            dispatch(requestCreateCounterOfferSuccess(result.data));
            alertService.success('Contra oferta criada com sucesso!');
        })
        .catch(error => {
            dispatch(requestCreateCounterOfferError(error));
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar contra oferta');
        });
};

export const requestMarkOfferAsRead = () => ({
    type: types.REQUEST_MARK_OFFER_AS_READ,
    payload: null
});

export const requestMarkOfferAsReadSuccess = offerId => ({
    type: types.REQUEST_MARK_OFFER_AS_READ_SUCCESS,
    payload: offerId
});

export const requestMarkOfferAsReadError = error => ({
    type: types.REQUEST_MARK_OFFER_AS_READ_ERROR,
    payload: error
});

export const markAsRead = offerId => dispatch => {
    dispatch(requestMarkOfferAsRead());
    offerService.markAsRead(offerId)
        .then(() => {
            dispatch(requestMarkOfferAsReadSuccess(offerId));
        })
        .catch(error => {
            dispatch(requestMarkOfferAsReadError(error));
        });
};

export const updateOffer = id => ({
    type: types.UPDATE_OFFER,
    payload: id
});

export const cotationUpdate = (cbotId, cotation) => ({
    type: types.OFFERS_COTATION_UPDATE,
    payload: { cbotId, cotation }
})

export const exchangeUpdate = (date, exchange) => ({
    type: types.OFFERS_EXCHANGE_UPDATE,
    payload: { date, exchange }
})

export const handleValueChange = edition => ({
    type: types.HANDLE_OFFERS_VALUE_CHANGE,
    payload: edition
});
