import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-BR')

class DateUtils {
  newDate(date, dateFormat = '') {
    return date ? moment(date, dateFormat) : moment()
  }

  format(date, format = 'DD/MM/YYYY', dateFormat = '') {
    return moment(date, dateFormat).format(format)
  }

  formatUTC(date, format = 'DD/MM/YYYY', dateFormat = '') {
    return moment.utc(date, dateFormat).local().format(format)
  }

  formatToUTC(date, format = 'DD/MM/YYYY', dateFormat = '') {
    return moment(date, dateFormat).utc().format(format)
  }

  isValid(date, format = 'DD/MM/YYYY') {
    return moment(date, format).isValid()
  }

  isSameOrAfter(start, end, format = 'YYYY-MM-DD') {
    return moment(end, format).isSameOrAfter(moment(start, format))
  }

  isAfter(start, end, format = 'YYYY-MM-DD') {
    return moment(end, format).isAfter(moment(start, format))
  }

  timeUntilEnd(end) {
    return moment(moment(end).diff(moment(moment.utc().toISOString()))).format("HH:mm:ss")
  }

  secondsUntilEnd(end) {
    return moment(moment(end).diff(moment(moment.utc().toISOString()))).seconds()
  }

  isAfterNow(date) {
    return moment.utc(date).isSameOrAfter(moment.utc())
  }

  yearOptions(range = 10) {
    let date = moment()
    const lastYear = moment().subtract('year', 1)
    const years = [{ value: lastYear.format('YYYY'), label: lastYear.format('YYYY') }]
    let year = 0

    while (year <= range) {
      years.push({ value: date.format('YYYY'), label: date.format('YYYY') })
      date = date.clone().add(1, 'y')
      year += 1
    }
    return years
  }

  isGreater(a, b, format) {
    let varA;
    let varB;

    if (!format) {
      varA = moment(a);
      varB = moment(b);
      return varA.isAfter(varB);
    } else if (format === 'DD/MM/YYY') {
      varA = moment(a, 'DD/MM/YYYY');
      varB = moment(b, 'DD/MM/YYYY');
      return varA.isAfter(varB);
    } else {
      varA = moment.duration(a).asMilliseconds();
      varB = moment.duration(b).asMilliseconds();
      return varA > varB;
    }
  }
}

const dateUtils = new DateUtils();
export default dateUtils;
