import "../../Styles/index.scss";

const sellersModules = [
  {
    name: "Clientes",
    url: "/clientes",
  },
  {
    name: "Usuários",
    url: "/usuarios",
  },
  {
    name: "Terminais Portuários",
    url: "/terminais",
  },
];

const pricesModules = [
  {
    name: "Gerar preços",
    url: "/precos",
    icon: "ic-coin",
  },
  {
    name: "Indicações de preços",
    url: "/indicacoes",
    icon: "ic-indications",
  },
  {
    name: "Histórico",
    url: "/historico-preco",
    icon: "ic-historico",
  },
];

const offersModules = [
  {
    name: "Ofertas do dia",
    url: "/ofertas",
    icon: "ic-calendar",
  },
  {
    name: "Histórico de negociações",
    url: "/historico-negociacoes",
    icon: "ic-ofertas-consolidadas",
  },
  {
    name: "Criar Oferta Para Cliente",
    url: "/criar-oferta-cliente",
    icon: "ic-coin",
  },
];

const configModules = [
  {
    name: "Safras",
    url: "/safras",
    icon: "ic-leaf",
  },
  {
    name: "Cotação da bolsa",
    url: "/cbot",
    icon: "ic-cbot",
  },
  {
    name: "Horários do mercado",
    url: "/horarios",
    icon: "ic-ofertas-ativas",
  },
];

const allModules = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "ic-dashboard",
  },
  {
    name: "Cadastros",
    icon: "ic-user",
    children: sellersModules,
  },
  { title: true, name: "Preços" },
  ...pricesModules,
  { title: true, name: "Ofertas" },
  ...offersModules,
  { title: true, name: "Configurações" },
  ...configModules,
];

export default {
  items: allModules,
};
