import initialState from '../initialState'
import {
    REQUEST_CLIENTS,
    REQUEST_CLIENTS_SUCCESS,
    REQUEST_CLIENTS_ERROR,
    HANDLE_CLIENTS_VALUE_CHANGE,
    UPDATE_CLIENTS_LIST
} from '../../Actions/actionTypes'

const requestClients = (state, action) => {
    return { ...state, isFetching: true };
};

const requestClientsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestClientsError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload };
};

const updateClientsList = (state, action) => {
    return {
        ...state,
        entities: [action.payload, ...state.entities]
    }
}

const actions = {
    [REQUEST_CLIENTS]: requestClients,
    [REQUEST_CLIENTS_SUCCESS]: requestClientsSuccess,
    [REQUEST_CLIENTS_ERROR]: requestClientsError,
    [HANDLE_CLIENTS_VALUE_CHANGE]: handleValueChange,
    [UPDATE_CLIENTS_LIST]: updateClientsList
};

export const clients = (state = initialState.clients, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
