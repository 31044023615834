import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CheckboxInput extends Component {
  onCheckboxToggle(e) {
    const event = {
      target: {
        name: this.props.name,
        value: !this.props.value
      }
    }

    this.props.onToggle(event);
  }

  render() {
    return (
      <div className='checkbox-input'>
        <label className='container' style={this.props.inputStyle}>
          <input
            type='checkbox'
            disabled={this.props.disabled}
            checked={this.props.value}
            required={this.props.required}
            name={this.props.name}
            value={this.props.value || ''}
            onChange={e => {
              this.onCheckboxToggle(e);
            }}
          />
          <span className='checkbox-label' style={{ fontSize: this.props.labelFontSize }}>
            {this.props.label}
          </span>
          <span className='checkmark' style={this.props.inputStyle} />
        </label>
        <label className='tooltip'>{this.props.tooltipText}</label>
      </div>
    );
  }
}

CheckboxInput.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.bool
};

export default CheckboxInput;
