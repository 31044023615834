import * as types from './actionTypes'

export const requestPriceConnection = () => ({
    type: types.REQUEST_SIGNALR_PRICE_CONNECTION,
    payload: null
});

export const requestPriceConnectionSuccess = () => ({
    type: types.REQUEST_SIGNALR_PRICE_CONNECTION_SUCCESS,
    payload: null
});

export const requestPriceConnectionError = error => ({
    type: types.REQUEST_SIGNALR_PRICE_CONNECTION_ERROR,
    payload: error
});

export const subscribeCotations = (entities, controlVariable) => ({
    type: types.SUBSCRIBE_COTATIONS,
    payload: { entities, controlVariable }
})

export const subscribeCotationsSuccess = controlVariable => ({
    type: types.SUBSCRIBE_COTATIONS_SUCCESS,
    payload: controlVariable
})

export const subscribeCotationsError = controlVariable => ({
    type: types.SUBSCRIBE_COTATIONS_ERROR,
    payload: controlVariable
})

export const unsubscribeAll = () => ({
    type: types.UNSUBSCRIBE_ALL,
    payload: null
})

export const unsubscribeAllSuccess = () => ({
    type: types.UNSUBSCRIBE_ALL_SUCCESS,
    payload: null
})

export const unsubscribeAllError = () => ({
    type: types.UNSUBSCRIBE_ALL_ERROR,
    payload: null
})

export const subscribeExchanges = (entities, controlVariable) => ({
    type: types.SUBSCRIBE_EXCHANGES,
    payload: { entities, controlVariable }
})

export const subscribeExchangesSuccess = controlVariable => ({
    type: types.SUBSCRIBE_EXCHANGES_SUCCESS,
    payload: controlVariable
})

export const subscribeExchangesError = controlVariable => ({
    type: types.SUBSCRIBE_EXCHANGES_ERROR,
    payload: controlVariable
})

export const closePriceConnection = () => ({
    type: types.CLOSE_SIGNALR_PRICE_CONNECTION,
    payload: null
});

export const closePriceConnectionSuccess = () => ({
    type: types.CLOSE_SIGNALR_PRICE_CONNECTION_SUCCESS,
    payload: null
});

export const closePriceConnectionError = () => ({
    type: types.CLOSE_SIGNALR_PRICE_CONNECTION_ERROR,
    payload: null
});

export const instrumentationUpdate = instrumentation => ({
    type: types.SIGNALR_INSTRUMENTATION_UPDATE,
    payload: instrumentation
});

export const setExchangesInfo = exchangesInfo => ({
    type: types.SET_EXCHANGES_INFO,
    payload: exchangesInfo
 })

// --------------------- Notification

export const requestNotificationConnection = () => ({
    type: types.REQUEST_SIGNALR_NOTIFICATION_CONNECTION,
    payload: null
});

export const requestNotificationConnectionSuccess = () => ({
    type: types.REQUEST_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS,
    payload: null
});

export const requestNotificationConnectionError = error => ({
    type: types.REQUEST_SIGNALR_NOTIFICATION_CONNECTION_ERROR,
    payload: error
});

export const closeNotificationConnection = () => ({
    type: types.CLOSE_SIGNALR_NOTIFICATION_CONNECTION,
    payload: null
});

export const closeNotificationConnectionSuccess = () => ({
    type: types.CLOSE_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS,
    payload: null
});

export const closeNotificationConnectionError = () => ({
    type: types.CLOSE_SIGNALR_NOTIFICATION_CONNECTION_ERROR,
    payload: null
});

export const handleValueChange = edition => ({
    type: types.HANDLE_SIGNALR_VALUE_CHANGE,
    payload: edition
});
