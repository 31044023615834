import * as types from '../actionTypes'
import { UserCatalogService, alertService } from '../../Services'

const userCatalogService = new UserCatalogService();

export const requestUsers = () => ({
    type: types.REQUEST_USERS,
    payload: null
});

export const requestUsersSuccess = users => ({
    type: types.REQUEST_USERS_SUCCESS,
    payload: users
})

export const requestUsersError = error => ({
    type: types.REQUEST_USERS_ERROR,
    payload: error
});

export const fetchUsers = options => dispatch => {
    dispatch(requestUsers());
    userCatalogService.findAll(options)
        .then(response => {
            if (response.data) {
                dispatch(requestUsersSuccess({ count: response.data.totalResults, entities: response.data.results }))
            }
        })
        .catch(error => {
            dispatch(requestUsersError(error));
            alertService.error(error, 'Erro ao buscar usuários');
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_USERS_VALUE_CHANGE,
    payload: edition
});

export const updateUsersList = user => ({
    type: types.UPDATE_USERS_LIST,
    payload: user
})

export const clear = () => ({
    type: types.CLEAR_USERS,
    payload: null
})