import initialState from '../initialState'
import {
    REQUEST_PRICES,
    REQUEST_PRICES_SUCCESS,
    REQUEST_PRICES_ERROR,
    REQUEST_DELETE_PRICES,
    REQUEST_DELETE_PRICES_SUCCESS,
    REQUEST_DELETE_PRICES_ERROR,
    REQUEST_PUBLISH_PRICES,
    REQUEST_PUBLISH_PRICES_SUCCESS,
    REQUEST_PUBLISH_PRICES_ERROR,
    HANDLE_PRICES_VALUE_CHANGE,
    UPDATE_PRICES_LIST,
    HANDLE_PRICES_CHECKBOX_TOGGLE,
    PRICES_COTATION_UPDATE,
    PRICES_EXCHANGE_UPDATE
} from '../../Actions/actionTypes'
import { calculateUpdatedValue, dateUtils } from '../../Utils'

const requestPrices = (state, action) => {
    return { ...state, entities: [], isFetching: true };
};

const requestPricesSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        isFetching: false
    };
};

const requestPricesError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const updatePricesList = (state, action) => {
    return {
        ...state,
        entities: [action.payload, ...state.entities]
    }
}

const handleCheckboxToggle = (state, action) => {
    const id = action.payload.id
    const checked = action.payload.checked
    let entities

    if (id) {
        if (checked) {
            entities = state.entities.map(price => price.id === id ? { ...price, checked: true } : price)
            return { ...state, entities }
        } else {
            entities = state.entities.map(price => price.id === id ? { ...price, checked: false } : price)
            return { ...state, entities }
        }
    } else if (checked) {
        entities = state.entities.map(price => ({ ...price, checked: true }))
        return { ...state, entities, allChecked: true }
    } else {
        entities = state.entities.map(price => ({ ...price, checked: false }))
        return { ...state, entities, allChecked: false }
    }
}

const requestDeletePrices = (state, action) => {
    return { ...state, isSaving: true };
};

const requestDeletePricesSuccess = (state, action) => {
    return { ...state, allchecked: false, isSaving: false };
};

const requestDeletePricesError = (state, action) => {
    return { ...state, isSaving: false };
};

const requestPublishPrices = (state, action) => {
    return { ...state, isSaving: true };
};

const requestPublishPricesSuccess = (state, action) => {
    return { ...state, allchecked: false, isSaving: false };
};

const requestPublishPricesError = (state, action) => {
    return { ...state, isSaving: false };
};

const cotationUpdate = (state, action) => {
    const updatedCotation = action.payload.cotation
    const entities = state.entities.map(price => {
        if (price.cbotId === action.payload.cbotId) {
            return {
                ...price,
                updatedCotation,
                updatedValue: calculateUpdatedValue(price, updatedCotation, price.updatedExchangeFee)
            }
        }
        return price
    })
    return { ...state, entities };
};

const exchangeUpdate = (state, action) => {
    const updatedExchangeFee = action.payload.exchange
    const entities = state.entities.map(price => {
        if (dateUtils.format(price.paymentOn) === action.payload.date) {
            return {
                ...price,
                updatedExchangeFee,
                updatedValue: calculateUpdatedValue(price, price.updatedCotation, updatedExchangeFee)
            }
        }
        return price
    })
    return { ...state, entities }
}

const actions = {
    [REQUEST_PRICES]: requestPrices,
    [REQUEST_PRICES_SUCCESS]: requestPricesSuccess,
    [REQUEST_PRICES_ERROR]: requestPricesError,
    [REQUEST_DELETE_PRICES]: requestDeletePrices,
    [REQUEST_DELETE_PRICES_SUCCESS]: requestDeletePricesSuccess,
    [REQUEST_DELETE_PRICES_ERROR]: requestDeletePricesError,
    [REQUEST_PUBLISH_PRICES]: requestPublishPrices,
    [REQUEST_PUBLISH_PRICES_SUCCESS]: requestPublishPricesSuccess,
    [REQUEST_PUBLISH_PRICES_ERROR]: requestPublishPricesError,
    [HANDLE_PRICES_VALUE_CHANGE]: handleValueChange,
    [UPDATE_PRICES_LIST]: updatePricesList,
    [HANDLE_PRICES_CHECKBOX_TOGGLE]: handleCheckboxToggle,
    [PRICES_COTATION_UPDATE]: cotationUpdate,
    [PRICES_EXCHANGE_UPDATE]: exchangeUpdate
};

export const prices = (state = initialState.prices, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
