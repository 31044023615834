import initialState from './initialState'
import {
    REQUEST_MARKET_HOURS,
    REQUEST_MARKET_HOURS_SUCCESS,
    REQUEST_MARKET_HOURS_ERROR,
    REQUEST_HOURS_CHANGE,
    REQUEST_UPDATE_MARKET_HOURS,
    REQUEST_UPDATE_MARKET_HOURS_SUCCESS,
    REQUEST_UPDATE_MARKET_HOURS_ERROR
} from '../Actions/actionTypes'

const requestMarketHours = (state, action) => {
    return { ...state, isFetching: true };
};

const requestMarketHoursSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        isFetching: false
    };
};

const requestMarketHoursError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestUpdateMarketHours = (state, action) => {
    return { ...state, isSaving: true };
};

const requestUpdateMarketHoursSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload,
        hasEdited: false,
        isSaving: false
    };
};

const requestUpdateMarketHoursError = (state, action) => {
    return { ...state, isSaving: false };
};

const handleHoursChange = (state, action) => {
    const entities = state.entities.map(market => {
        if (market.id === action.payload.id) {
            return {
                ...market,
                ...action.payload.edition,
            }
        }
        return market
    })
    return { ...state, entities, hasEdited: true };
};

const actions = {
    [REQUEST_MARKET_HOURS]: requestMarketHours,
    [REQUEST_MARKET_HOURS_SUCCESS]: requestMarketHoursSuccess,
    [REQUEST_MARKET_HOURS_ERROR]: requestMarketHoursError,
    [REQUEST_HOURS_CHANGE]: handleHoursChange,
    [REQUEST_UPDATE_MARKET_HOURS]: requestUpdateMarketHours,
    [REQUEST_UPDATE_MARKET_HOURS_SUCCESS]: requestUpdateMarketHoursSuccess,
    [REQUEST_UPDATE_MARKET_HOURS_ERROR]: requestUpdateMarketHoursError
};

export const marketHours = (state = initialState.marketHours, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
