import initialState from '../initialState';
import {
    REQUEST_EDIT_USER,
    REQUEST_EDIT_USER_SUCCESS,
    REQUEST_EDIT_USER_ERROR,
    REQUEST_EDIT_FORM_USER_DETAILS,
    REQUEST_EDIT_FORM_USER_DETAILS_SUCCESS,
    REQUEST_EDIT_FORM_USER_DETAILS_ERROR,
    HANDLE_EDIT_USER_FORM_VALUE_CHANGE,
    CLEAR_EDIT_USER_FORM
} from '../../Actions/actionTypes';


const requestEditUser = (state, action) => {
    return { ...state, isSaving: true };
};

const requestEditUserSuccess = (state, action) => {
    return initialState.editUserForm;
};

const requestEditUserError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const requestUserDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestUserDetailsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestUserDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const clear = (state, action) => {
    return initialState.editUserForm
}

const actions = {
    [REQUEST_EDIT_USER]: requestEditUser,
    [REQUEST_EDIT_USER_SUCCESS]: requestEditUserSuccess,
    [REQUEST_EDIT_USER_ERROR]: requestEditUserError,
    [REQUEST_EDIT_FORM_USER_DETAILS]: requestUserDetails,
    [REQUEST_EDIT_FORM_USER_DETAILS_SUCCESS]: requestUserDetailsSuccess,
    [REQUEST_EDIT_FORM_USER_DETAILS_ERROR]: requestUserDetailsError,
    [HANDLE_EDIT_USER_FORM_VALUE_CHANGE]: handleValueChange,
    [CLEAR_EDIT_USER_FORM]: clear
};

export const editUserForm = (state = initialState.editUserForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
