import * as types from '../actionTypes'
import { alertService, UserService } from '../../Services'
import { cpfCnpjUtils, redirectUtils, dateUtils } from '../../Utils'

const userService = new UserService()

export const requestEditUser = () => ({
    type: types.REQUEST_EDIT_USER,
    payload: null
});

export const requestEditUserSuccess = user => ({
    type: types.REQUEST_EDIT_USER_SUCCESS,
    payload: user
});

export const requestEditUserError = error => ({
    type: types.REQUEST_EDIT_USER_ERROR,
    payload: error
});

export const handleEdit = userForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(userForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestEditUser());

    const userEdition = {
        ...userForm,
        bornOn: dateUtils.format(userForm.bornOn, 'YYYY-MM-DD', 'DD/MM/YYYY')
    }

    userService.saveOrUpdate(userEdition)
        .then(response => {
            dispatch(requestEditUserSuccess())
            redirectUtils.redirectTo(`#/usuarios/detalhe/r/${response.data.id}`)
            alertService.success('Usuário editado com sucesso!')
        })
        .catch(error => {
            dispatch(requestEditUserError({ data: userForm, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar usuário')
        })
}

const checkRequiredFields = uf => {
    let alerts = []
    let isInvalid = false
    
    if (uf.name === '') {
        isInvalid = true
    }

    if (!cpfCnpjUtils.isValid(uf.cpf)) {
        alerts.push('Campo CPF inválido!')
        isInvalid = true
    }
    if (uf.email.length === 0) {
        isInvalid = true
    }
    if (uf.phoneNumber.length === 0) {
        isInvalid = true
    }
    if (uf.clientId === '') {
        isInvalid = true
    }
    if (!dateUtils.isValid(uf.bornOn)) {
        alerts.push('Campo data de nascimento inválido!')
        isInvalid = true
    }

    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const requestUserDetails = () => ({
    type: types.REQUEST_EDIT_FORM_USER_DETAILS,
    payload: null
});

export const requestUserDetailsSuccess = details => ({
    type: types.REQUEST_EDIT_FORM_USER_DETAILS_SUCCESS,
    payload: details
})

export const requestUserDetailsError = error => ({
    type: types.REQUEST_EDIT_FORM_USER_DETAILS_ERROR,
    payload: error
});

export const fetchUser = id => dispatch => {
    dispatch(requestUserDetails());

    userService.getById(id)
        .then(response => {
            if (response.data)
                dispatch(requestUserDetailsSuccess({...response.data, bornOn: dateUtils.format(response.data.bornOn)}));
        })
        .catch(error => {
            dispatch(requestUserDetailsError(error));
            redirectUtils.redirectTo(`#/usuarios/detalhe/r/${id}`)
            alertService.error(error, 'Erro ao buscar detalhes do usuário');
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_EDIT_USER_FORM_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_EDIT_USER_FORM,
    payload: null
});
