import { PortTerminalService, alertService } from '../../Services'
import * as types from '../actionTypes'

const portTerminalService = new PortTerminalService();

export const requestPortTerminals = () => ({
    type: types.REQUEST_PORT_TERMINALS,
    payload: null
});

export const requestPortTerminalsSuccess = portTerminals => ({
    type: types.REQUEST_PORT_TERMINALS_SUCCESS,
    payload: portTerminals
});

export const requestPortTerminalsError = error => ({
    type: types.REQUEST_PORT_TERMINALS_ERROR,
    payload: error
});

export const fetchPortTerminals = options => dispatch => {
    dispatch(requestPortTerminals());
    portTerminalService.findAll(options)
        .then(response => {
            if (response.data) {
                dispatch(requestPortTerminalsSuccess({ count: response.data.totalResults, entities: response.data.results }));
            }
        })
        .catch(error => {
            dispatch(requestPortTerminalsError(error));
            alertService.error(error, 'Erro ao buscar terminais portuários');
        });
};

export const requestPortTerminalsOptions = () => ({
    type: types.REQUEST_PORT_TERMINALS_OPTIONS,
    payload: null
});

export const requestPortTerminalsOptionsSuccess = portTerminals => ({
    type: types.REQUEST_PORT_TERMINALS_OPTIONS_SUCCESS,
    payload: portTerminals
});

export const requestPortTerminalsOptionsError = error => ({
    type: types.REQUEST_PORT_TERMINALS_OPTIONS_ERROR,
    payload: error
});

export const fetchPortTerminalsOptions = () => dispatch => {
    dispatch(requestPortTerminalsOptions());
    portTerminalService.findAll()
        .then(response => {
            if (response.data) {
                const portTerminalsOptions = response.data.results.map(portTerminal => {
                    return {
                        value: portTerminal.id,
                        label: portTerminal.tradeName || portTerminal.legalName,
                        active: portTerminal.active,
                    };
                });

                dispatch(requestPortTerminalsOptionsSuccess(portTerminalsOptions));
            }
        })
        .catch(error => {
            dispatch(requestPortTerminalsOptionsError(error));
            alertService.error(error, 'Erro ao buscar terminais portuários');
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_PORT_TERMINALS_VALUE_CHANGE,
    payload: edition
});