import React from 'react'
import { SVGIcon } from '../../Assets'
import { productColor } from '../../Utils'

export default function TabItem({ id, productId, product, year, isActive, size, onTabClick}) {
    let name
    let productName = product.split(' ')
    if (productName.length)
        productName = productName[0]

    switch (productName) {
        case 'Soja':
            name = 'soy'
            break;
        case 'Trigo':
            name = 'wheat'
            break;
        case 'Milho':
            name = 'corn'
            break;
        default:
            name = 'soy'
            break;
    }

    const color = productColor[productName]


    return (
        <div className={`inova-tab ${size}-tab-item ${isActive && `${name}-selected`}`} onClick={() => onTabClick(id, productId)}>
            <SVGIcon name={name} fill={isActive ? color : '#9D9D9D'} className='mr-2' />
            <span className='text-uppercase'>{size ==='small' ? year : `${product} ${year}`}</span>
        </div>
    )
}
