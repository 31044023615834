import * as types from '../actionTypes'
import { ClientService, alertService } from '../../Services'

const clientService = new ClientService();

export const requestClientDetails = () => ({
    type: types.REQUEST_CLIENT_DETAILS,
    payload: null
});

export const requestClientDetailsSuccess = details => ({
    type: types.REQUEST_CLIENT_DETAILS_SUCCESS,
    payload: details
})

export const requestClientDetailsError = error => ({
    type: types.REQUEST_CLIENT_DETAILS_ERROR,
    payload: error
});

export const fetchClient = id => dispatch => {
    dispatch(requestClientDetails());
    clientService.getById(id)
        .then(response => {
            if (response.data) {
                dispatch(requestClientDetailsSuccess(response.data));
            }
        })
        .catch(error => {
            dispatch(requestClientDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do cliente');
        });
};

export const requestClientStatusToggle = () => ({
    type: types.REQUEST_CLIENT_STATUS_TOGGLE,
    payload: null
});

export const requestClientStatusToggleSuccess = () => ({
    type: types.REQUEST_CLIENT_STATUS_TOGGLE_SUCCESS,
    payload: null
})

export const requestClientStatusToggleError = error => ({
    type: types.REQUEST_CLIENT_STATUS_TOGGLE_ERROR,
    payload: error
});

export const toggleStatus = (id, isActive) => dispatch => {
    dispatch(requestClientStatusToggle());

    clientService.toggleStatus(id)
        .then(response => {
            if (response.data) {
                const sucessMessage = isActive ? 'ativado' : 'inativado'
                dispatch(requestClientStatusToggleSuccess())
                alertService.success(`Cliente ${sucessMessage} com sucesso!`)
            }
        })
        .catch(error => {
            const errorMessage = isActive ? 'ativar' : 'inativar'
            dispatch(requestClientStatusToggleError(error));
            alertService.error(error, `Erro ao ${errorMessage} cliente`)
        });
};

export const clear = () => ({
    type: types.CLEAR_CLIENT_DETAILS,
    payload: null
});
