import { FreightService, alertService } from '../Services'
import * as types from './actionTypes'

const freightService = new FreightService();

export const requestFreights = () => ({
    type: types.REQUEST_FREIGHTS,
    payload: null
});

export const requestFreightsSuccess = freights => ({
    type: types.REQUEST_FREIGHTS_SUCCESS,
    payload: freights
});

export const requestFreightsError = error => ({
    type: types.REQUEST_FREIGHTS_ERROR,
    payload: error
});

export const fetchFreightOptions = options => dispatch => {
    dispatch(requestFreights());
    freightService.findAll(options)
        .then(response => {
            if (response.data) {
                const freightOptions = response.data.map(freight => {
                    return {
                        value: freight.originCityId,
                        label: freight.originCity
                    };
                });
                dispatch(requestFreightsSuccess(freightOptions));
            }
        })
        .catch(error => {
            dispatch(requestFreightsError(error));
            alertService.error(error, 'Erro ao buscar localidades');
        });
};
