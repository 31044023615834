import initialState from '../initialState'
import {
    REQUEST_OFFERS,
    REQUEST_OFFERS_SUCCESS,
    REQUEST_OFFERS_ERROR,
    REQUEST_APPROVE_OFFER,
    REQUEST_APPROVE_OFFER_SUCCESS,
    REQUEST_APPROVE_OFFER_ERROR,
    REQUEST_REJECT_OFFER,
    REQUEST_REJECT_OFFER_SUCCESS,
    REQUEST_REJECT_OFFER_ERROR,
    REQUEST_NEGOTIATE_OFFER,
    REQUEST_NEGOTIATE_OFFER_SUCCESS,
    REQUEST_NEGOTIATE_OFFER_ERROR,
    REQUEST_CREATE_COUNTER_OFFER,
    REQUEST_CREATE_COUNTER_OFFER_SUCCESS,
    REQUEST_CREATE_COUNTER_OFFER_ERROR,
    REQUEST_MARK_OFFER_AS_READ,
    REQUEST_MARK_OFFER_AS_READ_SUCCESS,
    REQUEST_MARK_OFFER_AS_READ_ERROR,
    UPDATE_OFFER,
    OFFERS_COTATION_UPDATE,
    OFFERS_EXCHANGE_UPDATE,
    HANDLE_OFFERS_VALUE_CHANGE
} from '../../Actions/actionTypes'
import { calculateUpdatedValue, dateUtils } from '../../Utils'

const requestOffers = (state, action) => {
    return { ...state, isFetching: action.payload };
};

const requestOffersSuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload.offers,
        offerCount: action.payload.offerCount || state.offerCount,
        isFetching: false
    };
};

const requestOffersError = (state, action) => {
    return { ...state, isFetching: false };
};

// ---------- APPROVE ----------
const requestApproveOffer = (state, action) => {
    return { ...state, isSaving: true };
};

const requestApproveOfferSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestApproveOfferError = (state, action) => {
    return { ...state, isSaving: false };
};

// ---------- REJECT ----------
const requestRejectOffer = (state, action) => {
    return { ...state, isSaving: true };
};

const requestRejectOfferSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestRejectOfferError = (state, action) => {
    return { ...state, isSaving: false };
};

// ---------- NEGOTIATE ----------
const requestNegotiateOffer = (state, action) => {
    return { ...state, isSaving: true };
};

const requestNegotiateOfferSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestNegotiateOfferError = (state, action) => {
    return { ...state, isSaving: false };
};

// ---------- COUNTER OFFER ----------
const requestCreateCounterOffer = (state, action) => {
    return { ...state, isSaving: true };
};

const requestCreateCounterOfferSuccess = (state, action) => {
    const counterOffer = action.payload
    const entities = state.entities.map(offer => {
        if (offer.id === counterOffer.originalOfferId) {
            return { ...offer, counterOffer }
        }
        return offer
    })
    return { ...state, entities, isSaving: false };
};

const requestCreateCounterOfferError = (state, action) => {
    return { ...state, isSaving: false };
};

// ---------- MARK AS READ ----------
const requestMarkOfferAsRead = (state, action) => {
    return { ...state, isSaving: true };
};

const requestMarkOfferAsReadSuccess = (state, action) => {
    const offerId = action.payload
    const entities = state.entities.map(offer => {
        if (offer.id === offerId) {
            return { ...offer, isRead: true }
        }
        return offer
    })
    return { ...state, entities, isSaving: false };
};

const requestMarkOfferAsReadError = (state, action) => {
    return { ...state, isSaving: false };
};

const updateOffer = (state, action) => {
    const entities = state.entities.map(offer => {
        if (offer.id === action.payload) {
            return {
                ...offer,
                isConsolidated: true
            }
        }
        return offer
    })
    return { ...state, entities };
};

const cotationUpdate = (state, action) => {
    const updatedCotation = action.payload.cotation
    const entities = state.entities.map(offer => {
        if (offer.price && offer.price.cbotId === action.payload.cbotId) {
            return {
                ...offer,
                price: {
                    ...offer.price,
                    updatedCotation,
                    updatedValue: calculateUpdatedValue(offer.price, updatedCotation, offer.price.updatedExchangeFee)
                }
            }
        }
        return offer
    })
    return { ...state, entities };
 };
 const exchangeUpdate = (state, action) => {
    const updatedExchangeFee = action.payload.exchange
    const entities = state.entities.map(offer => {
        if (offer.price && dateUtils.format(offer.price.paymentOn) === action.payload.date) {
            return {
                ...offer,
                price: {
                    ...offer.price,
                    updatedExchangeFee,
                    updatedValue: calculateUpdatedValue(offer.price, offer.price.updatedCotation, updatedExchangeFee)
                }
            }
        }
        return offer
    })
    return { ...state, entities }
 }

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const actions = {
    [REQUEST_OFFERS]: requestOffers,
    [REQUEST_OFFERS_SUCCESS]: requestOffersSuccess,
    [REQUEST_OFFERS_ERROR]: requestOffersError,
    [REQUEST_APPROVE_OFFER]: requestApproveOffer,
    [REQUEST_APPROVE_OFFER_SUCCESS]: requestApproveOfferSuccess,
    [REQUEST_APPROVE_OFFER_ERROR]: requestApproveOfferError,
    [REQUEST_REJECT_OFFER]: requestRejectOffer,
    [REQUEST_REJECT_OFFER_SUCCESS]: requestRejectOfferSuccess,
    [REQUEST_REJECT_OFFER_ERROR]: requestRejectOfferError,
    [REQUEST_NEGOTIATE_OFFER]: requestNegotiateOffer,
    [REQUEST_NEGOTIATE_OFFER_SUCCESS]: requestNegotiateOfferSuccess,
    [REQUEST_NEGOTIATE_OFFER_ERROR]: requestNegotiateOfferError,
    [REQUEST_CREATE_COUNTER_OFFER]: requestCreateCounterOffer,
    [REQUEST_CREATE_COUNTER_OFFER_SUCCESS]: requestCreateCounterOfferSuccess,
    [REQUEST_CREATE_COUNTER_OFFER_ERROR]: requestCreateCounterOfferError,
    [REQUEST_MARK_OFFER_AS_READ]: requestMarkOfferAsRead,
    [REQUEST_MARK_OFFER_AS_READ_SUCCESS]: requestMarkOfferAsReadSuccess,
    [REQUEST_MARK_OFFER_AS_READ_ERROR]: requestMarkOfferAsReadError,
    [UPDATE_OFFER]: updateOffer,
    [OFFERS_COTATION_UPDATE]: cotationUpdate,
    [OFFERS_EXCHANGE_UPDATE]: exchangeUpdate,
    [HANDLE_OFFERS_VALUE_CHANGE]: handleValueChange
};

export const dayOffers = (state = initialState.dayOffers, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
