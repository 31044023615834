import BaseService from './base.service'

class UserPreRegisterService extends BaseService {
  constructor() {
    super('UserPreRegister');
  }

  approve(id) {
    return this.post(id, `/${id}/Approve`)
  }

  reject(id, description) {
    return this.post({ description }, `/${id}/Reject`)
  }
}

export default UserPreRegisterService;
