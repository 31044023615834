import * as types from '../actionTypes'
import { ClientService, alertService } from '../../Services'
import { redirectUtils, cpfCnpjUtils } from '../../Utils'

const clientService = new ClientService();

export const requestEditClient = () => ({
    type: types.REQUEST_EDIT_CLIENT,
    payload: null
});

export const requestEditClientSuccess = client => ({
    type: types.REQUEST_EDIT_CLIENT_SUCCESS,
    payload: client
});

export const requestEditClientError = error => ({
    type: types.REQUEST_EDIT_CLIENT_ERROR,
    payload: error
});

export const handleEdit = clientForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(clientForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestEditClient());

    const clientData = {
        ...clientForm,
        isSeller: clientForm.isSeller === 'true',
    }

    clientForm.tradeRestriction.forEach((tro, i) => {
        clientData[`tradeRestriction${i + 1}`] = tro.value
    })

    clientService.saveOrUpdate(clientData)
        .then(response => {
            dispatch(requestEditClientSuccess())
            redirectUtils.redirectTo(`#/clientes/detalhe/${response.data.id}`)
            alertService.success('Cliente editado com sucesso!')
        })
        .catch(error => {
            const data = { ...clientData, isHeadOffice: String(clientData.isHeadOffice), isSeller: String(clientData.isSeller) }
            dispatch(requestEditClientError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao editar cliente')
        })
}

const checkRequiredFields = cf => {
    let alerts = []
    let isInvalid = false

    if (cf.activityType === '') {
        isInvalid = true
    }
    if (cf.legalName.length === 0) {
        isInvalid = true
    }
    if (!cpfCnpjUtils.isValid(cf.cpfcnpj)) {
        alerts.push('Campo CPF/CNPJ inválido!')
        isInvalid = true
    }
    if (cf.federalRegistration.length === 0) {
        isInvalid = true
    }
    if (cf.cep.length !== 8) {
        alerts.push('CEP inválido!')
        isInvalid = true
    }
    if (cf.address.length === 0) {
        isInvalid = true
    }
    if (cf.cityId === '') {
        isInvalid = true
    }
    if(cf.isSeller === null && ((cf.isSeller === 'true' && cf.bankData1) || cf.isSeller === 'false')) {
        isInvalid = true
    }

    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const requestClientDetails = () => ({
    type: types.REQUEST_EDIT_FORM_CLIENT_DETAILS,
    payload: null
});

export const requestClientDetailsSuccess = details => ({
    type: types.REQUEST_EDIT_FORM_CLIENT_DETAILS_SUCCESS,
    payload: details
})

export const requestClientDetailsError = error => ({
    type: types.REQUEST_EDIT_FORM_CLIENT_DETAILS_ERROR,
    payload: error
});

export const fetchClient = id => dispatch => {
    dispatch(requestClientDetails());

    clientService.getById(id)
        .then(response => {
            if (response.data) {
                const tradeRestriction = []
                for (let i = 1; i <= 4; i++) {
                    if (response.data[`tradeRestriction${i}`]) {
                        tradeRestriction.push(response.data[`tradeRestriction${i}`])
                        response.data[`tradeRestriction${i}`] = null
                    }
                }
                dispatch(requestClientDetailsSuccess({
                    ...response.data,
                    isSeller: response.data.isSeller ? 'true' : 'false',
                    tradeRestriction
                }));
            }
        })
        .catch(error => {
            dispatch(requestClientDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do cliente');
            redirectUtils.redirectTo(`#/clientes/detalhe/${id}`)
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_EDIT_CLIENT_FORM_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_EDIT_CLIENT_FORM,
    payload: null
});
