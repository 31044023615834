function setBackURL() {
  return "https://app-agrobrick-prod-api.azurewebsites.net/api";
  // return "https://app-agrobrick-dev-api.azurewebsites.net/api";
}

function setSignalRPriceURL() {
  return "https://app-agrobrick-prod-api.azurewebsites.net/inovaPriceHub";
  // return "https://app-agrobrick-dev-api.azurewebsites.net/inovaPriceHub";
}

function setSignalRNotificationURL() {
  return "https://app-agrobrick-prod-api.azurewebsites.net/inovaOfferUpdateHub";
  // return "https://app-agrobrick-dev-api.azurewebsites.net/inovaOfferUpdateHub";
}

const config = {
  backURL: setBackURL(),
  signalRPrice: setSignalRPriceURL(),
  signalRNotification: setSignalRNotificationURL(),
};

export default config;
