import { combineReducers } from "redux";
import { activityTypes } from "./activityTypesReducer";
import { auth } from "./authReducer";
import { cities } from "./citiesReducer";
import { clientDetails } from "./Clients/clientDetailsReducer";
import { clientForm } from "./Clients/clientFormReducer";
import { editClientForm } from "./Clients/editClientFormReducer";
import { clients } from "./Clients/clientReducer";
import { userDetails } from "./Users/userDetailReducer";
import { userForm } from "./Users/userFormReducer";
import { editUserForm } from "./Users/editUserFormReducer";
import { users } from "./Users/userReducer";
import { prices } from "./Prices/priceReducer";
import { pricesIndications } from "./Prices/priceIndicationReducer";
import { priceForm } from "./Prices/priceFormReducer";
import { pricesHistory } from "./Prices/priceHistoryReducer";
import { products } from "./productsReducer";
import { dayOffers } from "./Offers/dayOfferReducer";
import { offerForm } from "./Offers/offerFormReducer";
import { businessConfirmation } from "./businessConfirmationReducer";
import { negotiationsHistory } from "./Offers/negotiationHistoryReducer";
import { harvests } from "./harvestsReducer";
import { harvestForm } from "./harvestFormReducer";
import { portTerminals } from "./Port Terminals/portTerminalsReducer";
import { portTerminalDetails } from "./Port Terminals/portTerminalDetailsReducer";
import { portTerminalForm } from "./Port Terminals/portTerminalFormReducer";
import { editPortTerminalForm } from "./Port Terminals/editPortTermialFormReducer";
import { shippingAgreementTypes } from "./shippingAgreementTypesReducer";
import { freights } from "./freightReducer";
import { cbots } from "./cbotReducer";
import { cbotForm } from "./cbotFormReducer";
import { marketHours } from "./marketHoursReducer";
import { signalR } from "./signalRReducer";

const rootReducer = combineReducers({
  activityTypes,
  auth,
  cities,
  clientDetails,
  clientForm,
  editClientForm,
  clients,
  userDetails,
  userForm,
  editUserForm,
  users,
  prices,
  pricesIndications,
  priceForm,
  pricesHistory,
  products,
  dayOffers,
  offerForm,
  businessConfirmation,
  negotiationsHistory,
  harvests,
  harvestForm,
  portTerminals,
  portTerminalDetails,
  portTerminalForm,
  editPortTerminalForm,
  shippingAgreementTypes,
  freights,
  cbots,
  cbotForm,
  marketHours,
  signalR,
});

export default rootReducer;
