import BaseService from './base.service'

class OfferService extends BaseService {
  constructor() {
    super('Offer');
  }

  findAllToday(options) {
    return this.post(options, '/FindAllToday')
  }

  approve(id, buyerId) {
    return this.post({ buyerId }, `/Approve/${id}`)
  }

  reject(id) {
    return this.post({}, `/Reject/${id}`)
  }

  negotiate(id) {
    return this.post({}, `/Negotiate/${id}`)
  }

  markAsRead(id) {
    return this.post({}, `/MarkAsRead/${id}`)
  }

  findAllHistory(options) {
    return this.post(options, '/FindAllHistory')
  }
}

export default OfferService;
