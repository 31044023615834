import dateUtils from './dateUtils';

class ArrayUtils {
    sortBy(array, sortVariables, type) {
        switch (type) {
            case 'string':
                array.sort((a, b) => {
                    return this.cmpString(a[sortVariables[0]], b[sortVariables[0]]);
                });
                break;
            case 'date':
                array.sort((a, b) => {
                    return this.cmpDate(a[sortVariables[0]], b[sortVariables[0]]);
                });
                break;
            case 'boolean':
                array.sort((a, b) => {
                    return this.cmpBoolean(a[sortVariables[0]], b[sortVariables[0]]) === 0
                        ? this.cmpString(a[sortVariables[1]], b[sortVariables[1]])
                        : this.cmpBoolean(a[sortVariables[0]], b[sortVariables[0]]);
                });
                break;
            case 'number':
                array.sort((a, b) => {
                    return this.cmpNumber(b[sortVariables[0]], a[sortVariables[0]])
                });
                break;
            case 'pushStateReverse':
                array.sort((a, b) => {
                    return (
                        this.trueBooleanFirst(Boolean(a.active), Boolean(b.active)) ||
                        this.cmpNumber(b.pushCount, a.pushCount)
                    );
                });
                break;
            default:
                array.sort((a, b) => {
                    return this.cmpNumber(a[sortVariables[0]], b[sortVariables[0]]);
                });
                break;
        }
    }

    // ------------------------------------  Aux Functions
    cmpNumber = (a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    };

    cmpString = (a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 0;
    };

    cmpDate = (a, b) => {
        if (dateUtils.isGreater(a, b)) return 1;
        if (dateUtils.isGreater(b, a)) return -1;
        return 0;
    };

    cmpBoolean = (a, b) => {
        return a - b;
    };

    trueBooleanFirst = (a, b) => {
        if (a - b === 1) return -1;
        if (a - b === -1) return 1;
        return 0;
    };
}

const arrayUtils = new ArrayUtils();
export default arrayUtils;
