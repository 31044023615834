import React from 'react'
import ActivityRow from './activityRow'

export default function ActivitiesTable({ activities }) {
    return (
        <div className='activities'>
            <div className='header'>
                <span>Atividade</span>
                <span>Hora</span>
            </div>
            <div className='activities-table'>
                {activities.length ?
                    <>
                        {activities.map((activity, i) => <ActivityRow key={i} name={activity.item1} hour={activity.item2} />)}
                    </>
                    :
                    <div className='row px-4 justify-content-center align-items-center align-items-center h-100'>
                        <h4>Nenhuma atividade recebida</h4>
                    </div>
                }
            </div>
        </div>
    );
}
