import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dateUtils } from '../../Utils'
import { signalRActions } from '../../Actions'
import Loader from 'react-loader'
import DashboardCard from './dashboardCard'
import ActivitiesTable from './activitiesTable'

export default function DashboardContainer() {
    const signalR = useSelector(state => state.signalR)
    const dispatch = useDispatch();

    useEffect(() => {
        if (!signalR.currentDollarSubscribed && signalR.isPriceConnected)
            dispatch(signalRActions.subscribeExchanges([{ paymentOn: dateUtils.newDate().toISOString(), tradeOn: true }], 'currentDollarSubscribed'))
    }, [dispatch, signalR.currentDollarSubscribed, signalR.isPriceConnected])

    useEffect(() => { return () => dispatch(signalRActions.unsubscribeAll()) }, [dispatch])

    return (
        <div className='dashboard-page'>
            <Loader loaded={!signalR.isPriceConnecting} loadedClassName='dashboard-container' color='#fff'>
                <div className='stats'>
                    <div className='cards mb-4'>
                        <DashboardCard
                            title='Conexão com CMA'
                            value={signalR.instrumentation.isConnected ? 'Ativa' : 'Inativa'}
                            date={`Desde ${signalR.instrumentation.connectedOn || signalR.instrumentation.disconnectedOn ? signalR.instrumentation.isConnected ? dateUtils.format(signalR.instrumentation.connectedOn, 'DD/MM/YY - HH:mm') : dateUtils.format(signalR.instrumentation.disconnectedOn, 'DD/MM/YY - HH:mm') : '-'}`}
                            className={signalR.instrumentation.isConnected ? 'active' : 'inactive'}
                        />
                        <DashboardCard
                            title='Dólar Atual'
                            value={signalR.currentDollar.value || '-'}
                            date={signalR.currentDollar.hour ? `Atualizado às ${signalR.currentDollar.hour}` : '-'}
                        />
                    </div>
                    <ActivitiesTable activities={signalR.instrumentation.lastReceivedTickers} />
                </div>
            </Loader>
        </div>
    );
}
