import initialState from './initialState'
import {
    REQUEST_BUSINESS_CONFIRMATION,
    REQUEST_BUSINESS_CONFIRMATION_SUCCESS,
    REQUEST_BUSINESS_CONFIRMATION_ERROR,
    REQUEST_CONFIRM_BUSINESS_CONFIRMATION,
    REQUEST_CONFIRM_BUSINESS_CONFIRMATION_SUCCESS,
    REQUEST_CONFIRM_BUSINESS_CONFIRMATION_ERROR,
    REQUEST_NEGOTIATION_CONTRACT,
    REQUEST_NEGOTIATION_CONTRACT_SUCCESS,
    REQUEST_NEGOTIATION_CONTRACT_ERROR,
    REQUEST_RESEND_EMAIL,
    REQUEST_RESEND_EMAIL_SUCCESS,
    REQUEST_RESEND_EMAIL_ERROR,
    HANDLE_BUSINESS_CONFIRMATION_VALUE_CHANGE,
    CLEAR_BUSINESS_CONFIRMATION_FORM
} from '../Actions/actionTypes'

const requestBusinessConfirmation = (state, action) => {
    return { ...state, isFetching: true };
};

const requestBusinessConfirmationSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isFetching: false
    };
};

const requestBusinessConfirmationError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestConfirmBusinessConfirmation = (state, action) => {
    return { ...state, isSaving: true };
};

const requestConfirmBusinessConfirmationSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestConfirmBusinessConfirmationError = (state, action) => {
    return { ...state, isSaving: false };
};

const requestContractFile = (state, action) => {
    return { ...state, isFetching: true };
};

const requestContractFileSuccess = (state, action) => {
    return { ...state, isFetching: false };
};

const requestContractFileError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestResendEmail = (state, action) => {
    return { ...state, isSaving: true };
};

const requestResendEmailSuccess = (state, action) => {
    return { ...state, isSaving: false };
};

const requestResendEmailError = (state, action) => {
    return { ...state, isSaving: false };
};

const handleValueChange = (state, action) => {
    return { ...state, form: { ...state.form, ...action.payload }, };
};

const clear = (state, action) => {
    return initialState.businessConfirmation;
};

const actions = {
    [REQUEST_BUSINESS_CONFIRMATION]: requestBusinessConfirmation,
    [REQUEST_BUSINESS_CONFIRMATION_SUCCESS]: requestBusinessConfirmationSuccess,
    [REQUEST_BUSINESS_CONFIRMATION_ERROR]: requestBusinessConfirmationError,
    [REQUEST_CONFIRM_BUSINESS_CONFIRMATION]: requestConfirmBusinessConfirmation,
    [REQUEST_CONFIRM_BUSINESS_CONFIRMATION_SUCCESS]: requestConfirmBusinessConfirmationSuccess,
    [REQUEST_CONFIRM_BUSINESS_CONFIRMATION_ERROR]: requestConfirmBusinessConfirmationError,
    [REQUEST_NEGOTIATION_CONTRACT]: requestContractFile,
    [REQUEST_NEGOTIATION_CONTRACT_SUCCESS]: requestContractFileSuccess,
    [REQUEST_NEGOTIATION_CONTRACT_ERROR]: requestContractFileError,
    [REQUEST_RESEND_EMAIL]: requestResendEmail,
    [REQUEST_RESEND_EMAIL_SUCCESS]: requestResendEmailSuccess,
    [REQUEST_RESEND_EMAIL_ERROR]: requestResendEmailError,
    [HANDLE_BUSINESS_CONFIRMATION_VALUE_CHANGE]: handleValueChange,
    [CLEAR_BUSINESS_CONFIRMATION_FORM]: clear
};

export const businessConfirmation = (state = initialState.businessConfirmation, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
