import initialState from '../initialState'
import {
    REQUEST_USERS,
    REQUEST_USER_DETAILS,
    REQUEST_USER_DETAILS_SUCCESS,
    REQUEST_USER_DETAILS_ERROR,
    REQUEST_USER_APPROVAL,
    REQUEST_USER_APPROVAL_SUCCESS,
    REQUEST_USER_APPROVAL_ERROR,
    REQUEST_USER_REJECTION,
    REQUEST_USER_REJECTION_SUCCESS,
    REQUEST_USER_REJECTION_ERROR,
    REQUEST_USER_STATUS_TOGGLE,
    REQUEST_USER_STATUS_TOGGLE_SUCCESS,
    REQUEST_USER_STATUS_TOGGLE_ERROR,
    CLEAR_USER_DETAILS,
} from '../../Actions/actionTypes'

import { status } from '../../Utils'

const requestUsers = (state, action) => {
    return { 
        ...state,
        data: { ...action.payload }
    }
}

const requestUserDetails = (state, action) => {
    return { ...state, isFetching: true };
};

const requestUserDetailsSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isFetching: false
    };
};

const requestUserDetailsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestUserApproval = (state, action) => {
    return { ...state, isSaving: true };
};

const requestUserApprovalSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isSaving: false
    };
};

const requestUserApprovalError = (state, action) => {
    return { ...state, isSaving: false };
};

const requestUserRejection = (state, action) => {
    return { ...state, isRejecting: true };
};

const requestUserRejectionSuccess = (state, action) => {
    return {
        ...state,
        data: { ...action.payload },
        isFetching: false
    };
};

const requestUserRejectionError = (state, action) => {
    return { ...state, isRejecting: false };
};

const requestUserStatusToggle = (state, action) => {
    const newStatus = state.data.status === status.Active ? status.Inactive : status.Active
    return { ...state, data: { ...state.data, status: newStatus } };
};

const requestUserStatusToggleSuccess = (state, action) => {
    return { ...state };
};

const requestUserStatusToggleError = (state, action) => {
    const newStatus = state.data.status === status.Active ? status.Inactive : status.Active
    return { ...state, data: { ...state.data, status: newStatus } };
};

const clear = (state, action) => {
    return initialState.userDetails;
};

const actions = {
    [REQUEST_USERS]: requestUsers,
    [REQUEST_USER_DETAILS]: requestUserDetails,
    [REQUEST_USER_DETAILS_SUCCESS]: requestUserDetailsSuccess,
    [REQUEST_USER_DETAILS_ERROR]: requestUserDetailsError,
    [REQUEST_USER_APPROVAL]: requestUserApproval,
    [REQUEST_USER_APPROVAL_SUCCESS]: requestUserApprovalSuccess,
    [REQUEST_USER_APPROVAL_ERROR]: requestUserApprovalError,
    [REQUEST_USER_REJECTION]: requestUserRejection,
    [REQUEST_USER_REJECTION_SUCCESS]: requestUserRejectionSuccess,
    [REQUEST_USER_REJECTION_ERROR]: requestUserRejectionError,
    [REQUEST_USER_STATUS_TOGGLE]: requestUserStatusToggle,
    [REQUEST_USER_STATUS_TOGGLE_SUCCESS]: requestUserStatusToggleSuccess,
    [REQUEST_USER_STATUS_TOGGLE_ERROR]: requestUserStatusToggleError,
    [CLEAR_USER_DETAILS]: clear
};

export const userDetails = (state = initialState.userDetails, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
