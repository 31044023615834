import axios from 'axios'
import config from '../config'

class BaseService {
  constructor(modelName) {
    this.url = `${config.backURL}/${modelName}`;
  }

  get = (query, route) => {
    const queryParams = {
      filter: JSON.stringify(query || {})
    };

    const endpoint = route ? this.url + route : this.url;

    return axios.get(endpoint, { params: queryParams });
  };

  getFileWithUrl = route => {
    return axios(route, {
      method: 'GET',
      responseType: 'blob'
    });
  };

  getWithQueryParams = (query, route) => {
    const endpoint = route ? this.url + route : this.url;

    return axios.get(endpoint, { params: { ...query } });
  };

  getById = (id, route) => {
    let endpoint = route ? this.url + route : this.url;

    return axios.get(`${endpoint}/${id}`);
  };

  saveOrUpdate = data => {
    let resultPromise;

    if (!data.id) {
      delete data.id;
      resultPromise = this.post(data);
    } else {
      resultPromise = axios.put(`${this.url}/${data.id}`, data);
    }

    return resultPromise;
  };

  post = (data, route) => {
    const endpoint = route ? this.url + route : this.url;
    return axios.post(endpoint, data);
  };

  put = (data, route) => {
    const endpoint = route ? this.url + route : this.url;
    return axios.put(endpoint, data);
  };

  delete = id => {
    return axios.delete(`${this.url}/${id}`);
  };

  findAllCount = (filter = {}) => {
    return axios.post(`${this.url}/FindAllCount`, filter);
  };

  findAll(options = {}) {
    return this.post(options, '/FindAll');
  }

  findAllGet(query = {}) {
    return this.get(query, '/FindAll');
  }

  deleteList = (data, route) => {
    const endpoint = route ? this.url + route : this.url;
    return axios.delete(endpoint, { data });
  }
}

export default BaseService;
