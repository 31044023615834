import { ProductService, alertService } from '../Services'
import * as types from './actionTypes'

const productService = new ProductService();

export const requestProducts = () => ({
    type: types.REQUEST_PRODUCTS,
    payload: null
});

export const requestProductsSuccess = products => ({
    type: types.REQUEST_PRODUCTS_SUCCESS,
    payload: products
});

export const requestProductsError = error => ({
    type: types.REQUEST_PRODUCTS_ERROR,
    payload: error
});

export const fetchProducts = () => dispatch => {
    dispatch(requestProducts());
    productService
        .findAll()
        .then(response => {
            if (response.data) {
                const entities = response.data;
                const options = entities.map(product => {
                    return {
                        value: product.id,
                        label: product.description,
                        ...product
                    };
                });

                dispatch(requestProductsSuccess({ options, entities }));
            }
        })
        .catch(error => {
            dispatch(requestProductsError(error));
            alertService.error(error, 'Erro ao buscar produtos');
        });
};