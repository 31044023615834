class LocalStorageUtils {
    setAuthToken(token) {
        localStorage.setItem("authToken", JSON.stringify(token));
    }

    getAuthToken() {
        return JSON.parse(localStorage.getItem("authToken"));
    }

    removeAuthToken() {
        localStorage.removeItem("authToken");
    }
}

const localStorageUtils = new LocalStorageUtils();
export default localStorageUtils;