import { CBOTService, alertService } from '../Services'
import * as types from './actionTypes'

const cbotService = new CBOTService();

export const requestCBOTs = () => ({
    type: types.REQUEST_CBOTS,
    payload: null
});

export const requestCBOTsSuccess = cbots => ({
    type: types.REQUEST_CBOTS_SUCCESS,
    payload: cbots
});

export const requestCBOTsError = error => ({
    type: types.REQUEST_CBOTS_ERROR,
    payload: error
});

export const fetchCBOTs = () => dispatch => {
    dispatch(requestCBOTs());
    cbotService.findAllGroupedByProduct()
        .then(response => {
            if (response.data) {
                dispatch(requestCBOTsSuccess(response.data));
            }
        })
        .catch(error => {
            dispatch(requestCBOTsError(error));
            alertService.error(error, 'Erro ao buscar CBOTs');
        });
};

export const requestDeleteCBOT = () => ({
    type: types.REQUEST_DELETE_CBOT,
    payload: null
});

export const requestDeleteCBOTSuccess = () => ({
    type: types.REQUEST_DELETE_CBOT_SUCCESS,
    payload: null
});

export const requestDeleteCBOTError = error => ({
    type: types.REQUEST_DELETE_CBOT_ERROR,
    payload: error
});

export const deleteCBOT = (id, hideModal) => dispatch => {
    dispatch(requestDeleteCBOT());
    cbotService.delete(id)
        .then(() => {
            dispatch(requestDeleteCBOTSuccess())
            hideModal()
            dispatch(fetchCBOTs())
            alertService.success('Cotação excluída com sucesso!')
        }).catch(error => {
            dispatch(requestDeleteCBOTError(error))
            hideModal()
            alertService.error(error, 'Erro ao excluir cotação')
        })
};
