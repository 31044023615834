import initialState from '../initialState'
import { 
    REQUEST_SAVE_PORT_TERMINAL_FORM,
    REQUEST_SAVE_PORT_TERMINAL_FORM_SUCCESS,
    REQUEST_SAVE_PORT_TERMINAL_FORM_ERROR,
    CLEAR_PORT_TERMINAL_FORM 
} from '../../Actions/actionTypes'


const requestSavePortTerminalForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSavePortTerminalFormSuccess = (state, action) => {
    return initialState.portTerminalForm;
};

const requestSavePortTerminalFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clear = (state, action) => {
    return initialState.portTerminalForm
}

const actions = {
    [REQUEST_SAVE_PORT_TERMINAL_FORM]: requestSavePortTerminalForm,
    [REQUEST_SAVE_PORT_TERMINAL_FORM_SUCCESS]: requestSavePortTerminalFormSuccess,
    [REQUEST_SAVE_PORT_TERMINAL_FORM_ERROR]: requestSavePortTerminalFormError,
    [CLEAR_PORT_TERMINAL_FORM]: clear
};

export const portTerminalForm = (state = initialState.portTerminalForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
