import * as types from '../actionTypes'
import { OfferService, alertService } from '../../Services'
import { offerStates } from '../../Utils'

const offerService = new OfferService();

export const requestNegotiationsHistory = () => ({
    type: types.REQUEST_NEGOTIATIONS_HISTORY,
    payload: null
});

export const requestNegotiationsHistorySuccess = offers => ({
    type: types.REQUEST_NEGOTIATIONS_HISTORY_SUCCESS,
    payload: offers
});

export const requestNegotiationsHistoryError = error => ({
    type: types.REQUEST_NEGOTIATIONS_HISTORY_ERROR,
    payload: error
});

export const fetchNegotiationsHistory = (options) => dispatch => {
    dispatch(requestNegotiationsHistory());
    offerService.findAllHistory({...options, states: [offerStates.Approved.id]})
    .then(response => {
            if (response.data) {
                dispatch(requestNegotiationsHistorySuccess({ count: response.data.totalResults, entities: response.data.results }));
            }
        })
        .catch(error => {
            dispatch(requestNegotiationsHistoryError(error));
            alertService.error(error, 'Erro ao buscar ofertas');
        });
};

export const updateNegotiation = id => ({
    type: types.UPDATE_NEGOTIATION,
    payload: id
});

export const handleValueChange = edition => ({
    type: types.HANDLE_NEGOTIATIONS_HISTORY_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_NEGOTIATIONS_HISTORY,
    payload: null
});
