import * as types from '../actionTypes'
import { ClientService, alertService } from '../../Services'
import { redirectUtils, cpfCnpjUtils } from '../../Utils'
import { clientActions } from '../index'
const clientService = new ClientService();

export const requestSaveClientForm = () => ({
    type: types.REQUEST_SAVE_CLIENT_FORM,
    payload: null
});

export const requestSaveClientFormSuccess = client => ({
    type: types.REQUEST_SAVE_CLIENT_FORM_SUCCESS,
    payload: client
});

export const requestSaveClientFormError = error => ({
    type: types.REQUEST_SAVE_CLIENT_FORM_ERROR,
    payload: error
});

export const handleSave = clientForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(clientForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestSaveClientForm());

    const clientData = {
        ...clientForm,
        isSeller: clientForm.isSeller === 'true',
    }

    clientForm.tradeRestriction.forEach((tro, i) => {
        clientData[`tradeRestriction${i + 1}`] = tro.value
    })
    clientService
        .saveOrUpdate(clientData)
        .then(response => {
            dispatch(requestSaveClientFormSuccess())
            dispatch(clientActions.updateClientsList(response.data))
            redirectUtils.redirectTo('#/clientes')
            alertService.success('Cliente criado com sucesso!')
        })
        .catch(error => {
            const data = { ...clientData, isHeadOffice: String(clientData.isHeadOffice), isSeller: String(clientData.isSeller) }
            dispatch(requestSaveClientFormError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar cliente')
        })
}

const checkRequiredFields = cf => {
    let alerts = []
    let isInvalid = false

    if (cf.activityType === '') {
        isInvalid = true
    }
    if (cf.legalName.length === 0) {
        isInvalid = true
    }
    if (!cpfCnpjUtils.isValid(cf.cpfcnpj)) {
        alerts.push('Campo CPF/CNPJ inválido!')
        isInvalid = true
    }
    if (cf.federalRegistration.length === 0) {
        isInvalid = true
    }
    if (cf.cep.length !== 8) {
        alerts.push('CEP inválido!')
        isInvalid = true
    }
    if (cf.address.length === 0) {
        isInvalid = true
    }
    if (cf.cityId === '') {
        isInvalid = true
    }
    if(cf.isSeller === null && ((cf.isSeller === 'true' && cf.bankData1) || cf.isSeller === 'false')) {
        isInvalid = true
    }

    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const clear = () => ({
    type: types.CLEAR_CLIENT_FORM,
    payload: null
});
