import React, { Component } from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

class CustomPagination extends Component {

    render() {
        const totalPages = Math.ceil(
            this.props.totalItems / this.props.itemsPerPage
        );
        let numberOfOptions =
            totalPages > (this.props.maxNumberOfOptions || totalPages)
                ? this.props.maxNumberOfOptions
                : totalPages;
        const currentPage = this.props.currentPage;
        const pageIndex =
            this.props.currentPage + 1 > this.totalPages
                ? this.totalpages
                : this.props.currentPage + 1;
        return numberOfOptions > 1 ? (
            <Pagination>
                <PaginationItem disabled={this.props.isLoading}>
                    <PaginationLink
                        onClick={() => {
                            this.props.goToPage(0);
                        }}
                        disabled={currentPage === 0}
                        className='no-box-shadow'
                    >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={this.props.isLoading}>
                    <PaginationLink
                        onClick={() => {
                            this.props.goToPage(this.props.currentPage - 1);
                        }}
                        disabled={currentPage === 0}
                        className='no-box-shadow'
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </PaginationLink>
                </PaginationItem>
                <div
                    className='pagination-index'
                    style={{
                        display: 'flex',
                        padding: '0px 10px 0px 10px',
                        borderRadius: '0',
                        height: '34px',
                        width: '115px',
                        justifyContent: 'center',
                        border: '1px solid #dee2e6',
                        borderLeft: 'none',
                        backgroundColor: 'white'
                    }}
                >
                    <span className='my-auto mx-1'>{pageIndex} de {totalPages}</span>
                </div>
                <PaginationItem disabled={this.props.isLoading}>
                    <PaginationLink
                        onClick={() => {
                            this.props.goToPage(this.props.currentPage + 1);
                        }}
                        disabled={currentPage === totalPages - 1}
                        className='no-box-shadow'
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={this.props.isLoading}>
                    <PaginationLink
                        onClick={() => {
                            this.props.goToPage(totalPages - 1);
                        }}
                        disabled={currentPage === totalPages - 1}
                        className='no-box-shadow'
                    >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        ) : null;
    }
}

CustomPagination.propTypes = {
    currentPage: PropTypes.number,
    goToPage: PropTypes.func,
    totalItems: PropTypes.number,
    itemsPerPage: PropTypes.number,
    maxNumberOfOptions: PropTypes.number,
    isLoading: PropTypes.bool
};

export default CustomPagination;
