//--------------------- Authorization
export const REQUEST_AUTH = 'REQUEST_AUTH'
export const REQUEST_AUTH_SUCCESS = 'REQUEST_AUTH_SUCCESS'
export const REQUEST_AUTH_ERROR = 'REQUEST_AUTH_ERROR'

//---------------------- Activity Type
export const REQUEST_ACTIVITY_TYPES = 'REQUEST_ACTIVITY_TYPES'
export const REQUEST_ACTIVITY_TYPES_SUCCESS = 'REQUEST_ACTIVITY_TYPES_SUCCESS'
export const REQUEST_ACTIVITY_TYPES_ERROR = 'REQUEST_ACTIVITY_TYPES_ERROR'

//---------------------- City
export const REQUEST_CITIES = 'REQUEST_CITIES'
export const REQUEST_CITIES_SUCCESS = 'REQUEST_CITIES_SUCCESS'
export const REQUEST_CITIES_ERROR = 'REQUEST_CITIES_ERROR'

//---------------------- Client
export const REQUEST_CLIENTS = 'REQUEST_CLIENTS'
export const REQUEST_CLIENTS_SUCCESS = 'REQUEST_CLIENTS_SUCCESS'
export const REQUEST_CLIENTS_ERROR = 'REQUEST_CLIENTS_ERROR'
export const HANDLE_CLIENTS_VALUE_CHANGE = 'HANDLE_CLIENTS_VALUE_CHANGE'
export const UPDATE_CLIENTS_LIST = 'UPDATE_CLIENTS_LIST'

//--------------------- Client Details
export const REQUEST_CLIENT_DETAILS = 'REQUEST_CLIENT_DETAILS'
export const REQUEST_CLIENT_DETAILS_SUCCESS = 'REQUEST_CLIENT_DETAILS_SUCCESS'
export const REQUEST_CLIENT_DETAILS_ERROR = 'REQUEST_CLIENT_DETAILS_ERROR'
export const REQUEST_CLIENT_STATUS_TOGGLE = 'REQUEST_CLIENT_STATUS_TOGGLE'
export const REQUEST_CLIENT_STATUS_TOGGLE_SUCCESS = 'REQUEST_CLIENT_STATUS_TOGGLE_SUCCESS'
export const REQUEST_CLIENT_STATUS_TOGGLE_ERROR = 'REQUEST_CLIENT_STATUS_TOGGLE_ERROR'
export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS'

//--------------------- Client Form
export const REQUEST_SAVE_CLIENT_FORM = 'REQUEST_SAVE_CLIENT_FORM'
export const REQUEST_SAVE_CLIENT_FORM_SUCCESS = 'REQUEST_SAVE_CLIENT_FORM_SUCCESS'
export const REQUEST_SAVE_CLIENT_FORM_ERROR = 'REQUEST_SAVE_CLIENT_FORM_ERROR'
export const CLEAR_CLIENT_FORM = 'CLEAR_CLIENT_FORM'

//--------------------- Edit Client Form
export const REQUEST_EDIT_CLIENT = 'REQUEST_EDIT_CLIENT'
export const REQUEST_EDIT_CLIENT_SUCCESS = 'REQUEST_EDIT_CLIENT_SUCCESS'
export const REQUEST_EDIT_CLIENT_ERROR = 'REQUEST_EDIT_CLIENT_ERROR'
export const REQUEST_EDIT_FORM_CLIENT_DETAILS = 'REQUEST_EDIT_FORM_CLIENT_DETAILS'
export const REQUEST_EDIT_FORM_CLIENT_DETAILS_SUCCESS = 'REQUEST_EDIT_FORM_CLIENT_DETAILS_SUCCESS'
export const REQUEST_EDIT_FORM_CLIENT_DETAILS_ERROR = 'REQUEST_EDIT_FORM_CLIENT_DETAILS_ERROR'
export const HANDLE_EDIT_CLIENT_FORM_VALUE_CHANGE = 'HANDLE_EDIT_CLIENT_FORM_VALUE_CHANGE'
export const CLEAR_EDIT_CLIENT_FORM = 'CLEAR_EDIT_CLIENT_FORM'

//---------------------- User
export const REQUEST_USERS = 'REQUEST_USERS'
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS'
export const REQUEST_USERS_ERROR = 'REQUEST_USERS_ERROR'
export const HANDLE_USERS_VALUE_CHANGE = 'HANDLE_USERS_VALUE_CHANGE'
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST'
export const CLEAR_USERS = 'CLEAR_USERS'

//---------------------- User Details
export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS'
export const REQUEST_USER_DETAILS_SUCCESS = 'REQUEST_USER_DETAILS_SUCCESS'
export const REQUEST_USER_DETAILS_ERROR = 'REQUEST_USER_DETAILS_ERROR'

export const REQUEST_USER_APPROVAL = 'REQUEST_USER_APPROVAL'
export const REQUEST_USER_APPROVAL_SUCCESS = 'REQUEST_USER_APPROVAL_SUCCESS'
export const REQUEST_USER_APPROVAL_ERROR = 'REQUEST_USER_APPROVAL_ERROR'

export const REQUEST_USER_REJECTION = 'REQUEST_USER_REJECTION'
export const REQUEST_USER_REJECTION_SUCCESS = 'REQUEST_USER_REJECTION_SUCCESS'
export const REQUEST_USER_REJECTION_ERROR = 'REQUEST_USER_REJECTION_ERROR'

export const REQUEST_USER_STATUS_TOGGLE = 'REQUEST_USER_STATUS_TOGGLE'
export const REQUEST_USER_STATUS_TOGGLE_SUCCESS = 'REQUEST_USER_STATUS_TOGGLE_SUCCESS'
export const REQUEST_USER_STATUS_TOGGLE_ERROR = 'REQUEST_USER_STATUS_TOGGLE_ERROR'

export const CLEAR_USER_DETAILS = 'CLEAR_USER_DETAILS'

//---------------------- User Form
export const REQUEST_SAVE_USER_FORM = 'REQUEST_SAVE_USER_FORM'
export const REQUEST_SAVE_USER_FORM_SUCCESS = 'REQUEST_SAVE_USER_FORM_SUCCESS'
export const REQUEST_SAVE_USER_FORM_ERROR = 'REQUEST_SAVE_USER_FORM_ERROR'
export const CLEAR_USER_FORM = 'CLEAR_USER_FORM'

//---------------------- Edit User Form
export const REQUEST_EDIT_USER = 'REQUEST_EDIT_USER'
export const REQUEST_EDIT_USER_SUCCESS = 'REQUEST_EDIT_USER_SUCCESS'
export const REQUEST_EDIT_USER_ERROR = 'REQUEST_EDIT_USER_ERROR'
export const REQUEST_EDIT_FORM_USER_DETAILS = 'REQUEST_EDIT_FORM_USER_DETAILS'
export const REQUEST_EDIT_FORM_USER_DETAILS_SUCCESS = 'REQUEST_EDIT_FORM_USER_DETAILS_SUCCESS'
export const REQUEST_EDIT_FORM_USER_DETAILS_ERROR = 'REQUEST_EDIT_FORM_USER_DETAILS_ERROR'
export const HANDLE_EDIT_USER_FORM_VALUE_CHANGE = 'HANDLE_EDIT_USER_FORM_VALUE_CHANGE'
export const CLEAR_EDIT_USER_FORM = 'CLEAR_EDIT_USER_FORM'

//---------------------- Prices
export const REQUEST_PRICES = 'REQUEST_PRICES'
export const REQUEST_PRICES_SUCCESS = 'REQUEST_PRICES_SUCCESS'
export const REQUEST_PRICES_ERROR = 'REQUEST_PRICES_ERROR'

export const REQUEST_DELETE_PRICES = 'REQUEST_DELETE_PRICES'
export const REQUEST_DELETE_PRICES_SUCCESS = 'REQUEST_DELETE_PRICES_SUCCESS'
export const REQUEST_DELETE_PRICES_ERROR = 'REQUEST_DELETE_PRICES_ERROR'

export const REQUEST_PUBLISH_PRICES = 'REQUEST_PUBLISH_PRICES'
export const REQUEST_PUBLISH_PRICES_SUCCESS = 'REQUEST_PUBLISH_PRICES_SUCCESS'
export const REQUEST_PUBLISH_PRICES_ERROR = 'REQUEST_PUBLISH_PRICES_ERROR'

export const HANDLE_PRICES_VALUE_CHANGE = 'HANDLE_PRICES_VALUE_CHANGE'
export const UPDATE_PRICES_LIST = 'UPDATE_PRICES_LIST'
export const HANDLE_PRICES_CHECKBOX_TOGGLE = 'HANDLE_PRICES_CHECKBOX_TOGGLE'

export const PRICES_COTATION_UPDATE = 'PRICES_COTATION_UPDATE'
export const PRICES_EXCHANGE_UPDATE = 'PRICES_EXCHANGE_UPDATE'

//---------------------- Prices Indications
export const HANDLE_PRICES_INDICATIONS_VALUE_CHANGE = 'HANDLE_PRICES_INDICATIONS_VALUE_CHANGE'
export const REQUEST_PUBLISHED_PRICES = 'REQUEST_PUBLISHED_PRICES'
export const REQUEST_PUBLISHED_PRICES_SUCCESS = 'REQUEST_PUBLISHED_PRICES_SUCCESS'
export const REQUEST_PUBLISHED_PRICES_ERROR = 'REQUEST_PUBLISHED_PRICES_ERROR'

export const REQUEST_UPDATE_PRICE_VALUE = 'REQUEST_UPDATE_PRICE_VALUE'
export const REQUEST_UPDATE_PRICE_VALUE_SUCCESS = 'REQUEST_UPDATE_PRICE_VALUE_SUCCESS'
export const REQUEST_UPDATE_PRICE_VALUE_ERROR = 'REQUEST_UPDATE_PRICE_VALUE_ERROR'

export const REQUEST_UNPUBLISH_PRICE = 'REQUEST_UNPUBLISH_PRICE'
export const REQUEST_UNPUBLISH_PRICE_SUCCESS = 'REQUEST_UNPUBLISH_PRICE_SUCCESS'
export const REQUEST_UNPUBLISH_PRICE_ERROR = 'REQUEST_UNPUBLISH_PRICE_ERROR'

export const PRICE_INDICATIONS_COTATION_UPDATE = 'PRICE_INDICATIONS_COTATION_UPDATE'
export const PRICE_INDICATIONS_EXCHANGE_UPDATE = 'PRICE_INDICATIONS_EXCHANGE_UPDATE'

//---------------------- Price Form
export const REQUEST_SAVE_PRICE_FORM = 'REQUEST_SAVE_PRICE_FORM'
export const REQUEST_SAVE_PRICE_FORM_SUCCESS = 'REQUEST_SAVE_PRICE_FORM_SUCCESS'
export const REQUEST_SAVE_PRICE_FORM_ERROR = 'REQUEST_SAVE_PRICE_FORM_ERROR'
export const CLEAR_PRICE_FORM = 'CLEAR_PRICE_FORM'
export const HANDLE_PRICE_FORM_VALUE_CHANGE = 'HANDLE_PRICE_FORM_VALUE_CHANGE'

export const REQUEST_PRICE_REWARD = 'REQUEST_PRICE_REWARD'
export const REQUEST_PRICE_REWARD_SUCCESS = 'REQUEST_PRICE_REWARD_SUCCESS'
export const REQUEST_PRICE_REWARD_ERROR = 'REQUEST_PRICE_REWARD_ERROR'

//---------------------- Prices History
export const REQUEST_PRICES_HISTORY = 'REQUEST_PRICES_HISTORY'
export const REQUEST_PRICES_HISTORY_SUCCESS = 'REQUEST_PRICES_HISTORY_SUCCESS'
export const REQUEST_PRICES_HISTORY_ERROR = 'REQUEST_PRICES_HISTORY_ERROR'
export const HANDLE_PRICES_HISTORY_VALUE_CHANGE = 'HANDLE_PRICES_HISTORY_VALUE_CHANGE'
export const CLEAR_PRICES_HISTORY = 'CLEAR_PRICES_HISTORY'

//---------------------- Product
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS'
export const REQUEST_PRODUCTS_ERROR = 'REQUEST_PRODUCTS_ERROR'

//---------------------- Day Offers
export const REQUEST_OFFERS = 'REQUEST_OFFERS'
export const REQUEST_OFFERS_SUCCESS = 'REQUEST_OFFERS_SUCCESS'
export const REQUEST_OFFERS_ERROR = 'REQUEST_OFFERS_ERROR'

export const REQUEST_APPROVE_OFFER = 'REQUEST_APPROVE_OFFER'
export const REQUEST_APPROVE_OFFER_SUCCESS = 'REQUEST_APPROVE_OFFER_SUCCESS'
export const REQUEST_APPROVE_OFFER_ERROR = 'REQUEST_APPROVE_OFFER_ERROR'

export const REQUEST_REJECT_OFFER = 'REQUEST_REJECT_OFFER'
export const REQUEST_REJECT_OFFER_SUCCESS = 'REQUEST_REJECT_OFFER_SUCCESS'
export const REQUEST_REJECT_OFFER_ERROR = 'REQUEST_REJECT_OFFER_ERROR'

export const REQUEST_NEGOTIATE_OFFER = 'REQUEST_NEGOTIATE_OFFER'
export const REQUEST_NEGOTIATE_OFFER_SUCCESS = 'REQUEST_NEGOTIATE_OFFER_SUCCESS'
export const REQUEST_NEGOTIATE_OFFER_ERROR = 'REQUEST_NEGOTIATE_OFFER_ERROR'

export const REQUEST_CREATE_COUNTER_OFFER = 'REQUEST_CREATE_COUNTER_OFFER'
export const REQUEST_CREATE_COUNTER_OFFER_SUCCESS = 'REQUEST_CREATE_COUNTER_OFFER_SUCCESS'
export const REQUEST_CREATE_COUNTER_OFFER_ERROR = 'REQUEST_CREATE_COUNTER_OFFER_ERROR'

export const REQUEST_MARK_OFFER_AS_READ = 'REQUEST_MARK_OFFER_AS_READ'
export const REQUEST_MARK_OFFER_AS_READ_SUCCESS = 'REQUEST_MARK_OFFER_AS_READ_SUCCESS'
export const REQUEST_MARK_OFFER_AS_READ_ERROR = 'REQUEST_MARK_OFFER_AS_READ_ERROR'

export const UPDATE_OFFER = 'UPDATE_OFFER'
export const OFFERS_COTATION_UPDATE = 'OFFERS_COTATION_UPDATE'
export const OFFERS_EXCHANGE_UPDATE = 'OFFERS_EXCHANGE_UPDATE'
export const HANDLE_OFFERS_VALUE_CHANGE = 'HANDLE_OFFERS_VALUE_CHANGE'

//---------------------- Business Confirmation
export const REQUEST_BUSINESS_CONFIRMATION = 'REQUEST_BUSINESS_CONFIRMATION'
export const REQUEST_BUSINESS_CONFIRMATION_SUCCESS = 'REQUEST_BUSINESS_CONFIRMATION_SUCCESS'
export const REQUEST_BUSINESS_CONFIRMATION_ERROR = 'REQUEST_BUSINESS_CONFIRMATION_ERROR'

export const REQUEST_CONFIRM_BUSINESS_CONFIRMATION = 'REQUEST_CONFIRM_BUSINESS_CONFIRMATION'
export const REQUEST_CONFIRM_BUSINESS_CONFIRMATION_SUCCESS = 'REQUEST_CONFIRM_BUSINESS_CONFIRMATION_SUCCESS'
export const REQUEST_CONFIRM_BUSINESS_CONFIRMATION_ERROR = 'REQUEST_CONFIRM_BUSINESS_CONFIRMATION_ERROR'

export const REQUEST_NEGOTIATION_CONTRACT = 'REQUEST_NEGOTIATION_CONTRACT'
export const REQUEST_NEGOTIATION_CONTRACT_SUCCESS = 'REQUEST_NEGOTIATION_CONTRACT_SUCCESS'
export const REQUEST_NEGOTIATION_CONTRACT_ERROR = 'REQUEST_NEGOTIATION_CONTRACT_ERROR'

export const REQUEST_RESEND_EMAIL = 'REQUEST_RESEND_EMAIL'
export const REQUEST_RESEND_EMAIL_SUCCESS = 'REQUEST_RESEND_EMAIL_SUCCESS'
export const REQUEST_RESEND_EMAIL_ERROR = 'REQUEST_RESEND_EMAIL_ERROR'

export const HANDLE_BUSINESS_CONFIRMATION_VALUE_CHANGE = 'HANDLE_BUSINESS_CONFIRMATION_VALUE_CHANGE'
export const CLEAR_BUSINESS_CONFIRMATION_FORM = 'CLEAR_BUSINESS_CONFIRMATION_FORM'

//---------------------- Negotiations History
export const REQUEST_NEGOTIATIONS_HISTORY = 'REQUEST_NEGOTIATIONS_HISTORY'
export const REQUEST_NEGOTIATIONS_HISTORY_SUCCESS = 'REQUEST_NEGOTIATIONS_HISTORY_SUCCESS'
export const REQUEST_NEGOTIATIONS_HISTORY_ERROR = 'REQUEST_NEGOTIATIONS_HISTORY_ERROR'
export const UPDATE_NEGOTIATION = 'UPDATE_NEGOTIATION'
export const HANDLE_NEGOTIATIONS_HISTORY_VALUE_CHANGE = 'HANDLE_NEGOTIATIONS_HISTORY_VALUE_CHANGE'
export const CLEAR_NEGOTIATIONS_HISTORY = 'CLEAR_NEGOTIATIONS_HISTORY'

//---------------------- Harvest
export const REQUEST_HARVESTS = 'REQUEST_HARVESTS'
export const REQUEST_HARVESTS_SUCCESS = 'REQUEST_HARVESTS_SUCCESS'
export const REQUEST_HARVESTS_ERROR = 'REQUEST_HARVESTS_ERROR'

export const REQUEST_DELETE_HARVEST = 'REQUEST_DELETE_HARVEST'
export const REQUEST_DELETE_HARVEST_SUCCESS = 'REQUEST_DELETE_HARVEST_SUCCESS'
export const REQUEST_DELETE_HARVEST_ERROR = 'REQUEST_DELETE_HARVEST_ERROR'

export const REQUEST_HARVEST_OPTIONS = 'REQUEST_HARVEST_OPTIONS'
export const REQUEST_HARVEST_OPTIONS_SUCCESS = 'REQUEST_HARVEST_OPTIONS_SUCCESS'
export const REQUEST_HARVEST_OPTIONS_ERROR = 'REQUEST_HARVEST_OPTIONS_ERROR'

//---------------------- Harvest Form
export const REQUEST_SAVE_HARVEST = 'REQUEST_SAVE_HARVEST'
export const REQUEST_SAVE_HARVEST_SUCCESS = 'REQUEST_SAVE_HARVEST_SUCCESS'
export const REQUEST_SAVE_HARVEST_ERROR = 'REQUEST_SAVE_HARVEST_ERROR'
export const CLEAR_HARVEST_FORM = 'CLEAR_HARVEST_FORM'

//---------------------- Port Terminal
export const REQUEST_PORT_TERMINALS = 'REQUEST_PORT_TERMINALS'
export const REQUEST_PORT_TERMINALS_SUCCESS = 'REQUEST_PORT_TERMINALS_SUCCESS'
export const REQUEST_PORT_TERMINALS_ERROR = 'REQUEST_PORT_TERMINALS_ERROR'
export const REQUEST_PORT_TERMINALS_OPTIONS = 'REQUEST_PORT_TERMINALS_OPTIONS'
export const REQUEST_PORT_TERMINALS_OPTIONS_SUCCESS = 'REQUEST_PORT_TERMINALS_OPTIONS_SUCCESS'
export const REQUEST_PORT_TERMINALS_OPTIONS_ERROR = 'REQUEST_PORT_TERMINALS_OPTIONS_ERROR'
export const HANDLE_PORT_TERMINALS_VALUE_CHANGE = 'HANDLE_PORT_TERMINALS_VALUE_CHANGE'

//---------------------- Port Terminal Details
export const REQUEST_PORT_TERMINAL_DETAILS = 'REQUEST_PORT_TERMINAL_DETAILS'
export const REQUEST_PORT_TERMINAL_DETAILS_SUCCESS = 'REQUEST_PORT_TERMINAL_DETAILS_SUCCESS'
export const REQUEST_PORT_TERMINAL_DETAILS_ERROR = 'REQUEST_PORT_TERMINAL_DETAILS_ERROR'
export const REQUEST_PORT_TERMINAL_STATUS_TOGGLE = 'REQUEST_PORT_TERMINAL_STATUS_TOGGLE'
export const REQUEST_PORT_TERMINAL_STATUS_TOGGLE_SUCCESS = 'REQUEST_PORT_TERMINAL_STATUS_TOGGLE_SUCCESS'
export const REQUEST_PORT_TERMINAL_STATUS_TOGGLE_ERROR = 'REQUEST_PORT_TERMINAL_STATUS_TOGGLE_ERROR'
export const CLEAR_PORT_TERMINAL_DETAILS = 'CLEAR_PORT_TERMINAL_DETAILS'

//---------------------- Port Terminal Form
export const REQUEST_SAVE_PORT_TERMINAL_FORM = 'REQUEST_SAVE_PORT_TERMINAL_FORM'
export const REQUEST_SAVE_PORT_TERMINAL_FORM_SUCCESS = 'REQUEST_SAVE_PORT_TERMINAL_FORM_SUCCESS'
export const REQUEST_SAVE_PORT_TERMINAL_FORM_ERROR = 'REQUEST_SAVE_PORT_TERMINAL_FORM_ERROR'
export const CLEAR_PORT_TERMINAL_FORM = 'CLEAR_PORT_TERMINAL_FORM'

//---------------------- Edit Port Terminal Form
export const REQUEST_EDIT_PORT_TERMINAL = 'REQUEST_EDIT_PORT_TERMINAL'
export const REQUEST_EDIT_PORT_TERMINAL_SUCCESS = 'REQUEST_EDIT_PORT_TERMINAL_SUCCESS'
export const REQUEST_EDIT_PORT_TERMINAL_ERROR = 'REQUEST_EDIT_PORT_TERMINAL_ERROR'
export const REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS = 'REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS'
export const REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_SUCCESS = 'REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_SUCCESS'
export const REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_ERROR = 'REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_ERROR'
export const HANDLE_EDIT_PORT_TERMINAL_FORM_VALUE_CHANGE = 'HANDLE_EDIT_PORT_TERMINAL_FORM_VALUE_CHANGE'
export const CLEAR_EDIT_PORT_TERMINAL_FORM = 'CLEAR_EDIT_PORT_TERMINAL_FORM'

//---------------------- Shipping Agreement Type
export const REQUEST_SHIPPING_AGREEMENT_TYPES = 'REQUEST_SHIPPING_AGREEMENT_TYPES'
export const REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS = 'REQUEST_SHIPPING_AGREEMENT_TYPES_SUCCESS'
export const REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR = 'REQUEST_SHIPPING_AGREEMENT_TYPES_ERROR'

//---------------------- Freight
export const REQUEST_FREIGHTS = 'REQUEST_FREIGHTS'
export const REQUEST_FREIGHTS_SUCCESS = 'REQUEST_FREIGHTS_SUCCESS'
export const REQUEST_FREIGHTS_ERROR = 'REQUEST_FREIGHTS_ERROR'

//---------------------- CBOT
export const REQUEST_CBOTS = 'REQUEST_CBOTS'
export const REQUEST_CBOTS_SUCCESS = 'REQUEST_CBOTS_SUCCESS'
export const REQUEST_CBOTS_ERROR = 'REQUEST_CBOTS_ERROR'
export const REQUEST_DELETE_CBOT = 'REQUEST_DELETE_CBOT'
export const REQUEST_DELETE_CBOT_SUCCESS = 'REQUEST_DELETE_CBOT_SUCCESS'
export const REQUEST_DELETE_CBOT_ERROR = 'REQUEST_DELETE_CBOT_ERROR'

//---------------------- CBOT Form
export const REQUEST_SAVE_CBOT = 'REQUEST_SAVE_CBOT'
export const REQUEST_SAVE_CBOT_SUCCESS = 'REQUEST_SAVE_CBOT_SUCCESS'
export const REQUEST_SAVE_CBOT_ERROR = 'REQUEST_SAVE_CBOT_ERROR'
export const CLEAR_CBOT_FORM = 'CLEAR_CBOT_FORM'

//---------------------- Market Hours
export const REQUEST_MARKET_HOURS = 'REQUEST_MARKET_HOURS'
export const REQUEST_MARKET_HOURS_SUCCESS = 'REQUEST_MARKET_HOURS_SUCCESS'
export const REQUEST_MARKET_HOURS_ERROR = 'REQUEST_MARKET_HOURS_ERROR'

export const REQUEST_UPDATE_MARKET_HOURS = 'REQUEST_UPDATE_MARKET_HOURS'
export const REQUEST_UPDATE_MARKET_HOURS_SUCCESS = 'REQUEST_UPDATE_MARKET_HOURS_SUCCESS'
export const REQUEST_UPDATE_MARKET_HOURS_ERROR = 'REQUEST_UPDATE_MARKET_HOURS_ERROR'

export const REQUEST_HOURS_CHANGE = 'REQUEST_HOURS_CHANGE'

//---------------------- SignalR
export const REQUEST_SIGNALR_PRICE_CONNECTION = 'REQUEST_SIGNALR_PRICE_CONNECTION'
export const REQUEST_SIGNALR_PRICE_CONNECTION_SUCCESS = 'REQUEST_SIGNALR_PRICE_CONNECTION_SUCCESS'
export const REQUEST_SIGNALR_PRICE_CONNECTION_ERROR = 'REQUEST_SIGNALR_PRICE_CONNECTION_ERROR'

export const CLOSE_SIGNALR_PRICE_CONNECTION = 'CLOSE_SIGNALR_PRICE_CONNECTION'
export const CLOSE_SIGNALR_PRICE_CONNECTION_SUCCESS = 'CLOSE_SIGNALR_PRICE_CONNECTION_SUCCESS'
export const CLOSE_SIGNALR_PRICE_CONNECTION_ERROR = 'CLOSE_SIGNALR_PRICE_CONNECTION_ERROR'

export const SUBSCRIBE_COTATIONS = 'SUBSCRIBE_COTATIONS'
export const SUBSCRIBE_COTATIONS_SUCCESS = 'SUBSCRIBE_COTATIONS_SUCCESS'
export const SUBSCRIBE_COTATIONS_ERROR = 'SUBSCRIBE_COTATIONS_ERROR'

export const SUBSCRIBE_EXCHANGES = 'SUBSCRIBE_EXCHANGES'
export const SUBSCRIBE_EXCHANGES_SUCCESS = 'SUBSCRIBE_EXCHANGES_SUCCESS'
export const SUBSCRIBE_EXCHANGES_ERROR = 'SUBSCRIBE_EXCHANGES_ERROR'

export const UNSUBSCRIBE_ALL = 'UNSUBSCRIBE_ALL'
export const UNSUBSCRIBE_ALL_SUCCESS = 'UNSUBSCRIBE_ALL_SUCCESS'
export const UNSUBSCRIBE_ALL_ERROR = 'UNSUBSCRIBE_ALL_ERROR'

export const SIGNALR_INSTRUMENTATION_UPDATE = 'SIGNALR_INSTRUMENTATION_UPDATE'
export const SET_EXCHANGES_INFO = 'SET_EXCHANGES_INFO'

export const REQUEST_SIGNALR_NOTIFICATION_CONNECTION = 'REQUEST_SIGNALR_NOTIFICATION_CONNECTION'
export const REQUEST_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS = 'REQUEST_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS'
export const REQUEST_SIGNALR_NOTIFICATION_CONNECTION_ERROR = 'REQUEST_SIGNALR_NOTIFICATION_CONNECTION_ERROR'

export const CLOSE_SIGNALR_NOTIFICATION_CONNECTION = 'CLOSE_SIGNALR_NOTIFICATION_CONNECTION'
export const CLOSE_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS = 'CLOSE_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS'
export const CLOSE_SIGNALR_NOTIFICATION_CONNECTION_ERROR = 'CLOSE_SIGNALR_NOTIFICATION_CONNECTION_ERROR'

export const HANDLE_SIGNALR_VALUE_CHANGE = 'HANDLE_SIGNALR_VALUE_CHANGE'

//---------------------- Offer Form
export const REQUEST_SAVE_OFFER_FORM = 'REQUEST_SAVE_OFFER_FORM'
export const REQUEST_SAVE_OFFER_FORM_SUCCESS = 'REQUEST_SAVE_OFFER_FORM_SUCCESS'
export const REQUEST_SAVE_OFFER_FORM_ERROR = 'REQUEST_SAVE_OFFER_FORM_ERROR'
export const HANDLE_OFFER_FORM_VALUE_CHANGE = 'HANDLE_OFFER_FORM_VALUE_CHANGE'
export const CLEAR_OFFER_FORM = 'CLEAR_OFFER_FORM'

//---------------------- Offer

export const REQUEST_TODAY_OFFERS = 'REQUEST_TODAY_OFFERS'
export const REQUEST_TODAY_OFFERS_SUCCESS = 'REQUEST_TODAY_OFFERS_SUCCESS'
export const REQUEST_TODAY_OFFERS_ERROR = 'REQUEST_TODAY_OFFERS_ERROR'

export const REQUEST_OFFER = 'REQUEST_OFFER'
export const REQUEST_OFFER_SUCCESS = 'REQUEST_OFFER_SUCCESS'
export const REQUEST_OFFER_ERROR = 'REQUEST_OFFER_ERROR'

export const REQUEST_CANCEL_OFFER = 'REQUEST_CANCEL_OFFER'
export const REQUEST_CANCEL_OFFER_SUCCESS = 'REQUEST_CANCEL_OFFER_SUCCESS'
export const REQUEST_CANCEL_OFFER_ERROR = 'REQUEST_CANCEL_OFFER_ERROR'

export const HANDLE_OFFER_VALUE_CHANGE = 'HANDLE_OFFER_VALUE_CHANGE'
