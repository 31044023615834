import BaseService from './base.service'

class UserService extends BaseService {
  constructor() {
    super('User');
  }

  toggleStatus(id) {
    return this.post({}, `/${id}/ActiveToggle`)
  }
}

export default UserService;
