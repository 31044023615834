import * as types from '../actionTypes'
import { PortTerminalService, alertService } from '../../Services'
import { redirectUtils, cpfCnpjUtils } from '../../Utils'

const portTerminalService = new PortTerminalService();

export const requestEditPortTerminal = () => ({
    type: types.REQUEST_EDIT_PORT_TERMINAL,
    payload: null
});

export const requestEditPortTerminalSuccess = portTerminal => ({
    type: types.REQUEST_EDIT_PORT_TERMINAL_SUCCESS,
    payload: portTerminal
});

export const requestEditPortTerminalError = error => ({
    type: types.REQUEST_EDIT_PORT_TERMINAL_ERROR,
    payload: error
});

export const handleEdit = portTerminalForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(portTerminalForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestEditPortTerminal());

    portTerminalService.saveOrUpdate({...portTerminalForm})
        .then(response => {
            dispatch(requestEditPortTerminalSuccess())
            redirectUtils.redirectTo(`#/terminais/detalhe/${response.data.id}`)
            alertService.success('Terminal portuário editado com sucesso!')
        })
        .catch(error => {
            const data = { ...portTerminalForm }
            dispatch(requestEditPortTerminalError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao editar terminal portuário')
        })
}

const checkRequiredFields = ptf => {
    let alerts = []
    let isInvalid = false

    if (ptf.legalName.length === 0) {
        isInvalid = true
    }
    if (!cpfCnpjUtils.isValid(ptf.cpfcnpj)) {
        alerts.push('Campo CPF/CNPJ inválido!')
        isInvalid = true
    }
    if (ptf.federalRegistration.length === 0) {
        isInvalid = true
    }
    if (ptf.cep.length !== 8) {
        alerts.push('CEP inválido!')
        isInvalid = true
    }
    if (ptf.address.length === 0) {
        isInvalid = true
    }
    if (ptf.cityId === '') {
        isInvalid = true
    }

    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const requestPortTerminalDetails = () => ({
    type: types.REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS,
    payload: null
});

export const requestPortTerminalDetailsSuccess = details => ({
    type: types.REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_SUCCESS,
    payload: details
})

export const requestPortTerminalDetailsError = error => ({
    type: types.REQUEST_EDIT_FORM_PORT_TERMINAL_DETAILS_ERROR,
    payload: error
});

export const fetchPortTerminal = id => dispatch => {
    dispatch(requestPortTerminalDetails());

    portTerminalService.getById(id)
        .then(response => {
            if (response.data) {
                dispatch(requestPortTerminalDetailsSuccess({ ...response.data }));
            }
        })
        .catch(error => {
            dispatch(requestPortTerminalDetailsError(error));
            alertService.error(error, 'Erro ao buscar detalhes do terminal portuário');
            redirectUtils.redirectTo(`#/terminais/detalhe/${id}`)
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_EDIT_PORT_TERMINAL_FORM_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_EDIT_PORT_TERMINAL_FORM,
    payload: null
});
