import initialState from './initialState';
import {
    REQUEST_SAVE_CBOT,
    REQUEST_SAVE_CBOT_SUCCESS,
    REQUEST_SAVE_CBOT_ERROR,
    CLEAR_CBOT_FORM
} from '../Actions/actionTypes';


const requestSaveCBOT = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSaveCBOTSuccess = (state, action) => {
    return initialState.cbotForm;
};

const requestSaveCBOTError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clearCBOTForm = (state, action) => {
    return initialState.cbotForm
}

const actions = {
    [REQUEST_SAVE_CBOT]: requestSaveCBOT,
    [REQUEST_SAVE_CBOT_SUCCESS]: requestSaveCBOTSuccess,
    [REQUEST_SAVE_CBOT_ERROR]: requestSaveCBOTError,
    [CLEAR_CBOT_FORM]: clearCBOTForm
};

export const cbotForm = (state = initialState.cbotForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
