import { MarketExchangeService, alertService } from '../Services'
import { dateUtils } from '../Utils'
import * as types from './actionTypes'

const marketExchangeService = new MarketExchangeService();

export const requestMarketHours = () => ({
    type: types.REQUEST_MARKET_HOURS,
    payload: null
});

export const requestMarketHoursSuccess = marketHours => ({
    type: types.REQUEST_MARKET_HOURS_SUCCESS,
    payload: marketHours
});

export const requestMarketHoursError = error => ({
    type: types.REQUEST_MARKET_HOURS_ERROR,
    payload: error
});

export const fetchMarketHours = () => dispatch => {
    dispatch(requestMarketHours());
    marketExchangeService.findAll()
        .then(response => {
            if (response.data) {
                const markets = response.data.map(market => {
                    return {
                        ...market,
                        opensOn: dateUtils.formatUTC(market.opensOn, 'HH:mm', 'HH:mm'),
                        closesOn: dateUtils.formatUTC(market.closesOn, 'HH:mm', 'HH:mm')
                    }
                })
                dispatch(requestMarketHoursSuccess(markets));
            }
        })
        .catch(error => {
            dispatch(requestMarketHoursError(error));
            alertService.error(error, 'Erro ao buscar horários do mercado');
        });
};

export const requestUpdateMarketHours = () => ({
    type: types.REQUEST_UPDATE_MARKET_HOURS,
    payload: null
});

export const requestUpdateMarketHoursSuccess = user => ({
    type: types.REQUEST_UPDATE_MARKET_HOURS_SUCCESS,
    payload: user
});

export const requestUpdateMarketHoursError = error => ({
    type: types.REQUEST_UPDATE_MARKET_HOURS_ERROR,
    payload: error
});

export const handleUpdate = marketHours => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(marketHours)

    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestUpdateMarketHours());
    
    const newMarketHours = marketHours.map(market => {
        return {
            id: market.id,
            opensOn: dateUtils.formatToUTC(market.opensOn, 'HH:mm:ss', 'HH:mm'),
            closesOn: dateUtils.formatToUTC(market.closesOn, 'HH:mm:ss', 'HH:mm'),
        }
    })

    marketExchangeService.updateMarketHours(newMarketHours)
        .then(response => {
            if (response.data) {
                const markets = response.data.map(market => {
                    return {
                        ...market,
                        opensOn: dateUtils.formatUTC(market.opensOn, 'HH:mm', 'HH:mm'),
                        closesOn: dateUtils.formatUTC(market.closesOn, 'HH:mm', 'HH:mm')
                    }
                })
                dispatch(requestUpdateMarketHoursSuccess(markets))
                alertService.success('Horários atualizados com sucesso!')
            }
        })
        .catch(error => {
            dispatch(requestUpdateMarketHoursError({ data: marketHours, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao atualizar horários')
        })
}

const checkRequiredFields = marketHours => {
    let alerts = []
    let isInvalid = false

    marketHours.forEach(market => {
        if (!dateUtils.isAfter(market.opensOn, market.closesOn, 'HH:mm')) {
            alerts.push(`Horário final deve ser após inicial para ${market.description}!`)
            isInvalid = true
        }
    });

    return [isInvalid, alerts]
}

export const handleHoursChange = (id, edition) => ({
    type: types.REQUEST_HOURS_CHANGE,
    payload: { id, edition }
});