import initialState from '../initialState'
import {
    REQUEST_USERS,
    REQUEST_USERS_SUCCESS,
    REQUEST_USERS_ERROR,
    HANDLE_USERS_VALUE_CHANGE,
    UPDATE_USERS_LIST,
    CLEAR_USERS
} from '../../Actions/actionTypes'

const requestUsers = (state, action) => {
    return { ...state, isFetching: true };
};

const requestUsersSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestUsersError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};

const updateUsersList = (state, action) => {
    return {
        ...state,
        entities: [action.payload, ...state.entities]
    }
}

const clear = (state, action) => {
    return initialState.users;
};


const actions = {
    [REQUEST_USERS]: requestUsers,
    [REQUEST_USERS_SUCCESS]: requestUsersSuccess,
    [REQUEST_USERS_ERROR]: requestUsersError,
    [HANDLE_USERS_VALUE_CHANGE]: handleValueChange,
    [UPDATE_USERS_LIST]: updateUsersList,
    [CLEAR_USERS]: clear
};

export const users = (state = initialState.users, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
