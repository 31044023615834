import * as types from './actionTypes'
import { HarvestService, alertService } from '../Services'
import { harvestActions } from './'

const harvestService = new HarvestService();

export const requestSaveHarvest = () => ({
    type: types.REQUEST_SAVE_HARVEST,
    payload: null
});

export const requestSaveHarvestSuccess = harvest => ({
    type: types.REQUEST_SAVE_HARVEST_SUCCESS,
    payload: harvest
});

export const requestSaveHarvestError = error => ({
    type: types.REQUEST_SAVE_HARVEST_ERROR,
    payload: error
});

export const handleSave = (harvestForm, clearForm) => dispatch => {
    dispatch(requestSaveHarvest());
    harvestService
        .saveOrUpdate(harvestForm)
        .then(() => {
            dispatch(requestSaveHarvestSuccess())
            dispatch(harvestActions.fetchHarvests())
            clearForm()
            alertService.success('Safra criada com sucesso!')
        })
        .catch(error => {
            const data = { ...harvestForm }
            dispatch(requestSaveHarvestError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar safra')
        })
}

export const clear = () => ({
    type: types.CLEAR_HARVEST_FORM,
    payload: null
});
