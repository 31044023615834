import * as types from '../actionTypes'
import { alertService, UserService } from '../../Services'
import { cpfCnpjUtils, redirectUtils, dateUtils } from '../../Utils'
import { userActions } from '../index'
const userService = new UserService()

export const requestSaveUserForm = () => ({
    type: types.REQUEST_SAVE_USER_FORM,
    payload: null
});

export const requestSaveUserFormSuccess = user => ({
    type: types.REQUEST_SAVE_USER_FORM_SUCCESS,
    payload: user
});

export const requestSaveUserFormError = error => ({
    type: types.REQUEST_SAVE_USER_FORM_ERROR,
    payload: error
});

export const handleSave = userForm => dispatch => {

    const [isInvalid, alerts] = checkRequiredFields(userForm)
    if (isInvalid) {
        alerts.forEach(alert => alertService.info(alert))
        return
    }

    dispatch(requestSaveUserForm());

    const newUser = {
        ...userForm,
        bornOn: dateUtils.format(userForm.bornOn, 'YYYY-MM-DD', 'DD/MM/YYYY')
    }

    userService.saveOrUpdate(newUser)
        .then(response => {
            dispatch(requestSaveUserFormSuccess())
            dispatch(userActions.updateUsersList(response.data))
            redirectUtils.redirectTo('#/usuarios')
            alertService.success('Usuário criado com sucesso!')
        })
        .catch(error => {
            dispatch(requestSaveUserFormError({ data: userForm, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar usuário')
        })
}

const checkRequiredFields = uf => {
    let alerts = []
    let isInvalid = false
    
    if (uf.name === '') {
        isInvalid = true
    }

    if (!cpfCnpjUtils.isValid(uf.cpf)) {
        alerts.push('Campo CPF inválido!')
        isInvalid = true
    }
    if (uf.email.length === 0) {
        isInvalid = true
    }
    if (uf.phoneNumber.length === 0) {
        isInvalid = true
    }
    if (uf.clientId === '') {
        isInvalid = true
    }
    if (!dateUtils.isValid(uf.bornOn)) {
        alerts.push('Campo data de nascimento inválido!')
        isInvalid = true
    }

    if (isInvalid && alerts.length === 0) {
        alerts.push('Campos obrigatórios não preenchidos!')
    }

    return [isInvalid, alerts]
}

export const clear = () => ({
    type: types.CLEAR_USER_FORM,
    payload: null
});
