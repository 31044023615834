import BaseService from './base.service'

class MarketExchangeService extends BaseService {
  constructor() {
    super('MarketExchange');
  }

  updateMarketHours(hours) {
    return this.put(hours, '/UpdateMarketHours')
  }
}

export default MarketExchangeService;
