import BaseService from './base.service'

class ClientService extends BaseService {
  constructor() {
    super('Client');
  }

  toggleStatus(id) {
    return this.post({}, `/${id}/ActiveToggle`)
  }
}

export default ClientService;
