import { useState } from 'react'

const useForm = (initialValues, callback) => {

    const [values, setValues] = useState(initialValues);

    const handleSubmit = event => {
        if (event) event.preventDefault();
        callback();
    };

    const clear = () => {
        setValues({ ...initialValues })
    }

    const handleChange = event => {
        if (event.persist) event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };

    return {
        handleChange,
        handleSubmit,
        values,
        clear
    }
};

export default useForm;