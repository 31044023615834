export const status = {
  New: 1,
  Approved: 2,
  Rejected: 3,
  Active: 4,
  Inactive: 5,
};
export const statusOptions = [
  {
    value: 1,
    label: "Novo",
  },
  {
    value: 3,
    label: "Rejeitado",
  },
  {
    value: 4,
    label: "Ativo",
  },
  {
    value: 5,
    label: "Inativo",
  },
];

export const offerStates = {
  New: {
    id: 0,
    emptyMessage: "novas ofertas",
    tabName: "Novas Ofertas",
    name: "new",
  },
  Negotiation: {
    id: 1,
    emptyMessage: "ofertas em negociação",
    tabName: "Em negociação",
    name: "negotiation",
  },
  Approved: {
    id: 2,
    emptyMessage: "ofertas aprovadas",
    tabName: "Aprovadas",
    name: "approved",
  },
  Rejected: { id: 3, emptyMessage: "ofertas rejeitadas", tabName: "" },
  Consolidated: { id: 4, emptyMessage: "ofertas consolidadas", tabName: "" },
};

export const productsInfo = {
  Soja: { name: "soy", fillColor: "#f5851f", colorClass: "green" },
  "Trigo - PH 78": {
    name: "wheat",
    fillColor: "#f5851f",
    colorClass: "orange",
  },
  "Trigo - PH 72": {
    name: "wheat",
    fillColor: "#f5851f",
    colorClass: "orange",
  },
  "Trigo - PH 76": {
    name: "wheat",
    fillColor: "#f5851f",
    colorClass: "orange",
  },
  Trigo: { name: "wheat", fillColor: "#f5851f", colorClass: "orange" },
  Milho: { name: "corn", fillColor: "#f5851f", colorClass: "yellow" },
};

export const productColor = {
  Soja: "#f5851f",
  Trigo: "#f5851f",
  Milho: "#f5851f",
};

export const monthOptions = [
  { value: "jan", label: "Janeiro" },
  { value: "fev", label: "Fevereiro" },
  { value: "mar", label: "Março" },
  { value: "abr", label: "Abril" },
  { value: "mai", label: "Maio" },
  { value: "jun", label: "Junho" },
  { value: "jul", label: "Julho" },
  { value: "ago", label: "Agosto" },
  { value: "set", label: "Setembro" },
  { value: "out", label: "Outubro" },
  { value: "nov", label: "Novembro" },
  { value: "dez", label: "Dezembro" },
];

export const orderByOptions = [
  { value: 1, label: "Expiração" },
  { value: 2, label: "Maior valor" },
  { value: 3, label: "Maior quantidade" },
];

export const userDataStorageConstants = {
  KeepAlive: "kcBrickWeb",
  AuthToken: "authBrickWeb",
};
