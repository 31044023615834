import initialState from '../initialState'
import {
    REQUEST_PORT_TERMINALS,
    REQUEST_PORT_TERMINALS_SUCCESS,
    REQUEST_PORT_TERMINALS_ERROR,
    REQUEST_PORT_TERMINALS_OPTIONS,
    REQUEST_PORT_TERMINALS_OPTIONS_SUCCESS,
    REQUEST_PORT_TERMINALS_OPTIONS_ERROR,
    HANDLE_PORT_TERMINALS_VALUE_CHANGE
} from '../../Actions/actionTypes'

const requestPortTerminals = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPortTerminalsSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        isFetching: false
    };
};

const requestPortTerminalsError = (state, action) => {
    return { ...state, isFetching: false };
};

const requestPortTerminalsOptions = (state, action) => {
    return { ...state, isFetching: true };
};

const requestPortTerminalsOptionsSuccess = (state, action) => {
    return {
        ...state,
        options: action.payload,
        isFetching: false
    };
};

const requestPortTerminalsOptionsError = (state, action) => {
    return { ...state, isFetching: false };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload };
};


const actions = {
    [REQUEST_PORT_TERMINALS]: requestPortTerminals,
    [REQUEST_PORT_TERMINALS_SUCCESS]: requestPortTerminalsSuccess,
    [REQUEST_PORT_TERMINALS_ERROR]: requestPortTerminalsError,
    [REQUEST_PORT_TERMINALS_OPTIONS]: requestPortTerminalsOptions,
    [REQUEST_PORT_TERMINALS_OPTIONS_SUCCESS]: requestPortTerminalsOptionsSuccess,
    [REQUEST_PORT_TERMINALS_OPTIONS_ERROR]: requestPortTerminalsOptionsError,
    [HANDLE_PORT_TERMINALS_VALUE_CHANGE]: handleValueChange
};

export const portTerminals = (state = initialState.portTerminals, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
