import React, { Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom' // Redirect
import * as router from 'react-router-dom'
import { Container } from 'reactstrap'
import { signalRActions } from '../../Actions'
import Dashboard from '../../Modules/Dashboard/dashboard.container'

import {
  AppHeader,
  AppSidebar,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react'

import { routes, navigation } from '../../Services'
import { localStorageUtils } from '../../Utils'

const DefaultHeader = React.lazy(() => import('./defaultHeader'));

export default function Home(props) {
  const dispatch = useDispatch()
  const signalR = useSelector(state => state.signalR)

  useEffect(() => {
    if (!signalR.isPriceConnected && !signalR.isPriceConnecting)
      dispatch(signalRActions.requestPriceConnection())
  }, [dispatch, signalR.isPriceConnected, signalR.isPriceConnecting])

  useEffect(() => {
    if (!signalR.isNotificationConnected && !signalR.isNotificationConnecting)
      dispatch(signalRActions.requestNotificationConnection())
  }, [dispatch, signalR.isNotificationConnected, signalR.isNotificationConnecting])

  useEffect(() => {
    return () => {
      dispatch(signalRActions.closePriceConnection())
      dispatch(signalRActions.closeNotificationConnection())
    }
  }, [dispatch])

  const loading = () => <div className='animated fadeIn pt-1 text-center'>Loading...</div>

  const signOut = e => {
    e.preventDefault()
    localStorageUtils.removeAuthToken();
    props.history.push('#/login')
  }

  return (
    <div className='app'>
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader onLogout={e => signOut(e)} />
        </Suspense>
      </AppHeader>
      <div className='app-body'>
        <AppSidebar fixed display='lg'>
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav navConfig={navigation} {...props} router={router} />
          </Suspense>
        </AppSidebar>
        <main className='main'>
          <Container fluid className='px-5 py-4 h-100'>
            <Suspense fallback={loading}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        <route.component {...props} />
                      )} />
                  ) : (null);
                })}
                <Dashboard />
              </Switch>
            </Suspense>
          </Container>
        </main>
      </div>
    </div>
  );
}
