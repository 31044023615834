import * as types from './actionTypes'
import { CBOTService, alertService } from '../Services'
import { cbotActions } from './'
import { dateUtils } from '../Utils'

const cbotService = new CBOTService();

export const requestSaveCBOT = () => ({
    type: types.REQUEST_SAVE_CBOT,
    payload: null
});

export const requestSaveCBOTSuccess = cbot => ({
    type: types.REQUEST_SAVE_CBOT_SUCCESS,
    payload: cbot
});

export const requestSaveCBOTError = error => ({
    type: types.REQUEST_SAVE_CBOT_ERROR,
    payload: error
});

export const handleSave = (cbotForm, clearForm) => dispatch => {
    dispatch(requestSaveCBOT());

    const newCBOT = {
        productId: cbotForm.productId,
        start: dateUtils.format(cbotForm.start, 'YYYY-MM-DD'),
        end: dateUtils.format(cbotForm.end, 'YYYY-MM-DD'),
        cbotMonth: `${cbotForm.month}-${cbotForm.year.slice(-2)}`
    }

    cbotService
        .saveOrUpdate(newCBOT)
        .then(() => {
            dispatch(requestSaveCBOTSuccess())
            dispatch(cbotActions.fetchCBOTs())
            clearForm()
            alertService.success('Cotação criada com sucesso!')
        })
        .catch(error => {
            const data = { ...cbotForm }
            dispatch(requestSaveCBOTError({ data, error }))
            const { response: { data: { message } } } = error
            alertService.error(error, message || 'Erro ao criar cotação')
        })
}

export const clear = () => ({
    type: types.CLEAR_CBOT_FORM,
    payload: null
});
