import initialState from '../initialState'
import {
    REQUEST_SAVE_PRICE_FORM,
    REQUEST_SAVE_PRICE_FORM_SUCCESS,
    REQUEST_SAVE_PRICE_FORM_ERROR,
    CLEAR_PRICE_FORM,
    HANDLE_PRICE_FORM_VALUE_CHANGE,
    REQUEST_PRICE_REWARD,
    REQUEST_PRICE_REWARD_SUCCESS,
    REQUEST_PRICE_REWARD_ERROR
} from '../../Actions/actionTypes'


const requestSavePriceForm = (state, action) => {
    return { ...state, isSaving: true };
};

const requestSavePriceFormSuccess = (state, action) => {
    return initialState.priceForm;
};

const requestSavePriceFormError = (state, action) => {
    return { ...state, isSaving: false, ...action.payload.data };
};

const clearPriceForm = (state, action) => {
    return initialState.priceForm
}

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const requestReward = (state, action) => {
    return { ...state, rewardData: { ...state.rewardData, isFetching: true } };
};

const requestRewardSuccess = (state, action) => {
    return {
        ...state,
        rewardData: {
            ...action.payload,
            rewardValue: action.payload.rewardValue.toFixed(2),
            isFetching: false
        }
    };
};

const requestRewardError = (state, action) => {
    return { ...state, rewardData: { ...state.rewardData, isFetching: false } };
};

const actions = {
    [REQUEST_SAVE_PRICE_FORM]: requestSavePriceForm,
    [REQUEST_SAVE_PRICE_FORM_SUCCESS]: requestSavePriceFormSuccess,
    [REQUEST_SAVE_PRICE_FORM_ERROR]: requestSavePriceFormError,
    [CLEAR_PRICE_FORM]: clearPriceForm,
    [HANDLE_PRICE_FORM_VALUE_CHANGE]: handleValueChange,
    [REQUEST_PRICE_REWARD]: requestReward,
    [REQUEST_PRICE_REWARD_SUCCESS]: requestRewardSuccess,
    [REQUEST_PRICE_REWARD_ERROR]: requestRewardError
};

export const priceForm = (state = initialState.priceForm, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
