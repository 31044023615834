import * as types from '../actionTypes'
import { PriceService, alertService } from '../../Services'
// import { offerStates } from '../../Utils'

const priceService = new PriceService();

export const requestPricesHistory = () => ({
    type: types.REQUEST_PRICES_HISTORY,
    payload: null
});

export const requestPricesHistorySuccess = prices => ({
    type: types.REQUEST_PRICES_HISTORY_SUCCESS,
    payload: prices
});

export const requestPricesHistoryError = error => ({
    type: types.REQUEST_PRICES_HISTORY_ERROR,
    payload: error
});

export const fetchPricesHistory = (options) => dispatch => {
    dispatch(requestPricesHistory());
    priceService.findAllHistory({...options}) //states: [offerStates.Approved.id]
    .then(response => {
            if (response.data) {
                dispatch(requestPricesHistorySuccess({ count: response.data.totalResults, entities: response.data.results }));
            }
        })
        .catch(error => {
            dispatch(requestPricesHistoryError(error));
            alertService.error(error, 'Erro ao buscar ofertas');
        });
};

export const handleValueChange = edition => ({
    type: types.HANDLE_PRICES_HISTORY_VALUE_CHANGE,
    payload: edition
});

export const clear = () => ({
    type: types.CLEAR_PRICES_HISTORY,
    payload: null
});
