import initialState from './initialState';
import { REQUEST_AUTH, REQUEST_AUTH_SUCCESS, REQUEST_AUTH_ERROR } from '../Actions/actionTypes';

const requestAuth = (state, action) => {
    return { ...state, isFetching: true };
};

const requestAuthSuccess = (state, action) => {
    return { ...state, isFetching: false };
};

const requestAuthError = (state, action) => {
    return { ...state, isFetching: false };
};

const actions = {
    [REQUEST_AUTH]: requestAuth,
    [REQUEST_AUTH_SUCCESS]: requestAuthSuccess,
    [REQUEST_AUTH_ERROR]: requestAuthError
};

export const auth = (state = initialState.auth, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
