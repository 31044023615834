import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { dateUtils } from '../../Utils'
import NumberFormat from 'react-number-format'

export default function DatePickerInput(props) {
    const [dateInput, setDateInput] = useState(props.value ? dateUtils.format(props.value) : '')
    const [focus, setFocus] = useState(false)

    const calendarChangeHandler = date => {
        props.onChange({ target: { name: props.name, value: date } })
    }

    const inputChangeHandler = e => {
        const dateFormat = props.dateFormat ? props.dateFormat.toUpperCase() : 'DD/MM/YYYY'
        if (e.value.length === (dateFormat.length - 2) && dateUtils.isValid(e.formattedValue, dateFormat))
            props.onChange({ target: { name: props.name, value: dateUtils.newDate(e.formattedValue, dateFormat).toDate() } })

        setDateInput(e.formattedValue)
    }

    const onBlur = () => {
        setFocus(false)
        if(props.suggested && !props.value) calendarChangeHandler(props.suggested)
    }

    return (
        <div className='d-flex date-picker'>
            <div className='input-group-prepend'>
                <div className='input-group-text'>
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ color: 'white' }} />
                </div>
            </div >
            <DatePicker
                onFocus={() => setFocus(true)}
                onBlur={onBlur}
                selected={props.value || (focus ? props.suggested : undefined)}
                locale={ptBR}
                onChange={date => calendarChangeHandler(date)}
                className='form-control'
                placeholderText={props.placeholder || 'DD/MM/AAAA'}
                dateFormat={props.dateFormat || 'dd/MM/yyyy'}
                minDate={props.minDate}
                maxDate={props.maxDate}
                disabled={props.disabled}
                customInput={
                    <NumberFormat
                        format={props.format || '##/##/####'}
                        mask='_'
                        onValueChange={e => inputChangeHandler(e)}
                        value={dateInput}
                    />}
            />
        </div>
    )
}
