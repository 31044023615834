import initialState from './initialState'
import {
    REQUEST_SIGNALR_PRICE_CONNECTION,
    REQUEST_SIGNALR_PRICE_CONNECTION_SUCCESS,
    REQUEST_SIGNALR_PRICE_CONNECTION_ERROR,
    SUBSCRIBE_COTATIONS,
    SUBSCRIBE_COTATIONS_SUCCESS,
    SUBSCRIBE_COTATIONS_ERROR,
    SUBSCRIBE_EXCHANGES,
    SUBSCRIBE_EXCHANGES_SUCCESS,
    SUBSCRIBE_EXCHANGES_ERROR,

    UNSUBSCRIBE_ALL,
    UNSUBSCRIBE_ALL_SUCCESS,
    UNSUBSCRIBE_ALL_ERROR,

    CLOSE_SIGNALR_PRICE_CONNECTION,
    CLOSE_SIGNALR_PRICE_CONNECTION_SUCCESS,
    CLOSE_SIGNALR_PRICE_CONNECTION_ERROR,
    SIGNALR_INSTRUMENTATION_UPDATE,
    SET_EXCHANGES_INFO,

    REQUEST_SIGNALR_NOTIFICATION_CONNECTION,
    REQUEST_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS,
    REQUEST_SIGNALR_NOTIFICATION_CONNECTION_ERROR,
    CLOSE_SIGNALR_NOTIFICATION_CONNECTION,
    CLOSE_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS,
    CLOSE_SIGNALR_NOTIFICATION_CONNECTION_ERROR,

    HANDLE_SIGNALR_VALUE_CHANGE
} from '../Actions/actionTypes'

const requestPriceConnection = (state, action) => {
    return { ...state, isPriceConnecting: true };
};

const requestPriceConnectionSuccess = (state, action) => {
    return {
        ...state,
        isPriceConnecting: false,
        isPriceConnected: true
    };
};

const requestPriceConnectionError = (state, action) => {
    return {
        ...state,
        isPriceConnecting: false,
        isPriceConnected: false
    };
};

const subscribeCotations = (state, action) => {
    return { ...state, [action.payload.controlVariable]: true }
}

const subscribeCotationsSuccess = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeCotationsError = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeExchanges = (state, action) => {
    return { ...state, [action.payload.controlVariable]: true }
}

const subscribeExchangesSuccess = (state, action) => {
    return { ...state, ...action.payload }
}

const subscribeExchangesError = (state, action) => {
    return { ...state, ...action.payload }
}

const closePriceConnection = (state, action) => {
    return initialState.signalR
}

const closePriceConnectionSuccess = (state, action) => {
    return initialState.signalR
}

const closePriceConnectionError = (state, action) => {
    return { ...state, ...action.payload }
}

const unsubscribeAll = (state, action) => {
    return { ...state }
}

const unsubscribeAllSuccess = (state, action) => {
    return {
        ...state,
        pricesCotationSubscribed: false,
        pricesExchangeSubscribed: false,
        indicationsCotationSubscribed: false,
        indicationsExchangeSubscribed: false,
        offersCotationSubscribed: false,
        offersExchangeSubscribed: false,
        currentDollarSubscribed: false
    }
}

const unsubscribeAllError = (state, action) => {
    return { ...state }
}

const instrumentationUpdate = (state, action) => {
    return { ...state, instrumentation: action.payload }
}

const setExchangesInfo = (state, action) => {
    return { ...state, exchanges: { ...state.exchanges, ...action.payload } };
};

// --------------------- Notification

const requestNotificationConnection = (state, action) => {
    return { ...state, isPriceConnecting: true };
};

const requestNotificationConnectionSuccess = (state, action) => {
    return {
        ...state,
        isNotificationConnecting: false,
        isNotificationConnected: true
    };
};

const requestNotificationConnectionError = (state, action) => {
    return {
        ...state,
        isNotificationConnecting: false,
        isNotificationConnected: false
    };
};

const closeNotificationConnection = (state, action) => {
    return initialState.signalR
}

const closeNotificationConnectionSuccess = (state, action) => {
    return initialState.signalR
}

const closeNotificationConnectionError = (state, action) => {
    return { ...state, ...action.payload }
}

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload }
}

const actions = {
    [REQUEST_SIGNALR_PRICE_CONNECTION]: requestPriceConnection,
    [REQUEST_SIGNALR_PRICE_CONNECTION_SUCCESS]: requestPriceConnectionSuccess,
    [REQUEST_SIGNALR_PRICE_CONNECTION_ERROR]: requestPriceConnectionError,
    [SUBSCRIBE_COTATIONS]: subscribeCotations,
    [SUBSCRIBE_COTATIONS_SUCCESS]: subscribeCotationsSuccess,
    [SUBSCRIBE_COTATIONS_ERROR]: subscribeCotationsError,
    [SUBSCRIBE_EXCHANGES]: subscribeExchanges,
    [SUBSCRIBE_EXCHANGES_SUCCESS]: subscribeExchangesSuccess,
    [SUBSCRIBE_EXCHANGES_ERROR]: subscribeExchangesError,
    [CLOSE_SIGNALR_PRICE_CONNECTION]: closePriceConnection,
    [CLOSE_SIGNALR_PRICE_CONNECTION_SUCCESS]: closePriceConnectionSuccess,
    [CLOSE_SIGNALR_PRICE_CONNECTION_ERROR]: closePriceConnectionError,

    [UNSUBSCRIBE_ALL]: unsubscribeAll,
    [UNSUBSCRIBE_ALL_SUCCESS]: unsubscribeAllSuccess,
    [UNSUBSCRIBE_ALL_ERROR]: unsubscribeAllError,

    [SIGNALR_INSTRUMENTATION_UPDATE]: instrumentationUpdate,
    [SET_EXCHANGES_INFO]: setExchangesInfo,

    [REQUEST_SIGNALR_NOTIFICATION_CONNECTION]: requestNotificationConnection,
    [REQUEST_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS]: requestNotificationConnectionSuccess,
    [REQUEST_SIGNALR_NOTIFICATION_CONNECTION_ERROR]: requestNotificationConnectionError,
    [CLOSE_SIGNALR_NOTIFICATION_CONNECTION]: closeNotificationConnection,
    [CLOSE_SIGNALR_NOTIFICATION_CONNECTION_SUCCESS]: closeNotificationConnectionSuccess,
    [CLOSE_SIGNALR_NOTIFICATION_CONNECTION_ERROR]: closeNotificationConnectionError,

    [HANDLE_SIGNALR_VALUE_CHANGE]: handleValueChange
};

export const signalR = (state = initialState.signalR, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
