import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import Home from '../Home/home.page';

// Import routing components
import { Switch, Route, HashRouter } from 'react-router-dom';
import { PrivateRoute } from '../../Components';
import LoginContainer from '../Login/login.container';

class Root extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path='/login' name='Login Page' component={LoginContainer} />
            <PrivateRoute
              path='/'
              name='Home Page'
              component={Home}
            />
          </Switch>
        </HashRouter>
        <ToastContainer
          newestOnTop
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          pauseOnVisibilityChange
        />
      </>
    );
  }
}

export default Root;
