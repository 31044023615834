import React from 'react'
import Switch from 'rc-switch'
import 'rc-switch/assets/index.css'

export default function SwitchInput(props) {

    function handleClick(value) {
        const event = { target: { name: props.name, value } }
        props.onToggle(event)
    }

    return (
        <Switch
            name={props.name}
            onClick={value => handleClick(value)}
            checked={props.checked}
            disabled={props.disabled}
        />

    )
}
