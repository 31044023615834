import initialState from '../initialState'
import {
    REQUEST_NEGOTIATIONS_HISTORY,
    REQUEST_NEGOTIATIONS_HISTORY_SUCCESS,
    REQUEST_NEGOTIATIONS_HISTORY_ERROR,
    UPDATE_NEGOTIATION,
    HANDLE_NEGOTIATIONS_HISTORY_VALUE_CHANGE,
    CLEAR_NEGOTIATIONS_HISTORY
} from '../../Actions/actionTypes'

const requestNegotiationsHistory = (state, action) => {
    return { ...state, isFetching: true };
};

const requestNegotiationsHistorySuccess = (state, action) => {
    return {
        ...state,
        entities: action.payload.entities,
        count: action.payload.count,
        isFetching: false
    };
};

const requestNegotiationsHistoryError = (state, action) => {
    return { ...state, isFetching: false };
};

const updateNegotiation = (state, action) => {
    const entities = state.entities.map(negotiation => {
        if (negotiation.id === action.payload) {
            return {
                ...negotiation,
                isConsolidated: true
            }
        }
        return negotiation
    })
    return { ...state, entities };
};

const handleValueChange = (state, action) => {
    return { ...state, ...action.payload, };
};

const clear = (state, action) => {
    return initialState.negotiationsHistory;
};

const actions = {
    [REQUEST_NEGOTIATIONS_HISTORY]: requestNegotiationsHistory,
    [REQUEST_NEGOTIATIONS_HISTORY_SUCCESS]: requestNegotiationsHistorySuccess,
    [REQUEST_NEGOTIATIONS_HISTORY_ERROR]: requestNegotiationsHistoryError,
    [UPDATE_NEGOTIATION]: updateNegotiation,
    [HANDLE_NEGOTIATIONS_HISTORY_VALUE_CHANGE]: handleValueChange,
    [CLEAR_NEGOTIATIONS_HISTORY]: clear
};

export const negotiationsHistory = (state = initialState.negotiationsHistory, action) => {
    return actions[action.type] ? actions[action.type](state, action) : state;
};
