import React from 'react'

export default function DashboardCard(props) {
    return (
        <div className='card-container'>
            <div className={`dashboard-card ${props.className}`}>
                <div className='data'>
                    <label>{props.title}</label>
                    <span>{props.value}</span>
                </div>
                <span>{props.date}</span>
            </div>
        </div>
    );
}
