import { createStore, applyMiddleware } from 'redux'
import { rootReducer, initialState } from '../Reducers'
import thunk from 'redux-thunk'
import * as SignalR from '@aspnet/signalr'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import config from '../Services/config'
import * as types from '../Actions/actionTypes'
import { signalRActions, priceActions, priceIndicationActions, dayOfferActions } from '../Actions'
import { localStorageUtils, dateUtils, redirectUtils, offerStates } from '../Utils'
import { alertService } from '../Services'

const priceHub = new SignalR.HubConnectionBuilder()
    .withUrl(config.signalRPrice, { accessTokenFactory: () => localStorageUtils.getAuthToken() }).build()

const notificationHub = new SignalR.HubConnectionBuilder()
    .withUrl(config.signalRNotification, { accessTokenFactory: () => localStorageUtils.getAuthToken() }).build()

const signalRInvokeMiddleware = store => {
    return next => async action => {
        switch (action.type) {
            case types.REQUEST_SIGNALR_PRICE_CONNECTION:
                startPriceConnection(store)
                break;
            case types.SUBSCRIBE_COTATIONS:
                const auth = localStorageUtils.getAuthToken()
                if (!auth) {
                    alertService.error('Erro ao receber cotação da bolsa');
                    redirectUtils.redirectTo('/#/login');
                }
                action.payload.entities.forEach(entity => {
                    const cbotId = entity.price ? entity.price.cbotId : entity.cbotId
                    if (cbotId) {
                        priceHub.invoke('SubscribeCotationByCBOTId', cbotId)
                            .then(result => {
                                store.dispatch(signalRActions.subscribeCotationsSuccess({ [action.payload.controlVariable]: result }))
                                if (!result)
                                    debugger
                            })
                            .catch(() => {
                                // debugger
                                localStorageUtils.removeAuthToken();
                                alertService.error('Erro ao receber cotação da bolsa');
                                redirectUtils.redirectTo('/#/login');
                                store.dispatch(signalRActions.subscribeCotationsError({ [action.payload.controlVariable]: false }))
                            })
                    }
                });
                break;
            case types.SUBSCRIBE_EXCHANGES:
                action.payload.entities.forEach(entity => {
                    const paymentOn = entity.price ? entity.price.paymentOn : entity.paymentOn
                    const tradeOn = entity.price ? entity.price.tradeOn : entity.tradeOn
                    if (tradeOn) {
                        priceHub.invoke('SubscribeDollarByDate', paymentOn)
                            .then(result => {
                                store.dispatch(signalRActions.subscribeExchangesSuccess({ [action.payload.controlVariable]: result }))
                                if (!result)
                                    debugger
                            })
                            .catch(() => {
                                // debugger
                                localStorageUtils.removeAuthToken();
                                alertService.error('Erro ao receber curva cambial');
                                redirectUtils.redirectTo('/#/login');
                                store.dispatch(signalRActions.subscribeExchangesError({ [action.payload.controlVariable]: false }))
                            })
                    }
                });
                break;
            case types.UNSUBSCRIBE_ALL:
                priceHub.invoke('UnsubscribeAllCotations')
                    .then(() => {
                        store.dispatch(signalRActions.unsubscribeAllSuccess())
                    })
                    .catch(() => {
                        store.dispatch(signalRActions.unsubscribeAllError())
                    })
                break;
            case types.CLOSE_SIGNALR_PRICE_CONNECTION:
                priceHub.stop()
                break;
            // ---------------------- Notification 
            case types.REQUEST_SIGNALR_NOTIFICATION_CONNECTION:
                startNotificationConnection(store)
                break;
            case types.CLOSE_SIGNALR_NOTIFICATION_CONNECTION:
                notificationHub.stop()
                break;
            default:
                break;
        }

        return next(action);
    }
}

const startNotificationConnection = store => {
    if (!notificationHub.methods.todayoffercountchanged) {
        notificationHub.on('TodayOfferCountChanged', offerCount => {
            const state = store.getState()
            if (state.signalR.fetchNewOffers && state.dayOffers.offerCount[state.dayOffers.currentTab.name] !== null && offerCount[state.dayOffers.currentTab.name] >= state.dayOffers.offerCount[state.dayOffers.currentTab.name]) {
                store.dispatch(dayOfferActions.fetchOffers({ states: [state.dayOffers.currentTab.id] }, false, offerCount))
            }
            else {
                store.dispatch(dayOfferActions.handleValueChange({ offerCount }))
                if (!state.signalR.fetchNewOffers && state.dayOffers.offerCount.new !== null) {
                    if (state.dayOffers.offerCount.new < offerCount.new)
                        alertService.infoRedirect('Nova oferta recebida', () => {
                            redirectUtils.redirectTo('#/ofertas')
                            store.dispatch(dayOfferActions.handleValueChange({ currentTab: offerStates.New }))
                        })
                    if (state.dayOffers.offerCount.negotiation < offerCount.negotiation)
                        alertService.infoRedirect('Nova oferta em negociação', () => {
                            redirectUtils.redirectTo('#/ofertas')
                            store.dispatch(dayOfferActions.handleValueChange({ currentTab: offerStates.Negotiation }))
                        })
                    if (state.dayOffers.offerCount.approved < offerCount.approved)
                        alertService.infoRedirect('Nova oferta aprovada', () => {
                            redirectUtils.redirectTo('#/ofertas')
                            store.dispatch(dayOfferActions.handleValueChange({ currentTab: offerStates.Approved }))
                        })
                }
            }
        })
    }

    notificationHub.start()
        .then(() => {
            store.dispatch(signalRActions.requestNotificationConnectionSuccess())
        }).catch(error => {
            debugger
            store.dispatch(signalRActions.requestNotificationConnectionError(error))
        })
}

const startPriceConnection = store => {
    if (!priceHub.methods.cmainstrumentation) {
        priceHub.on('NewCotationUpdate', (cbotId, symbol, cotation) => {
            const state = store.getState()
            if (state.signalR.pricesCotationSubscribed)
                store.dispatch(priceActions.cotationUpdate(cbotId, cotation))
            else if (state.signalR.indicationsCotationSubscribed)
                store.dispatch(priceIndicationActions.cotationUpdate(cbotId, cotation))
            else if (state.signalR.offersCotationSubscribed)
                store.dispatch(dayOfferActions.cotationUpdate(cbotId, cotation))
        })

        priceHub.on('NewDollarUpdate', (date, exchange, receivedOn) => {
            const state = store.getState()
            const formattedDate = dateUtils.formatToUTC(date)
            if (state.signalR.pricesExchangeSubscribed)
                store.dispatch(priceActions.exchangeUpdate(formattedDate, exchange))
            else if (state.signalR.indicationsExchangeSubscribed)
                store.dispatch(priceIndicationActions.exchangeUpdate(formattedDate, exchange))
            else if (state.signalR.offersExchangeSubscribed)
                store.dispatch(dayOfferActions.exchangeUpdate(formattedDate, exchange))

            if (state.signalR.currentDollarSubscribed && (formattedDate === dateUtils.newDate().format('DD/MM/YYYY')))
                store.dispatch(signalRActions.handleValueChange({ currentDollar: { value: exchange, hour: dateUtils.formatUTC(receivedOn, 'HH:mm') } }))
        })

        priceHub.on('CMAStatusChanged', status => {
            // console.log('CMA status changed', status);
        })

        priceHub.on('CMAInstrumentation', instrumentation => {
            store.dispatch(signalRActions.instrumentationUpdate(instrumentation))
        })

        priceHub.on('LoginResponse', (serverUtcNow, exchanges) => {
            const cbot = exchanges.find(ex => ex.symbol === 'M')
            const dollar = exchanges.find(ex => ex.symbol === 'E')
            store.dispatch(signalRActions.setExchangesInfo({ cbot, dollar }))
        })

        priceHub.on('MarketExchangeStatusChanged', exchange => {
            const name = exchange.symbol === 'M' ? 'cbot' : 'dollar'
            store.dispatch(signalRActions.setExchangesInfo({ [name]: { ...exchange } }))
        })
    }

    priceHub.start()
        .then(() => {
            store.dispatch(signalRActions.requestPriceConnectionSuccess())
        }).catch(error => {
            debugger
            if (error && error.statusCode === 401) {
                localStorageUtils.removeAuthToken();
                alertService.info('Sua sessão expirou. Você será redirecionado para o login.', { toastId: 'expiredSession' });
                redirectUtils.redirectTo('/#/login');
            }
            store.dispatch(signalRActions.requestPriceConnectionError(error))
        })
}

const middleware = [thunk, signalRInvokeMiddleware];

if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
        collapsed: (getState, action) => action.type === 'SIGNALR_INSTRUMENTATION_UPDATE'
    });
    middleware.push(logger);
}

export default function configureStore() {
    return createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware)));
}
