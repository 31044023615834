import React, { useCallback } from 'react'
export default function CurrencyInput({ value, maxValue = 999999, onValueChange, style = {}, className = 'form-control form-input', autoFocus = false }) {
    const VALID_FIRST = /^[1-9]{1}$/
    const VALID_NEXT = /^[0-9]{1}$/
    const DELETE_KEY_CODE = 8
    value = Math.round(Math.abs(value))
    const handleKeyDown = useCallback(
        e => {
            const { key, keyCode } = e;
            if (
                (value === 0 && !VALID_FIRST.test(key)) ||
                (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
            ) {
                return;
            }
            const valueString = value.toString();
            let nextValue
            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString = value === 0 ? key : `${valueString}${key}`;
                nextValue = Number.parseInt(nextValueString, 10);
            } else {
                const nextValueString = valueString.slice(0, -1);
                nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
            }
            if (nextValue > maxValue) {
                return;
            }
            onValueChange(nextValue);
        },
        [maxValue, onValueChange, value, VALID_FIRST, VALID_NEXT, DELETE_KEY_CODE]
    );
    const handleChange = useCallback(() => {
        // AVOID REACT WARNING
    }, []);
    const valueDisplay = (value / 100).toLocaleString('pt-BR', {
        minimumFractionDigits: 2
    });
    return (
        <input
            className={className}
            inputMode='numeric'
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            style={style}
            value={valueDisplay}
            autoFocus={autoFocus}
        />
    )
}