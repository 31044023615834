import React from 'react'
import { useSelector, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authActions } from '../../Actions'
import Loader from 'react-loader'
import LoginForm from './loginForm'
import { logo } from '../../Assets'
import { Redirect } from 'react-router-dom'
import { AuthService } from '../../Services'
const authService = new AuthService()

const LoginContainer = props => {
  const auth = useSelector(state => state.auth);

  function handleLogin(data) {
    props.authActions.requestLogin(data);
  }

  return (
    authService.isAuthenticated() ? <Redirect to='/dashboard' /> :
      <div className='vh-100 d-flex align-items-center login-container'>
        <div className='mx-auto'>
          <Loader loaded={!auth.isFetching} color='#fff'>
            <div className='p-3 login-card'>
              <div className='mt-3 mb-5 text-center'>
                <img src={logo} className='logo' alt='Brick' />
              </div>
              <LoginForm handleLogin={handleLogin} />
            </div>
          </Loader>
        </div>
      </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(authActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(LoginContainer);
