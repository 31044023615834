import * as types from '../actionTypes'
import { PriceService, alertService } from '../../Services'
import { signalRActions } from '../../Actions'

const priceService = new PriceService();

export const requestPrices = () => ({
    type: types.REQUEST_PRICES,
    payload: null
});

export const requestPricesSuccess = prices => ({
    type: types.REQUEST_PRICES_SUCCESS,
    payload: prices
})

export const requestPricesError = error => ({
    type: types.REQUEST_PRICES_ERROR,
    payload: error
});

export const fetchPrices = options => dispatch => {
    dispatch(requestPrices());
    dispatch(signalRActions.unsubscribeAll())
    priceService.getUnpublished(options)
        .then(response => {
            if (response.data) {
                const entities = response.data.map(price =>
                    ({
                        ...price,
                        updatedCotation: price.cotation,
                        updatedExchangeFee: price.exchangeFee,
                        updatedValue: price.value,
                        checked: false
                    }))
                dispatch(requestPricesSuccess(entities));
            }
        }).catch(error => {
            dispatch(requestPricesError(error));
            alertService.error(error, 'Erro ao buscar preços');
        })
};

export const requestDeletePrices = () => ({
    type: types.REQUEST_DELETE_PRICES,
    payload: null
});

export const requestDeletePricesSuccess = () => ({
    type: types.REQUEST_DELETE_PRICES_SUCCESS,
    payload: null
})

export const requestDeletePricesError = error => ({
    type: types.REQUEST_DELETE_PRICES_ERROR,
    payload: error
});

export const deletePrices = (pricesToDelete, options, hideModal) => dispatch => {
    dispatch(requestDeletePrices());

    priceService.deletePrices(pricesToDelete)
        .then(() => {
            dispatch(requestDeletePricesSuccess())
            dispatch(fetchPrices(options))
            alertService.success('Preços excluídos com sucesso!')
            hideModal()
        }).catch(error => {
            dispatch(requestDeletePricesError(error))
            alertService.error(error, 'Erro ao excluir preços')
        })
};

export const requestPublishPrices = () => ({
    type: types.REQUEST_PUBLISH_PRICES,
    payload: null
});

export const requestPublishPricesSuccess = () => ({
    type: types.REQUEST_PUBLISH_PRICES_SUCCESS,
    payload: null
})

export const requestPublishPricesError = error => ({
    type: types.REQUEST_PUBLISH_PRICES_ERROR,
    payload: error
});

export const publishPrices = (pricesToPublish, options, hideModal) => dispatch => {
    dispatch(requestPublishPrices());

    priceService.publishPrices(pricesToPublish)
        .then(() => {
            dispatch(requestPublishPricesSuccess())
            dispatch(fetchPrices(options));
            alertService.success('Preços publicados com sucesso!')
            hideModal()
        }).catch(error => {
            dispatch(requestPublishPricesError(error))
            alertService.error(error, 'Erro ao publicar preços')
        })
};

export const handleValueChange = edition => ({
    type: types.HANDLE_PRICES_VALUE_CHANGE,
    payload: edition
});

export const updatePricesList = price => ({
    type: types.UPDATE_PRICES_LIST,
    payload: price
})

export const handleCheckboxToggle = (checked, id) => ({
    type: types.HANDLE_PRICES_CHECKBOX_TOGGLE,
    payload: { checked, id }
})

export const cotationUpdate = (cbotId, cotation) => ({
    type: types.PRICES_COTATION_UPDATE,
    payload: { cbotId, cotation }
})

export const exchangeUpdate = (date, exchange) => ({
    type: types.PRICES_EXCHANGE_UPDATE,
    payload: { date, exchange }
})
