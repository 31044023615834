import React from 'react'
import { SVGIcon } from '../../Assets'

export default function TabItem({ label, name, color, id, isActive, onTabClick}) {

    return (
        <div className={`inova-tab regular-tab-item ${isActive ? `${name}-selected` : ''}`} onClick={() => onTabClick(name, id)}>
            <SVGIcon name={name} fill={isActive ? color : '#9D9D9D'} className='mr-2' />
            <span className='text-uppercase'>{label}</span>
        </div>
    )
}
