import React from 'react'
import { FormInput } from '../../Components'
import { useForm } from '../../Hooks'

const LoginForm = ({ handleLogin }) => {
  const { values, handleChange, handleSubmit } = useForm({ email: '', password: '' }, () => handleLogin(values));

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <FormInput name='email' placeholder='Email' addon glyphicon='user' containerClass='mb-2' value={values.cpf} onChange={handleChange} />
      <FormInput name='password' placeholder='Senha' addon glyphicon='lock' containerClass='mb-3' type='password' value={values.password} onChange={handleChange} />
      <button className='btn inova-btn primary w-100' type='submit'>Entrar</button>
    </form>
  )
}

export default LoginForm
