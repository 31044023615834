import * as types from "../actionTypes";
import { OfferService, alertService } from "../../Services";
import { dateUtils, userDataStorageUtils } from "../../Utils";

const offerService = new OfferService();

export const requestSaveOfferForm = () => ({
  type: types.REQUEST_SAVE_OFFER_FORM,
  payload: null,
});

export const requestSaveOfferFormSuccess = (offer) => ({
  type: types.REQUEST_SAVE_OFFER_FORM_SUCCESS,
  payload: offer,
});

export const requestSaveOfferFormError = (error) => ({
  type: types.REQUEST_SAVE_OFFER_FORM_ERROR,
  payload: error,
});

export const handleSave = (offer) => (dispatch) => {
  let offerToCreate = {
    ...offer,
    value: offer.value / 100,
    paymentOn: dateUtils.format(offer.paymentOn, "YYYY-MM-DD", "DD/MM/YYYY"),
    deliveryStartsOn: offer.immediateDelivery
      ? null
      : dateUtils.format(offer.deliveryStartsOn, "YYYY-MM-DD", "DD/MM/YYYY"),
    deliveryEndsOn: offer.immediateDelivery
      ? null
      : dateUtils.format(offer.deliveryEndsOn, "YYYY-MM-DD", "DD/MM/YYYY"),
  };

  if (offerToCreate.id) {
    offerToCreate.priceId = offer.id;
    delete offerToCreate.id;
  }

  if (
    offerToCreate.dirtRoadLength === "" &&
    offerToCreate.shippingAgreementTypeId === 3
  )
    offerToCreate.dirtRoadLength = 0;

  if (offerToCreate.portTerminalId === 0) delete offerToCreate.portTerminalId;

  if (offerToCreate.useMarketTime === "true") delete offerToCreate.duration;

  dispatch(requestSaveOfferForm());

  offerService
    .saveOrUpdate(offerToCreate)
    .then((response) => {
      const productId = offerToCreate.productId
        ? offerToCreate.productId
        : offerToCreate.product.productId;
      userDataStorageUtils.setIndicationTabInfo(
        offerToCreate.harvestId,
        productId
      );
      dispatch(requestSaveOfferFormSuccess(response.data));
    })
    .catch((error) => {
      dispatch(requestSaveOfferFormError({ data: offerToCreate, error }));
      const {
        response: {
          data: { message },
        },
      } = error;
      alertService.error(error, message || "Erro ao criar oferta");
    });
};

export const handleValueChange = (edition) => ({
  type: types.HANDLE_OFFER_FORM_VALUE_CHANGE,
  payload: edition,
});

export const clear = () => ({
  type: types.CLEAR_OFFER_FORM,
  payload: null,
});
